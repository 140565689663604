import React from 'react'
import AdminDashboard from './admin-dashboard/AdminDashboard';
import MerchantDashboard from './merchant-dashboard/MerchantDashboard';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

const Dashboard = () => {
    const authState = useSelector(state => state.auth)
    const { userType } = authState || {}    
    return (
        <Box>
            {userType === "ADMIN" ? <AdminDashboard/> : <MerchantDashboard/>}
        </Box>
    )
}

export default Dashboard