import { apiSlice } from "../apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/user/users/",
      keepUnusedDataFor: 5,
    }),
    getUserProfile:builder.query({
      query:()=>'/user/me/',
      keepUnusedDataFor:0,
      responseHandler: ((response) => response.data.dp.blob())
    }),
    postUsers: builder.mutation({
      query: (user) => ({
        url: "/user/create_user/",
        method: "POST",
        body: user,
      }),
    }),
    putUsers: builder.mutation({
      query: (user) => ({
        url: "/user/update_user/",
        method: "PUT",
        body: user,
      }),
    }),
    putSubscription:builder.mutation({
      query:(subscription) =>({
        url:'/user/subscription/',
        method:'PUT',
        body:subscription
      })
    }),
  }),
});

export const { useGetUsersQuery, usePostUsersMutation, usePutUsersMutation, usePutSubscriptionMutation, useGetUserProfileQuery, useLazyGetUserProfileQuery } =
  userApiSlice;