import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CustomAutoComplete = ({ options, label, value, onInputChange, onChange, onChangeText, error, helperText }) => {
    const ListboxComponent = React.forwardRef(function ListboxComponent(
        props,
        ref
    ) {
        const { children, ...other } = props;
        return (
            <ul
                ref={ref}
                {...other}
                style={{
                    backgroundColor: "rgb(43, 44, 64)",
                    padding: "0",
                    listStyle: "none",
                    borderRadius: '4px',
                }}
            >
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child, {
                        style: {
                            ...child.props.style,
                            color: "#dbdbeb99",
                        },
                    });
                })}
            </ul>
        );
    });

    return (
        <Autocomplete
            sx={{
                '& fieldset': {
                    borderColor: '#dbdbeb61'
                },
                '& .MuiInputBase-root,label': {
                    color: '#dbdbeb61'
                },
                '& svg': {
                    fill: '#dbdbeb61'
                },
                '& .MuiMenu-list': {
                    backgroundColor: 'rgb(43, 44, 64) !important'
                },
                "& fieldset:hover": {
                    borderColor: "white !important"
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover': {
                        '& fieldset': {
                            borderColor: '#dbdbeb61',
                        },
                    },
                },
                '& .Mui-focused': {
                    '& fieldset': {
                        borderColor: 'rgb(105, 108, 255) !important',
                    },
                },
                '& .MuiFormLabel-root': {
                    '&.Mui-focused': {
                        color: 'rgb(105, 108, 255) !important',
                    },
                },
                '& .MuiSvgIcon-root': {
                    fill: error ? "#d32f2f" : "#dbdbeb61"
                },

            }}
            onChange={onChange}
            id="combo-box-demo"
            options={options}
            value={value}
            onInputChange={onInputChange}
            ListboxComponent={ListboxComponent}
            renderInput={(params) => <TextField error={error} helperText={helperText}  {...params} onChange={onChangeText} label={label} />}
        />
    );
};

export default CustomAutoComplete;
