import React, { useEffect, useState } from 'react'
import TabTitle from '../../shared/TabTitle'
import ComponentTitle from '../../shared/ComponentTitle'
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import CustomAutoCompleteCheckBox from '../../theme/CustomAutoCompleteCheckBox';
import { useGetBrangersByNameQuery } from '../../store/brangers/brangerApiSlice';
import { useGetMerchantsByNameQuery } from '../../store/merchant/merchantApiSlice';
import { useGetOrdersBySearchQuery } from '../../store/orders/orderApiSlice';
import CustomSelect from '../../theme/style-components/CustomSelect';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomInput from '../../theme/style-components/CustomInput';
import CustomButton from '../../theme/style-components/CustomButton';
import { toast } from 'react-toastify';
import { useCreateExternalTransactionMutation, useUpdateExternalTransactionMutation } from '../../store/external-transaction/externalTransactionsApiSlice';

const CreateExternalTransaction = (props) => {
    const { transactionData } = props;

    const navigate = useNavigate();
    const [selectedBrangers, setSelectedBrangers] = useState([]);
    const [searchByNameBranger, setSearchByNameBranger] = useState([]);
    const [brangersToDisplay, setBrangersToDisplay] = useState([]);
    const [selectedMerchants, setSelectedMerchants] = useState([]);
    const [searchByNameMerchants, setSearchByNameMerchants] = useState('');
    const [merchantsToDisplay, setMerchantsToDisplay] = useState([]);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [searchByNameOrders, setSearchByNameOrders] = useState([]);
    const [ordersToDisplay, setOrdersToDisplay] = useState([]);

    const [error, setError] = useState(null);

    const [formValue, setFormValue] = useState({
        brangers: transactionData?.brangers ? transactionData?.brangers?.map((branger) => branger?.id) : [],
        merchants: transactionData?.merchants ? transactionData?.merchants?.map((merchant) => merchant?.id) : [],
        orders: transactionData?.orders ? transactionData?.orders : [],
        trx_type: transactionData?.trx_type ? transactionData?.trx_type : '',
        purpose: transactionData?.purpose ? transactionData?.purpose : '',
        completed_using: transactionData?.completed_using ? transactionData?.completed_using : '',
        amount_cents: transactionData?.amount_cents ? transactionData?.amount_cents / 100 : '',
        note: transactionData?.note ? transactionData?.note : ''
    });

    const [createExternalTransaction, { isCreateLoading }] = useCreateExternalTransactionMutation();
    const [updateExternalTransaction, { isEditLoading }] = useUpdateExternalTransactionMutation();

    const {
        isLoading: isBrangerSearchedByNameLoading,
        data: brangerSearchedByName,
        isSuccess: isBrangerSearchedByNameSuccess,
        isError: isBrangerSearchedByNameError,
        error: brangerSearchedByNameError
    } = useGetBrangersByNameQuery(searchByNameBranger);

    const {
        isLoading: isMerchantSearchedByNameLoading,
        data: merchantSearchedByName,
        isSuccess: isMerchantSearchedByNameSuccess,
    } = useGetMerchantsByNameQuery({ name: searchByNameMerchants });

    const {
        isLoading: isOrderSearchLoading,
        data: orderBySearch,
        isSuccess: isOrderBySearchSuccess,
    } = useGetOrdersBySearchQuery(searchByNameOrders);

    useEffect(() => {
        if (!isBrangerSearchedByNameLoading && isBrangerSearchedByNameSuccess) {
            let tempBrangers = brangerSearchedByName.data.map((branger) => {
                return {
                    label: branger.first_name + " " + branger.last_name,
                    id: branger.id,
                };
            });
            setBrangersToDisplay(tempBrangers);
        }
    }, [brangerSearchedByName?.data, isBrangerSearchedByNameLoading, searchByNameBranger, isBrangerSearchedByNameSuccess])


    useEffect(() => {
        if (!isMerchantSearchedByNameLoading && isMerchantSearchedByNameSuccess) {
            const tempMerchants = merchantSearchedByName?.data?.map((merchant) => {
                return {
                    label: merchant.first_name + " " + merchant.last_name,
                    id: merchant.id,
                };
            });
            if (tempMerchants.length > 0) {
                setMerchantsToDisplay(tempMerchants);
            }
        }
    }, [merchantSearchedByName?.data?.length, merchantSearchedByName, isMerchantSearchedByNameLoading, isMerchantSearchedByNameSuccess])


    useEffect(() => {
        if (!isOrderSearchLoading && isOrderBySearchSuccess) {
            const tempOrders = orderBySearch?.data?.map((order) => {
                return {
                    label: order.id,
                    id: order.id,
                };
            });
            if (tempOrders?.length > 0) {
                setOrdersToDisplay(tempOrders);
            }
        }
    }, [orderBySearch?.data?.length, searchByNameOrders, isOrderSearchLoading, isOrderBySearchSuccess])

    useEffect(() => {
        if (transactionData?.id) {
            const updateTimeBrangers = transactionData?.brangers?.length > 0 && transactionData?.brangers?.map((item) => {
                return {
                    label: item?.first_name + " " + item?.first_name,
                    id: item?.id
                }
            })
            if (updateTimeBrangers) {
                setSelectedBrangers(updateTimeBrangers)
            }

            const updateTimeMerchants = transactionData?.merchants?.length > 0 && transactionData?.merchants?.map((item) => {
                return {
                    label: item?.first_name + " " + item?.first_name,
                    id: item?.id
                }
            })
            if (updateTimeMerchants) {
                setSelectedMerchants(updateTimeMerchants)
            }

            const updateTimeOrders = transactionData?.orders?.length > 0 && transactionData?.orders?.map((item) => {
                return {
                    label: item,
                    id: item
                }
            })
            if (updateTimeOrders) {
                setSelectedOrders(updateTimeOrders)
            }
        }
    }, [transactionData]);

    const validate = () => {
        let isInvalid = false;
        let errorNew = {};
        const { trx_type, purpose, completed_using, amount_cents, note } = formValue;

        if (!trx_type) {
            errorNew.trx_type = {
                isError: true,
                text: "Please select the transaction type",
            };

            isInvalid = true;
        };

        if (!purpose) {
            errorNew.purpose = {
                isError: true,
                text: "Please select the purpose of transaction",
            };

            isInvalid = true;
        };

        if (!completed_using) {
            errorNew.completed_using = {
                isError: true,
                text: "Please select the transaction method",
            };

            isInvalid = true;
        };

        if (!amount_cents) {
            errorNew.amount_cents = {
                isError: true,
                text: "Please enter the amount",
            };

            isInvalid = true;
        };

        if (!note) {
            errorNew.note = {
                isError: true,
                text: "Please enter the note",
            };

            isInvalid = true;
        };

        if (isInvalid) {
            setError(errorNew);
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            const { brangers, merchants, orders, trx_type, purpose, completed_using, amount_cents, note } = formValue;
            let createData = {
                brangers: brangers,
                merchants: merchants,
                orders: orders,
                trx_type: trx_type,
                purpose: purpose,
                completed_using: completed_using,
                amount_cents: amount_cents * 100,
                note: note
            };
            let updateData = {
                trx_id: transactionData?.id,
                brangers: brangers,
                merchants: merchants,
                orders: orders,
                trx_type: trx_type,
                purpose: purpose,
                completed_using: completed_using,
                amount_cents: amount_cents * 100,
                note: note
            };

            if (transactionData?.id) {
                try {
                    const response = await updateExternalTransaction(updateData);
                    if (response?.data?.message === "transaction updated!") {
                        toast.success(`Transaction updated successfully.`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate("/external-transactions");
                    } else if (response) {
                        const errorMessages = Object.values(response?.error?.data).flat();
                        errorMessages.forEach((errorMessage) => {
                            toast.error(errorMessage, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        });
                    } else {
                        toast.error("An error occurred.", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (err) {
                    console.error(err, "shit");
                    toast.error("An error occurred while creating a transaction.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                try {
                    const response = await createExternalTransaction(createData);
                    if (response?.data?.message === "transaction added!") {
                        toast.success(`Transaction added successfully.`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate("/external-transactions");
                    } else if (response) {
                        const errorMessages = Object.values(response?.error?.data).flat();
                        errorMessages.forEach((errorMessage) => {
                            toast.error(errorMessage, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        });
                    } else {
                        toast.error("An error occurred.", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (err) {
                    console.error(err, "shit");
                    toast.error("An error occurred while creating a transaction.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        }
    };

    const breadcrumbs = [
        <Link key="1" to="/external-transactions">
            External Transactions
        </Link>,
        <Typography key="2" variant="body1">
            {transactionData ? "Edit" : "Create"}
        </Typography>,
    ];

    return (
        <Box>
            <TabTitle title="Create Transactions" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox>
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomAutoCompleteCheckBox
                                options={brangersToDisplay}
                                value={selectedBrangers}
                                onChange={(newValue) => {
                                    if (newValue && newValue.length === 0) {
                                        setSearchByNameBranger('');
                                    }
                                    setSelectedBrangers(newValue);
                                    const selected = newValue?.length > 0 ? newValue?.map((branger) => branger?.id) : []
                                    setFormValue(() => ({ ...formValue, brangers: selected }))
                                }}
                                onTextFieldChange={(e) => setSearchByNameBranger(e.target.value)}
                                label="Brangers"
                                placeholder="Select the brangers"
                                multiple
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomAutoCompleteCheckBox
                                options={merchantsToDisplay}
                                value={selectedMerchants}
                                onChange={(newValue) => {
                                    if (newValue && newValue.length === 0) {
                                        setSearchByNameMerchants('');
                                    }
                                    setSelectedMerchants(newValue);
                                    const selected = newValue?.length > 0 ? newValue?.map((merchant) => merchant?.id) : []
                                    setFormValue(() => ({ ...formValue, merchants: selected }))
                                }}
                                onTextFieldChange={(e) => setSearchByNameMerchants(e.target.value)}
                                label="Merchants"
                                placeholder="Select the merchants"
                                multiple
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomAutoCompleteCheckBox
                                options={ordersToDisplay}
                                value={selectedOrders}
                                onChange={(newValue) => {
                                    setSelectedOrders(newValue);
                                    const selected = newValue?.length > 0 ? newValue?.map((order) => order?.id) : []
                                    setFormValue(() => ({ ...formValue, orders: selected }))
                                }}
                                onTextFieldChange={(e) => setSearchByNameOrders(e.target.value)}
                                label="Orders"
                                placeholder="Select the orders"
                                multiple
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                error={error != null && error.trx_type && error.trx_type.isError}
                                helperText={
                                    error != null &&
                                    error.trx_type &&
                                    error.trx_type.isError &&
                                    error.trx_type.text
                                }
                                value={formValue?.trx_type}
                                label="Transaction Type"
                                onChange={(e) => {
                                    setError(null);
                                    setFormValue(() => ({ ...formValue, trx_type: e.target.value }))
                                }}
                            >
                                <MenuItem value="CREDIT">Credit</MenuItem>
                                <MenuItem value="DEBIT">Debit</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                error={error != null && error.purpose && error.purpose.isError}
                                helperText={
                                    error != null &&
                                    error.purpose &&
                                    error.purpose.isError &&
                                    error.purpose.text
                                }
                                value={formValue?.purpose}
                                label="Purpose"
                                onChange={(e) => {
                                    setError(null);
                                    setFormValue(() => ({ ...formValue, purpose: e.target.value }))
                                }}
                            >
                                <MenuItem value="SUBSCRIPTION">Subscription</MenuItem>
                                <MenuItem value="SHORTFALL">Shortfall</MenuItem>
                                <MenuItem value="OFFPORTAL_INVOICE">Offportal invoice</MenuItem>
                                <MenuItem value="BRANGER_PAYMENT">Branger Payment</MenuItem>
                                <MenuItem value="OTHERS">Others</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                error={error != null && error.completed_using && error.completed_using.isError}
                                helperText={
                                    error != null &&
                                    error.completed_using &&
                                    error.completed_using.isError &&
                                    error.completed_using.text
                                }
                                value={formValue?.completed_using}
                                label="Completed Using"
                                onChange={(e) => {
                                    setError(null);
                                    setFormValue(() => ({ ...formValue, completed_using: e.target.value }))
                                }}
                            >
                                <MenuItem value="STRIPE">Stripe</MenuItem>
                                <MenuItem value="OTHERS">Others</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box sx={{
                            position: 'relative'
                        }}>
                            <CustomInput fullWidth type="number"
                                value={formValue.amount_cents}
                                error={error != null && error.amount_cents && error.amount_cents.isError}
                                helperText={
                                    error != null &&
                                    error.amount_cents &&
                                    error.amount_cents.isError &&
                                    error.amount_cents.text
                                }
                                onChange={(e) => {
                                    setError(null);
                                    setFormValue(() => ({ ...formValue, amount_cents: e.target.value }));
                                }}
                                name="amount_cents"
                                label="Amount" />
                            <AttachMoneyIcon sx={{
                                position: 'absolute',
                                right: '10px',
                                top: error !== null && Boolean(error?.amount_cents?.isError) ? "37%" : "50%",
                                transform: 'translateY(-50%)',
                                color: error !== null && Boolean(error?.amount_cents?.isError) ? "#d32f2f" : "#dbdbeb61"
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomInput
                            label="Note" minRows={2} multiline
                            onChange={(e) => {
                                setFormValue(() => ({ ...formValue, note: e.target.value }));
                            }}
                            value={formValue?.note}
                            error={error != null && error.note && error.note.isError}
                            helperText={
                                error != null &&
                                error.note &&
                                error.note.isError &&
                                error.note.text
                            }
                        />
                    </Grid>
                    <Grid item xs={12} gap={2} md={12} >
                        <Box sx={{ justifyContent: "end", display: "flex", marginTop: "20px", gap: "10px" }}>
                            <CustomButton buttonName="Cancel" color="secondary" onClick={() => navigate("/external-transactions")} />
                            <CustomButton
                                onClick={handleSubmit}
                                type="submit"
                                color="primary"
                                buttonName="Submit"
                            />
                        </Box >
                    </Grid>
                </Grid>
            </CustomPaperBox>
        </Box>
    )
}

export default CreateExternalTransaction
