import React, { useEffect, useRef, useState } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import Sidebar, { DrawerHeader } from './Sidebar';
import { Box } from "@mui/material";


const BoxContainer = styled(Box)({
    display: 'flex',
});

const MainContent = styled('main')({
    flexGrow: 1,
    p: 3,
    minHeight: "100vh"
});

// Create a custom theme using createTheme
const theme = createTheme();

export default function Layout({ children }) {
    const [open, setOpen] = React.useState(false);
    const handleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BoxContainer>
                <Header open={open} handleDrawer={handleDrawer} setOpen={setOpen} />
                <Sidebar open={open} setOpen={setOpen} />
                <MainContent sx={{
                    backgroundColor: 'rgb(35, 35, 51)',
                    // [theme.breakpoints.up('lg')]: {
                    //     marginLeft: open ? "0px" : "0px",
                    // },
                    padding: '24px',
                    overflow: 'hidden',
                }}>
                    <DrawerHeader />
                    <Box id="main_box" sx={{
                        transition: 'all 0.3s',
                        [theme.breakpoints.up('lg')]: {
                            width: open ? "calc(100% - 240px)" : "calc(100% - 90px)",
                            marginLeft: open ? "auto" : "auto"
                        }
                    }} onClick={() => setOpen(false)}>
                        {children}
                    </Box>
                </MainContent>
            </BoxContainer>
        </ThemeProvider >
    );
}