import React, { useEffect, useState } from 'react';
import CustomInput from '../../theme/style-components/CustomInput';
import { Box, Grid, MenuItem } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useCreateRefundMutation } from '../../store/refund/refundApiSlice';
import CustomButton from '../../theme/style-components/CustomButton';
import { toast } from 'react-toastify';
import { useLazyGetMerchantsByNameQuery } from '../../store/merchant/merchantApiSlice';
import CustomAutoComplete from '../../theme/style-components/CustomAutoComplete';
import CustomSelect from '../../theme/style-components/CustomSelect';

const CreateRefund = (props) => {
    const { handleClose, refetchRefund } = props;
    const [createRefund, { isCreateLoading }] = useCreateRefundMutation();
    const [error, setError] = useState(null);
    const [selectedMerchant, setSelectedMerchant] = useState();
    const [merchantsToDisplay, setMerchantsToDisplay] = useState([]);
    const [searchedName, setSearchedName] = useState('');

    const [getMerchantsByName, {
        isLoading: isMerchantSearchedByNameLoading,
        data: merchantSearchedByName,
        isSuccess: isMerchantSearchedByNameSuccess,
    }] = useLazyGetMerchantsByNameQuery();

    useEffect(() => {
        searchMerchant(searchedName);
    }, [searchedName])

    const searchMerchant = async (searchText) => {
        const data = await getMerchantsByName({ name: searchText}).unwrap();
    }

    useEffect(() => {
        if (!isMerchantSearchedByNameLoading && isMerchantSearchedByNameSuccess) {
            const tempMerchants = merchantSearchedByName?.data?.map((merchant) => {
                return {
                    label: merchant.first_name + " " + merchant.last_name,
                    id: merchant.id,
                };
            });
            if (tempMerchants.length > 0) {
                setMerchantsToDisplay(tempMerchants);
            }
        }
    }, [merchantSearchedByName?.data?.length, merchantSearchedByName, isMerchantSearchedByNameLoading, isMerchantSearchedByNameSuccess])

    const [formValue, setFormValue] = useState({
        user_id: "",
        trx_type: "",
        amount_cents: "",
        trx_note: ""
    })

    const validate = () => {
        let isInvalid = false;
        let errorNew = {};
        const { user_id, amount_cents, trx_note, trx_type } = formValue;

        if (!user_id) {
            errorNew.user_id = {
                isError: true,
                text: "Please select the user",
            };

            isInvalid = true;
        };

        if (trx_type.trim().length === 0) {
            errorNew.trx_type = {
                isError: true,
                text: "Please select the trasaction type",
            };

            isInvalid = true;
        };

        if (!amount_cents) {
            errorNew.amount_cents = {
                isError: true,
                text: "Please enter the amount",
            };

            isInvalid = true;
        };

        if (!trx_note) {
            errorNew.trx_note = {
                isError: true,
                text: "Please enter the note",
            };

            isInvalid = true;
        };

        if (isInvalid) {
            setError(errorNew);
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            const { user_id, amount_cents, trx_note, trx_type } = formValue;
            let createData = {
                user_id: user_id,
                trx_type: trx_type,
                amount_cents: amount_cents * 100,
                trx_note: trx_note
            };
            try {
                const response = await createRefund(createData);

                if (response?.data?.message === "success") {
                    toast.success(`Refund created successfully.`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    refetchRefund();
                    handleClose();
                } else if (response) {
                    const errorMessages = Object.values(response?.error?.data).flat();
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
                } else {
                    toast.error("An error occurred.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (err) {
                toast.error("An error occurred while creating a refund.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    return (
        <>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomAutoComplete
                    error={error != null && error.user_id && error.user_id.isError}
                    helperText={
                        error != null &&
                        error.user_id &&
                        error.user_id.isError &&
                        error.user_id.text
                    }
                    value={selectedMerchant}
                    onChange={(event, newValue) => {
                        setError(null);
                        setSelectedMerchant(newValue);
                        setFormValue(() => ({ ...formValue, user_id: newValue?.id }))
                    }}
                    onChangeText={(e) => {
                        setSearchedName(e.target.value);
                        setError(null);
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue === '') {
                            setError(null);
                            setSelectedMerchant(null);
                        }
                        setSearchedName(newInputValue);
                    }}
                    label="Search Merchant" options={merchantsToDisplay} />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box>
                    <CustomSelect
                        error={error != null && error.trx_type && error.trx_type.isError}
                        helperText={
                            error != null &&
                            error.trx_type &&
                            error.trx_type.isError &&
                            error.trx_type.text
                        }
                        value={formValue?.trx_type}
                        label="Transaction Type"
                        onChange={(e) => {
                            setError(null);
                            setFormValue(() => ({ ...formValue, trx_type: e.target.value }))
                        }}
                    >
                        <MenuItem value="CREDIT">Credit</MenuItem>
                        <MenuItem value="DEBIT">Debit</MenuItem>
                    </CustomSelect>
                </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box sx={{
                    position: 'relative'
                }}>
                    <CustomInput fullWidth type="number"
                        error={error != null && error.amount_cents && error.amount_cents.isError}
                        helperText={
                            error != null &&
                            error.amount_cents &&
                            error.amount_cents.isError &&
                            error.amount_cents.text
                        }
                        onChange={(e) => {
                            setError(null);
                            setFormValue(() => ({ ...formValue, amount_cents: e.target.value }));
                        }}
                        name="amount_cents"
                        label="Amount" />
                    <AttachMoneyIcon sx={{
                        position: 'absolute',
                        right: '10px',
                        top: error !== null && Boolean(error?.amount_cents?.isError) ? "37%" : "50%",
                        transform: 'translateY(-50%)',
                        color: error !== null && Boolean(error?.amount_cents?.isError) ? "#d32f2f" : "#dbdbeb61"
                    }} />
                </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    label="Note" minRows={2} multiline
                    onChange={(e) => {
                        setFormValue(() => ({ ...formValue, trx_note: e.target.value }));
                    }}
                    error={error != null && error.trx_note && error.trx_note.isError}
                    helperText={
                        error != null &&
                        error.trx_note &&
                        error.trx_note.isError &&
                        error.trx_note.text
                    }
                />
            </Grid>
            <Grid item xs={12} gap={2} md={12} >
                <Box sx={{ justifyContent: "end", display: "flex", marginTop: "20px", gap: "10px" }}>
                    <CustomButton
                        onClick={handleSubmit}
                        type="submit"
                        color="primary"
                        buttonName={isCreateLoading ? "Submitting" : "Submit"}
                    />
                    <CustomButton buttonName="Cancel" color="secondary" onClick={handleClose} />
                </Box >
            </Grid>
        </>
    )
}

export default CreateRefund;
