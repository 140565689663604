import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import CustomTable from '../../theme/style-components/CustomTable';
import LottieLoader from '../loader/LottieLoader';
import CustomModal from '../../theme/style-components/CustomModal';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import ComponentTitle from '../../shared/ComponentTitle';
import TabTitle from '../../shared/TabTitle';
import CreateRefund from './CreateRefund';
import { useGetRefundQuery } from '../../store/refund/refundApiSlice';
import { selectRefund, setRefundList } from '../../store/refund/refundSlice';

const Refund = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState();
    const [refundToDisplay, setRefundToDisplay] = useState();
    const [selectedAddressBook, setSelectedAddressBook] = useState();
    const [isModalOpen, setIsModalOpen] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [query, setQuery] = useState("");
    const [searchText, setSearchText] = useState("");

    const refund = useSelector(selectRefund);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
        refetch: refetchRefund
    } = useGetRefundQuery({ page: page, query: query });

    //hooks
    useEffect(() => {
        if (!isLoading) {
            dispatch(setRefundList({
                refundList: dataReturned?.data,
                totalRecord: dataReturned?.page_details?.count
            }));
        };
    }, [isLoading, page, dataReturned?.data, query]);

    //hooks
    useEffect(() => {
        if (refund) {
            setRefundToDisplay(refund?.refundList);
            setCount(refund?.totalRecord)
        }
    }, [refund]);


    const setSelectedData = (item) => {
        setSelectedAddressBook(item)
    };

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'user_id', label: 'User ID' },
        { id: 'trx_type', label: 'Trx Type' },
        { id: 'trx_note', label: 'Trx Note' },
        { id: 'amount', label: 'Amount' },
    ];

    const addressData = refundToDisplay?.length > 0 ? refundToDisplay.map((data) => ({
        id: data.id,
        user_id: data.user,
        trx_type: data.trx_type,
        trx_note: data.note,
        amount: `AUD ${(data?.amount_cents / 100).toFixed(2)}`
    })) : []

    const handleCreateClick = () => {
        setIsModalOpen(!isModalOpen);
        setIsEdit(false);
    };

    const handleEditClick = () => {
        setIsModalOpen(!isModalOpen)
        setIsEdit(true);
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    const breadcrumbs = [
        <Typography key="2" variant="body1">
            Refund
        </Typography>,
    ];

    return (
        <Box>
            <TabTitle title="Refund" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            {refundToDisplay && !isLoading && count !== undefined ? (
                <>
                    <CustomTable
                        //pagination props
                        title="Branger"
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}

                        //create button props
                        handleCreateClick={handleCreateClick}
                        createButtonName="Create Refund"

                        //search props
                        isShowSearch={true}
                        handleOnSearch={handleOnSearch}
                        searchText={searchText}
                        searchPlaceholder="Search"

                        // //delete menu props
                        // isShowDeleteMenu={true}
                        // handleDelteClick={handleDeleteClick}


                        //action props
                        isActionVisible={true}

                        //data props
                        data={addressData}
                        columns={columns}

                        //set Selected row data
                        setSelectedData={setSelectedData}
                    />
                </>
            ) : <Box>
                <LottieLoader />
            </Box>}

            <CustomModal maxWidth="md" title="Create Refund" isModalOpen={isModalOpen}
                isShowActionButton={false}
                handleClose={() => setIsModalOpen(false)} >
                <CreateRefund refetchRefund={refetchRefund} handleClose={() => setIsModalOpen(false)} isEdit={isEdit} ID={selectedAddressBook?.id} />
            </CustomModal>
        </Box>
    )
}

export default Refund;
