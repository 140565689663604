import { apiSlice } from "../apiSlice"

export const externalTransactionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getExternalTransactions: builder.query({
            query: ({ page, query }) => `/payments/external_transactions/?page=${page}${query}`,
            keepUnusedDataFor: 0,
        }),
        getSingleExternalTransaction: builder.query({
            query: (ID) => `/payments/external_transactions/?trx_id=${ID}`,
            keepUnusedDataFor: 0,
        }),
        createExternalTransaction: builder.mutation({
            query: (data) => ({
                url: '/payments/external_transactions/',
                method: 'POST',
                body: data,
            })
        }),
        updateExternalTransaction: builder.mutation({
            query: (data) => ({
                url: '/payments/external_transactions/',
                method: 'PUT',
                body: data,
            })
        }),
        deleteExternalTransaction: builder.mutation({
            query: (id) => ({
                url: `/payments/external_transactions/?trx_id=${id}`,
                method: "DELETE",
            }),
        }),
    })
});

export const {
    useGetExternalTransactionsQuery,
    useLazyGetSingleExternalTransactionQuery,
    useCreateExternalTransactionMutation,
    useUpdateExternalTransactionMutation,
    useDeleteExternalTransactionMutation,
} = externalTransactionsApiSlice;