import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'

const CustomButton = ({ buttonName, fullWidth, color, disabled, href, target, onClick, outline, isLoading }) => {
    const colorMappings = {
        primary: "#696cff !important",
        secondary: "#8592a3 !important",
        tertiary: "red !important",
        success: '#67c932 !important',
        error: "#ff3e1d !important",
        warning: "#ffab00 !important",
        info: "#13C2C2 !important",
    };

    const outlineColorMappings = {
        primary: "#696cff14",
        secondary: "#8592a314",
        success: "#71dd3714",
        error: "#ff3e1d14",
        warning: "#ffab0014",
        info: "#13C2C2"
    };

    return (
        <Box>
            {!outline && <Button fullWidth={fullWidth} sx={{
                backgroundColor: disabled ? "#dbdbeb1f" : colorMappings[color] || "",
                color: disabled ? "#dbdbeb42 !important" : '#fff',
                padding: '7px 20px',
            }}
                onClick={onClick}
                href={href}
                target={target}
                disabled={disabled}>
                {isLoading ? <CircularProgress size={14} sx={{ marginRight: "5px" }} color="inherit" /> : ""}
                {buttonName}
            </Button>}
            {outline && <Button fullWidth={fullWidth} sx={{
                backgroundColor: 'transparent',
                color: `${colorMappings[color]}`,
                padding: '7px 20px',
                border: `1px solid ${colorMappings[color]}`,
                '&:hover': {
                    backgroundColor: disabled ? "#dbdbeb1f" : outlineColorMappings[color] || "",
                    color: "#ffffff !important"
                }
            }}
                onClick={onClick}
                href={href}
                target={target}
                disabled={disabled}>
                {isLoading ? <CircularProgress size={14} sx={{ marginRight: "5px" }} color="inherit" /> : ""}
                {buttonName}
            </Button>}
        </Box>
    )
}

export default CustomButton;


