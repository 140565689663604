import React, { useRef, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { Box, Button, ButtonGroup, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const ExpenseChart = () => {
  const [timeRange, setTimeRange] = useState('weekly');
  const [isExporting, setIsExporting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const chartRef = useRef(null);

  // Data for different time ranges
  const data = {
    weekly: [
      { Payment: 'Brangers', Amount: 1700 },
    ],
    monthly: [
        { Payment: 'Brangers', Amount: 2000 },
    ],
    '3month': [
        { Payment: 'Brangers', Amount: 2500 },
    ],
    '6month': [
        { Payment: 'Brangers', Amount: 4000 },
    ],
    yearly: [
        { Payment: 'Brangers', Amount: 4800 },
    ],
  };

  // Get chart data based on selected time range
  const chartData = data[timeRange];
  const totalAmount = chartData.reduce((total, item) => total + item.Amount, 0);

  // Chart series data for ApexCharts
  const chartSeries = [
    {
      name: 'Expense',
      data: chartData.map(item => ({
        x: item.Payment,
        y: item.Amount,
      })),
    },
  ];

  // Chart categories based on the selected time range
  const chartCategories = chartData.map(item => item.Amount);

  const chartOptions = {
    chart: {
      id: 'expense-chart',
      height: 350,
      type: 'bar',
      toolbar: { show: false },
    },
    xaxis: {
      labels: {
        style: { colors: isExporting ? '#000000' : '#FFFFFF' },
      },
      axisTicks: {
        show: false, 
      },
    },
 
    yaxis: {
      categories: chartCategories,
      labels: {
        formatter: (value) => `$${(value / 1000).toFixed(1)}k`,
        style: { colors: isExporting ? '#000000' : '#FFFFFF' },
      },
    },
    plotOptions: {    bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 5,
    }, },
    colors: ['#696CFF'],
    dataLabels: { enabled: false },
    fill: {
        opacity: 1,
        colors: ['#696CFF', '#FF5733', '#28a745', '#f39c12', '#8e44ad'],
    },
  };

  const handleExportAction = async (type) => {
    setIsExporting(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const chartInstance = window.Apex._chartInstances.find(
        (chart) => chart.id === 'expense-chart'
      );

      if (!chartInstance) {
        console.error('Chart instance not found');
        setIsExporting(false);
        return;
      }

      if (type === 'svg') {
        chartInstance.chart.exports.exportToSVG();
      } else if (type === 'png') {
        chartInstance.chart.exports.exportToPng();
      }
    } catch (error) {
      console.error('Export failed:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const generateCSV = () => {
    const header = 'Category,Expense\n';
    const rows = chartData
      .map((item) => `${item.Payment},${item.Amount}`)
      .join('\n');
    const csvContent = `data:text/csv;charset=utf-8,${header}${rows}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Expense_chart_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <Box sx={{
                    display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "18px", borderBottom: "1px solid #9797A933", marginBottom: "16px",
                    "& a": {
                        textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500"
                    }
                }}>
        <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: '700' }}>Expense Chart</Typography>
      </Box>

      <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "base",
                    marginBottom: "16px",
                    width: '100%',
                    '@media (max-width:768px)': {
                        flexWrap: 'wrap',
                        gap: "10px"
                    },
                }}>
       <Box>
                        <Typography variant='h2' sx={{ color: "white", fontSize: "16px", fontWeight: "700" }}>Total Expense</Typography>
                        <Typography variant='h2' sx={{ marginTop: "6px", color: "#696CFF", fontSize: "24px", fontWeight: "700" }}>{`$${totalAmount}`}</Typography>
                    </Box>

        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="outlined primary button group"
          sx={{
            display: 'flex',
            gap: '7px',
            boxShadow: "inherit",
            paddingTop:"1px",
            '@media (max-width:768px)': { flexWrap: 'wrap' },
          }}
        >
          {['weekly', 'monthly', '3month', '6month', 'yearly'].map((range) => (
            <Button
              key={range}
              onClick={() => setTimeRange(range)}
              sx={{
                backgroundColor: timeRange === range ? '#696CFF' : '#232333',
                width: '38px',
                height: '26px',
                color: 'white',
                borderRight: '0 !important',
                borderRadius: '20px !important',
                '&:hover': { backgroundColor: '#696CFF' },
              }}
            >
              {range === 'weekly' ? 'W' : range === 'monthly' ? '1M' : range === '3month' ? '3M' : range === '6month' ? '6M' : '1Y'}
            </Button>
          ))}
        </ButtonGroup>

        <Box sx={{ position: 'relative',bottom:"24px" }}>
          <IconButton onClick={handleClickMenu} sx={{ marginTop: '16px' }}>
            <MenuIcon style={{ color: '#FFFFFF' }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => { handleExportAction('svg'); handleCloseMenu(); }}>Download SVG</MenuItem>
            <MenuItem onClick={() => { generateCSV(); handleCloseMenu(); }}>Download CSV</MenuItem>
            <MenuItem onClick={() => { handleExportAction('png'); handleCloseMenu(); }}>Download PNG</MenuItem>
          </Menu>
        </Box>
      </Box>

      <Box id="chart">
        <ApexCharts options={chartOptions} series={chartSeries} type="bar" height={350} ref={chartRef} />
      </Box>
    </Box>
  );
};

export default ExpenseChart;
