import { Box, Typography } from '@mui/material';
import { GoogleApiWrapper, Map as GoogleMap, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import greenIcon from "../../../assets/green_marker_map.svg";
import blueIcon from "../../../assets/blue_map_marker.svg";
import redIcon from "../../../assets/red_marker_map.svg";
import { useGetAssignedTasksQuery, useGetCompletedTasksQuery, useGetUnAssignedTasksQuery } from '../../../store/tasks/taskApiSlice';
import moment from 'moment';

const Map = ({ google }) => {
  const [mapRef, setMapRef] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [allDeliveries, setAllDeliveries] = useState([]);
  const navigate = useNavigate();  // Hook to navigate to other pages


  const onMapReady = (mapProps, map) => {
    setMapRef(map);
  };

  useEffect(() => {
    setDateRange({
      startDate: new Date(),
      endDate: new Date()
    });
  }, [])

  //base query hooks
  const {
    data: tasks,
  } = useGetUnAssignedTasksQuery({
    taskSearch: "",
    startDate: dateRange?.startDate && dateRange?.endDate
      ? moment(dateRange?.startDate).format("YYYY-MM-DD")
      : "",
    endDate: dateRange?.startDate && dateRange?.endDate
      ? moment(dateRange?.endDate).format("YYYY-MM-DD")
      : "",
    page: 1,
  });

  const {
    data: assignedTasks,
  } = useGetAssignedTasksQuery({
    taskSearch: "",
    startDate: dateRange?.startDate && dateRange?.endDate
      ? moment(dateRange?.startDate).format("YYYY-MM-DD")
      : "",
    endDate: dateRange?.startDate && dateRange?.endDate
      ? moment(dateRange?.endDate).format("YYYY-MM-DD")
      : "",
    page: 1,
    task_status_arr: ["ASSIGNED", "STARTED", "PICKEDUP"],
  });
  const {
    data: completedTasks,
  } = useGetCompletedTasksQuery({
    taskSearch: "",
    startDate: dateRange?.startDate && dateRange?.endDate
      ? moment(dateRange?.startDate).format("YYYY-MM-DD")
      : "",
    endDate: dateRange?.startDate && dateRange?.endDate
      ? moment(dateRange?.endDate).format("YYYY-MM-DD")
      : "",
    page: 1,
    task_status_arr: ["CANCELED", "FAILED", "DELIVERED"],
  });

  useEffect(() => {
    const allDelivery = []
    if (assignedTasks?.data && tasks?.data && completedTasks?.data) {
      assignedTasks?.data?.forEach((task) => {
        allDelivery.push(task)
      });
      tasks?.data?.forEach((task) => {
        allDelivery.push(task)
      });
      completedTasks?.data?.forEach((task) => {
        allDelivery.push(task)
      });
    }
    setAllDeliveries(allDelivery)
  }, [assignedTasks, tasks, completedTasks])

  const handleMapClick = (e) => {
    // Redirect to map when the map is clicked
    // For example, navigate to a new route like '/new-page'
    navigate('/map');  // Adjust this path based on where you want to navigate
  };

  return (
    <div>
      <Box sx={{
        display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "18px", borderBottom: "1px solid #9797A933", marginBottom: "16px",
        "& a": {
          textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500"
        }
      }}>
        <Typography sx={{ fontSize: '20px', fontWeight: '700', color: "#fff" }}>Map</Typography>
        <Link to="/map" sx={{ textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500" }}>View All</Link>
      </Box>
      {/* The Map component from 'google-maps-react' is used here */}
      <Box id="chart" sx={{
        position: "relative",
        width: "100%",
        height: "320px",
        '@media (max-width:991px)': {
          height: "460px", // For screens 900px and above (md)
        },
      }}>
        <GoogleMap
          google={google}
          containerStyle={{ width: "100%", height: "100%" }}
          zoom={14}
          onReady={onMapReady}
          maxZoom={17}
          initialCenter={{ lat: -37.82296, lng: 144.979401 }}
          style={{ height: "100%", width: "100%", borderRadius: "10px" }}
          onClick={handleMapClick}  // Attach the onClick handler to the map
        >
          {allDeliveries?.length > 0 &&
            allDeliveries.map((delivery, index) => {
              if (delivery) {
                return (
                  <Marker
                    onClick={handleMapClick}  // Attach the onClick handler to the map
                    position={{ lat: Number(delivery?.pickup_address_details?.lat), lng: Number(delivery?.pickup_address_details?.long) }}
                    label={{
                      text: `P`, color: "#FFFFFF", fontSize: "16px",
                    }}
                    data={delivery}
                    icon={{
                      url: delivery?.task_status === "UNASSIGNED" ? redIcon : delivery?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                      // Assume we're working with an icon size of 40x40
                      scaledSize: new google.maps.Size(40, 40),
                      // Set the anchor to the middle of your icon image
                      anchor: new google.maps.Point(20, 20),
                    }}
                  />
                )
              }
            })
          }
          {allDeliveries?.length > 0 &&
            allDeliveries.map((delivery, index) => {
              return delivery?.tasks?.map((task) => { // Add return statement here
                return (
                  <Marker
                    onClick={handleMapClick}  // Attach the onClick handler to the map
                    position={{ lat: Number(task?.drop_address?.lat), lng: Number(task?.drop_address?.long) }}
                    label={{
                      text: `D`, color: "#FFFFFF", fontSize: "16px",
                      labelOrigin: new google.maps.Point(0, 0)
                    }}
                    data={task}
                    icon={{
                      url: task?.task_status === "UNASSIGNED" ? redIcon : task?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                      // Assume we're working with an icon size of 40x40
                      scaledSize: new google.maps.Size(40, 40),
                      // Set the anchor to the middle of your icon image
                      anchor: new google.maps.Point(20, 20),
                    }}
                  />
                )
              })
            })
          }
        </GoogleMap>
      </Box>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0'
})(Map);
