import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { useDeleteBrangerDocumentsMutation, useGetBrangerQuery, usePutBrangerDocumentsMutation } from "../../store/brangers/brangerApiSlice";
import _ from 'underscore';
import { Backdrop, Box, Button, IconButton } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from "../../theme/style-components/CustomButton";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { showError } from "../../shared/helperFunction";
import DeleteIcon from '@mui/icons-material/Delete';
import LottieLoader from "../loader/LottieLoader";

const CustomLink = styled(Link)`
&& {
  background-color: rgb(43, 44, 64) !important;
  color: #696cff;

  &:hover {
    box-shadow: none !important;
  }
}
`;

const BrangerDocuments = (props) => {
    //ref variables
    const inputRef = useRef(null);

    //variables
    const original_documents = [
        {
            label: "Front License",
            key: "license",
            document: {},
            link: ''
        },
        {
            label: "Back License",
            key: "license_back",
            document: {},
            link: ''
        },
        { label: "Utility Bill", key: "utility_bill", document: {}, link: '' },
        {
            label: "Photo Holding Drivers License",
            key: "photograph_holding_drivers_license",
            document: {},
            link: ''
        },
        { label: "Vehicle Insurance", key: "vehicle_insurance", document: {}, link: '' },
        { label: "Work Permit", key: "work_permit", document: {}, link: '' },
    ];

    //state variables
    const [documents, setDocuments] = useState(original_documents);
    const [currentDocument, setCurrentDocument] = useState("");
    const [fetchedDocuments, setFetchedDocuments] = useState([{ key: '', file: '' }]);
    const [docsId, setDocsId] = useState('');
    //base query hooks
    const [putBrangerDocuments, { isLoading: isPutBrangerDocumentsLoading }] =
        usePutBrangerDocumentsMutation();
    const [deleteBrangerDocuments, { isLoading: isDeleteBrangerDocumentsLoading }] =
        useDeleteBrangerDocumentsMutation();
    const { isLoading, data: branger, isSuccess, isError, error, refetch } = useGetBrangerQuery(props.brangerID);

    //hooks
    useEffect(() => {

        if (!isLoading) {
            if (!isError) {
                let docs = [{
                    key: 'license',
                    file: branger.data[0].branger_details !== null && branger.data[0].branger_details.branger_documents !== null && branger.data[0].branger_details.branger_documents.license !== null ?
                        branger.data[0].branger_details.branger_documents.license : null
                },
                {
                    key: 'license_back',
                    file: branger.data[0].branger_details !== null && branger.data[0].branger_details.branger_documents !== null && branger.data[0].branger_details.branger_documents.license_back !== null ?
                        branger.data[0].branger_details.branger_documents.license_back : null
                },
                {
                    key: 'utility_bill',
                    file: branger.data[0].branger_details !== null && branger.data[0].branger_details.branger_documents !== null && branger.data[0].branger_details.branger_documents.utility_bill !== null ?
                        branger.data[0].branger_details.branger_documents.utility_bill : null
                },
                {
                    key: 'vehicle_insurance',
                    file: branger.data[0].branger_details !== null && branger.data[0].branger_details.branger_documents !== null && branger.data[0].branger_details.branger_documents.vehicle_insurance !== null ?
                        branger.data[0].branger_details.branger_documents.vehicle_insurance : null
                },
                {
                    key: 'photograph_holding_drivers_license',
                    file: branger.data[0].branger_details !== null && branger.data[0].branger_details.branger_documents !== null && branger.data[0].branger_details.branger_documents.photograph_holding_drivers_license !== null ?
                        branger.data[0].branger_details.branger_documents.photograph_holding_drivers_license : null
                },
                {
                    key: 'work_permit',
                    file: branger.data[0].branger_details !== null && branger.data[0].branger_details.branger_documents !== null && branger.data[0].branger_details.branger_documents.work_permit !== null ?
                        branger.data[0].branger_details.branger_documents.work_permit : null
                },
                ]
                setFetchedDocuments(docs);
                setDocsId(branger?.data[0]?.branger_details?.branger_documents?.id ? branger?.data[0]?.branger_details?.branger_documents?.id : null)
            }
        }
    }, [isLoading, branger])

    //methods
    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        event.target.value = null;

        new Compressor(fileObj, {
            quality: 0.6,
            maxWidth: 1920,
            maxHeight: 1080,
            maxSize: 3 * 1024, // max size in bytes (3 MB)
            success: (compressedFile) => {
                const file = new File([compressedFile], fileObj.name, { type: fileObj.type });
                const newDocuments = documents.map((document) => {
                    if (document.key === currentDocument) {
                        return { label: document.label, key: document.key, document: file, link: '' };
                    } else {
                        return document;
                    }
                });
                setDocuments(newDocuments);
            },
            error: (err) => {
                console.log(err.message);
            },
        });
    };
    const handleDocumentUpload = () => {
        let formData = new FormData();
        formData.append("user_id", props.brangerID);
        const obj = Object.fromEntries(
            documents.map((document) => [document.key, document.document])
        );


        Object.keys(obj).forEach((key) => {
            if (_.isEmpty(obj.key)) {
                console.log(`empty documents : ${key}`);
                //formData.append(key, obj[key])
            }
            else {
                //console.log(key);
            };
        });

        if (Object.getPrototypeOf(obj.license) === File.prototype) {
            formData.append('license', obj.license)
        }
        if (Object.getPrototypeOf(obj.license_back) === File.prototype) {
            formData.append('license_back', obj.license_back)
        }
        if (Object.getPrototypeOf(obj.utility_bill) === File.prototype) {
            formData.append('utility_bill', obj.utility_bill)
        }
        if (Object.getPrototypeOf(obj.photograph_holding_drivers_license) === File.prototype) {
            formData.append('photograph_holding_drivers_license', obj.photograph_holding_drivers_license)
        }
        if (Object.getPrototypeOf(obj.vehicle_insurance
        ) === File.prototype) {
            formData.append('vehicle_insurance', obj.vehicle_insurance
            )
        }
        if (Object.getPrototypeOf(obj.work_permit) === File.prototype) {
            formData.append('work_permit', obj.work_permit)
        }

        for (var key of formData.entries()) {
        }

        putBrangerDocumentsMethod(formData);
    };

    const putBrangerDocumentsMethod = async (data) => {
        try {
            const response = await putBrangerDocuments(data).unwrap();
            if (response) {
                toast.success(`Documents Uploaded Successfully for Branger ${props.brangerID}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            refetch();
        } catch (error) {
            showError(error?.data)
        }
    };

    const handleDeleteDocs = async (data) => {
        try {
            const response = await deleteBrangerDocuments(data);
            if (response?.data?.message) {
                refetch()
                toast.success(`${data?.doc_name} deleted successfully`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            showError(error?.data);
        }
    };

    //hooks
    return (
        <Box sx={{ marginTop: "20px" }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isDeleteBrangerDocumentsLoading}
            >
                <LottieLoader />
            </Backdrop>
            {documents.map((document) => (
                <Box key={document.key}
                    sx={{
                        border: "1px solid #dbdbeb61",
                        maxWidth: '800px',
                        width: '100%',
                        borderRadius: "10px",
                        marginTop: "10px !important",
                        marginBottom: "10px !important",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        pl: 3,
                        p: 2,
                        flexWrap: { lg: 'nowrap', md: 'nowrap', sm: 'nowrap', xs: 'wrap' }
                    }}
                >
                    <Box sx={{ width: { lg: '140px', md: '140px', sm: '140px', xs: '100%' } }}>
                        <h3>{document.label}</h3>
                    </Box>

                    <Box sx={{ display: { lg: 'flex', md: 'flex', sm: 'block', xs: 'block' }, flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        {document.document.name
                            ? document.document.name
                            : "Nothing New Uploaded"}
                        <br />

                        {
                            fetchedDocuments.map((doc) => (
                                doc.key === document.key ?
                                    (doc.file ?
                                        <CustomLink key={doc.key} target="_blank" to={doc.file} underline="none">
                                            Click to View Already Uploaded
                                        </CustomLink>
                                        : 'File not uploaded') : ''
                            ))
                        }

                    </Box>

                    <Box style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        <input
                            type="file"
                            ref={inputRef}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <Button
                            onClick={() => {
                                inputRef.current.click();
                                setCurrentDocument(document.key);
                            }}
                        >
                            <CloudUploadIcon sx={{ color: '#696cff' }} />
                        </Button>
                        {
                            fetchedDocuments.map((doc) => (
                                doc.key === document.key ?
                                    (doc.file ? <Box sx={{
                                        "& svg": {
                                            fill: "#ff3e1d"
                                        },
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'center'
                                    }}><IconButton
                                        onClick={() => {
                                            handleDeleteDocs({ doc_id: docsId, doc_name: document.key })
                                        }}
                                    ><DeleteIcon fill='red' /></IconButton></Box>
                                        : null) : null
                            ))
                        }
                    </Box>
                </Box>
            ))
            }
            <br></br>
            <CustomButton color="primary" buttonName="submit" isLoading={isPutBrangerDocumentsLoading} disabled={isPutBrangerDocumentsLoading} onClick={handleDocumentUpload} />
        </Box >
    );
};
export default BrangerDocuments;