import { apiSlice } from "../apiSlice"

export const brangerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBrangers: builder.query({
            query: ({ page, query }) => `/user/users/?page=${page}&user_type=BRANGER${query}`,
            keepUnusedDataFor: 0,
        }),
        getBrangerById: builder.query({
            query: ({ id }) => `/user/users/?page=1&user_type=BRANGER&id=${id}`,
            keepUnusedDataFor: 0,
        }),
        getFreeBrangers: builder.query({
            query: ({ userSearch, page }) => `/user/users/?user_type=BRANGER&branger_status=ONLINE&user_search=${userSearch}&page=${page}&user_verified=${true}`,
            keepUnusedDataFor: 5,
        }),
        getBusyBrangers: builder.query({
            query: ({ userSearch, page }) => `/user/users/?user_type=BRANGER&branger_status=BUSY&user_search=${userSearch}&page=${page}&user_verified=${true}`,
            keepUnusedDataFor: 5,
        }),
        getInactiveBrangers: builder.query({
            query: ({ userSearch, page }) => `/user/users/?user_type=BRANGER&branger_status=OFFLINE&user_search=${userSearch}&page=${page}&user_verified=${true}`,
            keepUnusedDataFor: 5,
        }),
        getBranger: builder.query({
            query: (id) => `user/users/?id=${id}`,
            keepUnusedDataFor: 5,
        }),
        getBrangersByName: builder.query({
            query: (name) => `user/users/?user_type=BRANGER&user_search=${name}&user_verified=${true}`,
            keepUnusedDataFor: 5,
        }),
        getVerifiedBrangers: builder.query({
            query: (verified) => `user/users/?user_type=BRANGER&user_verified=${verified}`,
            keepUnusedDataFor: 5,
        }),
        getActiveBrangers: builder.query({
            query: (active) => `user/users/?user_type=BRANGER&user_active=${active}`,
            keepUnusedDataFor: 5,
        }),
        onBoardBranger: builder.mutation({
            query: (data) => ({
                url: '/user/onboard_branger/',
                method: 'PUT',
                body: data,
            })
        }),
        putBrangerDocuments: builder.mutation({
            query: (data) => ({
                url: '/user/branger_docs/',
                method: 'PUT',
                body: data
            })
        }),
        deleteBrangerDocuments: builder.mutation({
            query: ({ doc_id, doc_name }) => ({
                url: `/user/branger_docs/?doc_id=${doc_id}&doc_name=${doc_name}`,
                method: "DELETE",
            }),
        }),
        getUserVehicleTypes: builder.query({
            query: () => "/user/vehicle_types/",
            keepUnusedDataFor: 5,
        }),
        getBrangersTaskSortList: builder.query({
            query: (data) => `/task/sort_tasks/?branger_id=${data?.branger_id}&from=${data?.date}`,
            keepUnusedDataFor: 0,
        }),
        putBrangerTaskSort: builder.mutation({
            query: (data) => ({
                url: '/task/sort_tasks/',
                method: 'PUT',
                body: data
            })
        }),

    })
})

export const {
    useGetBrangersQuery,
    useGetBrangerQuery,
    useLazyGetBrangerByIdQuery,
    useOnBoardBrangerMutation,
    usePutBrangerDocumentsMutation,
    useGetBrangersByNameQuery,
    useGetVerifiedBrangersQuery,
    useGetActiveBrangersQuery,
    useGetFreeBrangersQuery,
    useGetBusyBrangersQuery,
    useGetInactiveBrangersQuery,
    useLazyGetUserVehicleTypesQuery,
    useLazyGetBrangersTaskSortListQuery,
    usePutBrangerTaskSortMutation,
    useDeleteBrangerDocumentsMutation,
} = brangerApiSlice