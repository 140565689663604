import { Box, Typography } from '@mui/material';
import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Link } from 'react-router-dom';

const RadialBarChartExample = () => {
    // Data
    const totalBrangers = 300; // Total Brangers
    const activeBrangers = 200; // Active Brangers
    const inactiveBrangers = 100; // Inactive Brangers

    // Calculate the percentage for Active and Inactive Brangers based on Total Brangers
    const activePercentage = (activeBrangers / totalBrangers) * 100; // 66.67%
    const inactivePercentage = (inactiveBrangers / totalBrangers) * 100; // 33.33%

    // Options for the RadialBar chart
    const chartOptions = {
        chart: {
            type: 'radialBar',
            width: '100%',
            height: 350,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 15, // Space inside the circle (for a donut-like effect)
                    size: '50%', // Size of the hollow center (controls how much of the center is empty)
                    background: 'transparent', // Transparent background in the center
                },
                dataLabels: {
                    name: {
                        show: false, // Hide the name in the center
                    },
                    value: {
                        offsetY: 5,
                        show: true, // Show value in the center
                        fontSize: '20px', // Customize font size for the count in the center
                        fontWeight: 'bold', // Customize font weight for the count
                        color: '#fff', // White text color for the count in the center
                        formatter: function (value, config) {
                            // Show the count in the center of the radial bar
                            if (config?.config?.colors?.[0] === "#696CFF") {
                                return totalBrangers; // Show total Brangers count (300)
                            } else if (config?.config?.colors?.[0] === "#00E396") {
                                return activeBrangers; // Show Active Brangers count (200)
                            } else {
                                return inactiveBrangers; // Show Inactive Brangers count (100)
                            }
                        },
                    },
                },
            },
        },
        stroke: {
            lineCap: 'round'
        },
        labels: ['Brangers'], // Label for the radial bar
    };

    // Data for the RadialBar charts
    const totalBrangersData = [100]; // Full circle for Total Brangers (100% or full bar)
    const activeBrangersData = [activePercentage]; // Active Brangers percentage (e.g., 66.67%)
    const inactiveBrangersData = [inactivePercentage]; // Inactive Brangers percentage (e.g., 33.33%)

    return (
        <Box>
            <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "18px", borderBottom: "1px solid #9797A933", marginBottom: "16px",
                "& a": {
                    textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500"
                }
            }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', color: "#fff" }}>Brangers</Typography>
                <Link to="/brangers" sx={{ textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500" }}>View All</Link>
            </Box>
            <Box sx={{
                display: "flex",
                gap: "12px",
                alignItems: "center",
                '@media (max-width:768px)': {
                    flexWrap: 'wrap', // For screens 900px and above (md)
                    gap: "10px"
                },
            }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: "#fff" }}>New Register Brangers:</Typography>
                <Typography sx={{ fontSize: '26px', fontWeight: 'bold', color: "#9DA1FE" }}>
                    500
                    <Typography variant='span' sx={{ color: "#fff", fontWeight: "400", fontSize: "14px", paddingLeft: "3px" }}>This Month</Typography>
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: "20px",
                alignItems: "center",
                marginTop: "44px",
                '@media (max-width:991px)': {
                    flexWrap: 'wrap', // For screens 900px and above (md)
                },
            }}>
                {/* Radial Bar for Total Brangers (Full Circle) */}
                <Box sx={{ textAlign: 'center', maxWidth: "150px", width: "100%" }}>
                    <ApexCharts
                        options={{
                            ...chartOptions,
                            colors: ['#696CFF'], // Blue color for Total Brangers
                            plotOptions: {
                                radialBar: {
                                    ...chartOptions.plotOptions.radialBar,
                                    track: {
                                        background: '#232332', // Background color of the radial track
                                    },
                                },
                            },
                        }}
                        series={totalBrangersData} // Full circle for Total Brangers
                        type="radialBar"
                        height={200}
                        key="totalBrangers"
                    />
                    <Typography variant='h3' sx={{ color: "#fff", fontWeight: "semi-bold", fontSize: "16px" }}>Total No. of Brangers</Typography>
                </Box>

                {/* Radial Bar for Active Brangers */}
                <Box sx={{ textAlign: 'center', maxWidth: "150px", width: "100%" }}>
                    <ApexCharts
                        options={{
                            ...chartOptions,
                            colors: ['#00E396'], // Green color for Active Brangers
                            plotOptions: {
                                radialBar: {
                                    ...chartOptions.plotOptions.radialBar,
                                    track: {
                                        background: '#232332', // Background color of the radial track
                                    },
                                },
                            },
                        }}
                        series={activeBrangersData} // Active Brangers percentage
                        type="radialBar"
                        height={200}
                    />
                    <Typography variant='h3' sx={{ color: "#fff", fontWeight: "semi-bold", fontSize: "16px" }}>Active Brangers</Typography>
                </Box>

                {/* Radial Bar for Inactive Brangers */}
                <Box sx={{ textAlign: 'center', maxWidth: "150px", width: "100%" }}>
                    <ApexCharts
                        options={{
                            ...chartOptions,
                            colors: ['#EE7482'], // Red color for Inactive Brangers
                            plotOptions: {
                                radialBar: {
                                    ...chartOptions.plotOptions.radialBar,
                                    track: {
                                        background: '#232332', // Background color of the radial track
                                    },
                                },
                            },
                        }}
                        series={inactiveBrangersData} // Inactive Brangers percentage
                        type="radialBar"
                        height={200}
                    />
                    <Typography variant='h3' sx={{ color: "#fff", fontWeight: "semi-bold", fontSize: "16px" }}>Inactive Brangers</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default RadialBarChartExample;
