import { Box, Typography } from '@mui/material'
import React from 'react'
import './style.css';

const OrderDetails = ({multiBranger}) => {
    const business_name = multiBranger[0]?.business_name ?? "NA";
    const order_id = multiBranger?.[0]?.package?.[0]?.order_id ?? "NA";

    return (
        <div>
            <Box className="orderDetailsWrapper">
                <Box className="orderDetailsName">
                    <Typography className="orderDetailsTitle">Business Name:</Typography>
                    <Typography className="orderDetailsdisc">{business_name}</Typography>
                </Box>
                <Box className="orderDetailsId">
                    <Typography className="orderDetailsTitle">Order ID:</Typography>
                    <Typography className="orderDetailsdisc">{order_id}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default OrderDetails