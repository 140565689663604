import React from 'react'
import DrawerContent from './DrawerContent'
import { SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material'

const LeftDrawer = ({ handleToggle, isDrawerOpen, window , multiBranger, dropETA, refetchData}) => {
    const theme = useTheme();

    // Use MUI's breakpoints to detect screen size
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // max-width: 600px

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    const drawerBleeding = 0;

    return (
        <div>
            <SwipeableDrawer
                className='swipeable_drawer'
                container={container}
                anchor={isSmallScreen ? "bottom" : "left"}
                sx={{
                    width: isSmallScreen ? '100%' : "400px !important", '& .MuiDrawer-paper': {
                        width: isSmallScreen ? '100%' : 400, // Apply the width to the paper component
                        bgcolor: "#232333",
                        marginTop: "50px",
                        height: "calc(100% - 50px)",
                    },
                }}
                ModalProps={{
                    BackdropProps: {
                        style: { display: "none" },
                    },
                    keepMounted: true,
                }}
                open={isDrawerOpen}
                disableDiscovery={false}
                onClose={() => handleToggle()}
                onOpen={() => handleToggle()}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}

                hysteresis={0.52}
                minFlingVelocity={450}
            >
                <DrawerContent refetchData={refetchData} dropETA={dropETA} multiBranger={multiBranger} />
            </SwipeableDrawer>
        </div>
    )
}

export default LeftDrawer