import React, { useEffect, useState } from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import CustomTabs from '../../theme/style-components/CustomTabs'
import { Box, CircularProgress, Typography } from '@mui/material';
import CustomTable from '../../theme/style-components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUserObject, selectCurrentUserType } from '../../store/auth/authSlice';
import { useLazyGetUserProfileQuery } from '../../store/users/usersApiSlice';
import { useLazyGetWalletStripeTransactionsQuery, useLazyGetWalletTransactionsQuery } from '../../store/wallet/walletApiSlice';
import moment from 'moment/moment';
import ComponentTitle from '../../shared/ComponentTitle';
import TabTitle from '../../shared/TabTitle';
import LottieLoader from '../loader/LottieLoader';

const Wallet = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const tabsData = [
        { label: 'Wallet' },
        { label: 'External' },
    ];

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const walletColumns = [
        { id: 'id', label: 'ID' },
        { id: 'date', label: 'Date' },
        { id: 'amount', label: 'Amount' },
        { id: 'type', label: 'Type' },
        { id: 'meta_data', label: 'MetaData' },
        { id: 'note', label: 'Note' },
    ];


    const externalColumns = [
        { id: 'id', label: 'ID' },
        { id: 'date', label: 'Date' },
        { id: 'charged_amount', label: 'Charged Amount' },
        { id: 'intent_id', label: 'intent ID' },
        { id: 'status', label: 'Status' },
    ];


    const navigate = useNavigate();
    //redux logic
    let userType = useSelector(selectCurrentUserType);
    let user = useSelector(selectCurrentUserObject);

    const [transactionsToDisplay, setTransactionsToDisplay] = useState([]);
    const [externalToDisplay, setExternalToDisplay] = useState([]);
    const [getUserProfile] = useLazyGetUserProfileQuery();

    const [getWalletTransactions, {
        isLoading,
        data: walletTrasactionReturned,
        isSuccess,
        isError,
        error,
    }] = useLazyGetWalletTransactionsQuery();

    const [getWalletStripeTransactions, {
        isLoading: isStripeTransactionsLoading,
        data: walletStripeTrasactionsReturned,
        isSuccess: isStripeTransactionsSuccess,
        isError: isStripeTransactionsIsError,
        error: isStripeTransactionsError,
    }] = useLazyGetWalletStripeTransactionsQuery();


    useEffect(() => {
        try {
            getWalletTransactions({ page, rowsPerPage }).unwrap().then((res) => {
                setTransactionsToDisplay(res)
            }).catch((err) => console.log(err))
            getWalletStripeTransactions({ page, rowsPerPage }).unwrap().then((res) => {
                setExternalToDisplay(res)
            }).catch((err) => console.log(err))
        } catch (err) {
            console.log(err);
        }
    }, [isLoading, page, rowsPerPage])

    useEffect(() => {
        setPage(1);
        setRowsPerPage(15)
    }, [activeTab])

    //methods
    const handleCreateClick = (e) => {
        navigate("/recharge");
    };

    const [refreshWalletLoading, setRefreshWalletLoading] = useState(true);

    useEffect(() => {
        const fetchDataWithDelay = async () => {
            await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds
            handleRefresh();
            setRefreshWalletLoading(false);
        };

        fetchDataWithDelay();
        handleRefresh();
    }, []);

    const handleRefresh = async () => {
        try {
            setRefreshWalletLoading(true);
            await getUserProfile()
                .unwrap()
                .then((res) => {
                    console.log("refresh the balance");
                    setRefreshWalletLoading(false);
                    setBalance(`$${(res?.data?.wallet_balance_cents / 100).toFixed(2)}`);
                })
        } catch (err) {
            console.log(err);
        }
    };

    const walletData = transactionsToDisplay?.data?.length > 0 ? transactionsToDisplay.data.map((data) => ({
        id: data.id,
        date: moment.utc(data.created_at).format('DD MMMM YYYY'),
        amount: (data?.amount_cents / 100),
        type: data.trx_type,
        meta_data: data?.metadata && Object.entries(data?.metadata).map(([key, value]) => (
            <p key={key}>
                {key}: {value}
            </p>
        )),
        note: data.note,
    })) : []

    const externalData = externalToDisplay?.data?.length > 0 ? externalToDisplay.data.map((data) => ({
        id: data.id,
        date: moment.utc(data.created_at).format('DD MMMM YYYY'),
        charged_amount: (data?.charged_amount / 100),
        intent_id: data.intent_id,
        status: data.status,
    })) : [];

    const [balance, setBalance] = useState();

    const onPageChange = (value) => {
        setPage(value)
    };

    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    return (
        <Box>
            <TabTitle title="Wallet" />
            <ComponentTitle title="Wallet" />
            <CustomTabs tabsData={tabsData}
                activeTab={activeTab}
                onChange={handleTabChange} >
                {/* TAB 1 */}
                {transactionsToDisplay && transactionsToDisplay?.page_details?.count !== undefined ? <CustomTable
                    page={page}
                    count={transactionsToDisplay?.page_details?.count}
                    rowsPerPage={rowsPerPage}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    createButtonName="Recharge"
                    isShowBalance={true}
                    balance={balance}
                    isShowSearch={false}
                    handleCreateClick={handleCreateClick}
                    isActionVisible={false}
                    isCollapse={false}
                    data={walletData}
                    columns={walletColumns}
                /> : <Box>
                    <LottieLoader />
                </Box>}

                {/* TAB 2 */}
                {externalToDisplay && externalToDisplay?.page_details?.count !== undefined ? <CustomTable
                    page={page}
                    count={externalToDisplay?.page_details?.count}
                    rowsPerPage={rowsPerPage}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    createButtonName="Recharge"
                    isShowBalance={true}
                    balance={balance}
                    isShowSearch={false}
                    handleCreateClick={handleCreateClick}
                    isActionVisible={false}
                    isCollapse={false}
                    data={externalData}
                    columns={externalColumns} /> : <Box>
                    <LottieLoader />
                </Box>}
            </CustomTabs>
        </Box>
    )
}

export default Wallet