import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import LottieLoader from '../loader/LottieLoader';

const BrangerDetailsCard = ({ isLoading, multiBranger }) => {
    return (
        <Grid container spacing={2} sx={{
            padding: '15px 15px'
        }}>
            {!isLoading ? multiBranger?.length > 0 &&
                multiBranger?.map((detail) => {
                    return (
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Box sx={{
                                backgroundColor: '#2b2c40',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 10px 10px 10px',
                                padding: '20px',
                                border: "1px solid #dbdbeb99",
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto' },
                                marginBottom: '15px',
                                flexWrap: { xl: 'nowrap', lg: 'nowrap', md: 'nowrap', sm: 'nowrap', xs: 'wrap' }
                            }}>
                                <Box sx={{
                                    maxWidth: { xl: '390px', lg: '390px', md: '390px', sm: '390px', xs: '100%' },
                                    width: '100%'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        color: '#dbdbeb99',
                                        marginBottom: '10px',
                                        '& p': {
                                            '&:first-child': {
                                                maxWidth: '130px',
                                                width: '100%',
                                                color: '#fff',
                                            }
                                        }
                                    }}>
                                        <Typography>Bussiness Name:</Typography>
                                        <Typography>{detail?.business_name}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        color: '#dbdbeb99',
                                        marginBottom: '10px',
                                        '& p': {
                                            '&:first-child': {
                                                maxWidth: '130px',
                                                width: '100%',
                                                color: '#fff',
                                            }
                                        }
                                    }}>
                                        <Typography>Customer Name:</Typography>
                                        <Typography>{detail?.customer_name}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        color: '#dbdbeb99',
                                        marginBottom: '10px',
                                        '& p': {
                                            '&:first-child': {
                                                maxWidth: '130px',
                                                width: '100%',
                                                color: '#fff',
                                            }
                                        }

                                    }}>
                                        <Typography>Customer Email:</Typography>
                                        <Typography>{detail?.customer_email}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        gap: '10px',
                                        color: '#dbdbeb99',
                                        marginBottom: '10px',
                                        '& p': {
                                            '&:first-child': {
                                                maxWidth: '130px',
                                                width: '100%',
                                                color: '#fff',
                                            }

                                        }
                                    }}>
                                        <Typography>Customer Phone:</Typography>
                                        <Typography>{detail?.customer_phone}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'baseline',
                                        gap: '10px',
                                        color: '#dbdbeb99',
                                        marginBottom: '10px',
                                        '& p': {
                                            '&:first-child': {
                                                maxWidth: '130px',
                                                width: '100%',
                                                color: '#fff',
                                            }
                                        }
                                    }}>
                                        <Typography>Pickup Address:</Typography>
                                        <Typography>{detail?.pickup_address}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'baseline',
                                        color: '#dbdbeb99',
                                        marginBottom: '10px',
                                        '& p': {
                                            marginBottom: '10px',
                                            '&:first-child': {
                                                width: '100%',
                                                color: '#fff',
                                            }
                                        }
                                    }}>
                                        <Typography>Drop Address:</Typography>
                                        {detail?.package?.length > 0 && detail?.package?.map((item, index) => {
                                            return (<Box sx={{
                                                display: 'flex',
                                                position: 'relative',
                                                '&:last-child': {
                                                    '& .MuiTypography-overline': {
                                                        display: 'none',
                                                    },
                                                },
                                            }}>
                                                <Box sx={{
                                                    marginRight: '15px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    top: '6px',
                                                    '& .MuiTypography-span': {
                                                        width: '24px',
                                                        height: '24px',
                                                        borderRadius: "100px",
                                                        border: '2px solid #1394ff',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: '#fff',
                                                        fontSize: "12px",
                                                        fontWeight: '400',
                                                    },
                                                    '& .MuiTypography-overline': {
                                                        width: '2px',
                                                        backgroundColor: '#1394ff',
                                                        flexGrow: '1',

                                                    },
                                                }}>
                                                    <Typography variant='span'>D{index === 0 ? null : index}</Typography>
                                                    <Typography variant='overline'></Typography>
                                                </Box>
                                                <Box sx={{
                                                    marginBottom: '10px',
                                                }}>
                                                    <Typography>{item?.drop_address?.address}</Typography>
                                                </Box>
                                            </Box>)
                                        })}
                                    </Box>
                                </Box>
                                <Box sx={{
                                    maxWidth: "340px",
                                    width: '100%'
                                }}>
                                    <Typography variant='h5' sx={{
                                        fontWeight: '500',
                                        color: '#696cff',
                                        marginBottom: '15px'
                                    }}>Branger Details</Typography>
                                    <Box sx={{
                                        color: '#dbdbeb99',
                                        wordBreak: "break-word",
                                        marginBottom: '10px',
                                    }}>
                                        <Typography variant="span" sx={{
                                            maxWidth: '130px',
                                            width: '100%',
                                            color: '#fff',
                                        }}>Branger Name:</Typography>
                                        <Typography variant="span">&nbsp;&nbsp;{detail?.branger_first_name + " " + detail?.branger_last_name}</Typography>
                                    </Box>
                                    <Box sx={{
                                        color: '#dbdbeb99',
                                        wordBreak: "break-word",
                                        marginBottom: '10px',
                                    }}>
                                        <Typography variant="span" sx={{
                                            maxWidth: '130px',
                                            width: '100%',
                                            color: '#fff',
                                        }}>Email:</Typography>
                                        <Typography variant="span">&nbsp;&nbsp;{detail?.branger_email}</Typography>
                                    </Box>
                                    <Box sx={{
                                        color: '#dbdbeb99',
                                        wordBreak: "break-word",
                                        marginBottom: '10px',
                                    }}>
                                        <Typography variant="span" sx={{
                                            maxWidth: '130px',
                                            width: '100%',
                                            color: '#fff',
                                        }}>Phone:</Typography>
                                        <Typography variant="span">&nbsp;&nbsp;{detail?.branger_phone}</Typography>
                                    </Box>
                                    {/* <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: '10px',
                                    color: '#dbdbeb99',
                                    marginBottom: '10px',
                                    '& p': {
                                        '&:first-child': {
                                            maxWidth: '130px',
                                            width: '100%',
                                            color: '#fff',
                                        }
                                    }
                                }}>
                                    <Typography>PICKUP ETA:</Typography>
                                    <Typography>15.2 km 25 mins</Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: '10px',
                                    color: '#dbdbeb99',
                                    marginBottom: '10px',
                                    '& p': {
                                        '&:first-child': {
                                            maxWidth: '130px',
                                            width: '100%',
                                            color: '#fff',
                                        }
                                    }
                                }}>
                                    <Typography>DROP ETA</Typography>
                                    <Typography>NA</Typography>
                                </Box> */}
                                </Box>
                            </Box>
                        </Grid>
                    )
                }) : <Box><LottieLoader /></Box>}
        </Grid >
    )
}

export default BrangerDetailsCard
