import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import CustomButton from "../../theme/style-components/CustomButton";
import CustomSelect from "../../theme/style-components/CustomSelect";
import CustomInput from "../../theme/style-components/CustomInput";
import BrangerDocuments from "./BrangerDocuments";
import { useGetBrangerQuery, useLazyGetUserVehicleTypesQuery, useOnBoardBrangerMutation, usePutBrangerDocumentsMutation } from "../../store/brangers/brangerApiSlice";
import { usePostUsersMutation, usePutUsersMutation } from "../../store/users/usersApiSlice";
import { Avatar, Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import CustomTabs from "../../theme/style-components/CustomTabs";
import ComponentTitle from "../../shared/ComponentTitle";
import TabTitle from "../../shared/TabTitle";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidNumber } from 'libphonenumber-js';
import { showError } from "../../shared/helperFunction";
import CustomDatePicker from "../../theme/style-components/CustomDatePicker";
import moment from "moment";

const phoneRegex =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object({
    firstName: yup
        .string("Enter first name")
        .min(3, "First name must contain 3 characters minimum")
        .required("First Name is required")
        .max(50, "First name can contain a maximum of 50 characters"),
    lastName: yup.string("Enter last name").required("Last name is required"),
    email: yup
        .string("Enter mail")
        .email("Enter a valid email")
        .required("Email address is mandatory"),
    phoneNumber: yup
        .string("Enter a phone number")
        .test('isValid', 'Invalid phone number', (value) => {
            // Ensure the value is a string before parsing
            if (typeof value === 'string') {
                return value ? isValidNumber(value) : false;
            }
            return false; // Return false for non-string values
        }),
    password: yup
        .string("Password")
        .required("Password is required")
        .min(8, "Password should be minimum 8 characters"),
});

const CreateBranger = (props) => {

    //route parameters
    let { id } = useParams();

    //state variables
    const [avatarPreview, setAvatarPreview] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [vehicleOptions, setVehicleOptions] = useState([])
    const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
    const [isBusinessDetailButtonEnabled, setIsBusinessDetailButtonEnabled] =
        useState(true);
    const [ID, setID] = useState(id ? id : null);

    //hooks
    const dispatch = useDispatch();

    //base query hooks

    const {
        isLoading: isBrangerLoading,
        data: branger,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetBrangerQuery(ID);

    const [postUsers, { isLoading: isPostLoading }] = usePostUsersMutation();
    const [onBoardBranger, { isLoading: isOnboardLoading }] = useOnBoardBrangerMutation();
    const [putUser, { isLoading: isPutUserLoading }] = usePutUsersMutation();
    const [getUserVehicleTypes] = useLazyGetUserVehicleTypesQuery();

    const [putBrangerDocuments] =
        usePutBrangerDocumentsMutation();

    //variables
    let newUserID = 0;

    useEffect(() => {
        if (id != null && !isError && !isBrangerLoading) {
            formik.setFieldValue("firstName", branger.data[0].first_name);
            formik.setFieldValue("lastName", branger.data[0].last_name);
            formik.setFieldValue("phoneNumber", branger.data[0].phone);
            formik.setFieldValue("email", branger.data[0].email);
            formik.setFieldValue("isSuperUser", branger.data[0].is_superuser);
            formik.setFieldValue("isActive", branger.data[0].is_active);
            formik.setFieldValue("isKYCVerified", branger.data[0].is_kyc_verified);
            formik.setFieldValue("password", "arbitraryPassword");
            setIsPasswordDisabled(true);
            formik.setFieldValue(
                "isEmailVerified",
                branger.data[0].is_email_verified
            );

            if (branger.data[0].branger_details?.vehicle_type) {
                formikSecondary.setFieldValue(
                    "vehicleType",
                    branger.data[0].branger_details?.vehicle_type
                );
            };

            if (branger.data[0].branger_details?.abn_number) {
                formikSecondary.setFieldValue(
                    "abn",
                    branger.data[0].branger_details?.abn_number
                );
            }

            if (branger.data[0].branger_details?.branger_documents?.license_expiry) {
                const formattedDate = moment(branger.data[0].branger_details?.branger_documents?.license_expiry).toDate();
                formikSecondary.setFieldValue("licence_expiry_date", formattedDate);
            }

            const isVerified = branger?.data[0]?.branger_details?.is_verified;

            if (isVerified === true || isVerified === false) {
                formikSecondary.setFieldValue("isBusinessVerified", isVerified);
            }

            if (branger.data[0].branger_details?.branger_type) {
                formikSecondary.setFieldValue(
                    "brangerType",
                    branger.data[0].branger_details?.branger_type
                );
            }

            setIsBusinessDetailButtonEnabled(false);
        }
    }, [id, branger]);


    useEffect(() => {
        const fetchVehicle = async () => {
            const data = await getUserVehicleTypes().unwrap().then((res) => {
                if (res.data) {
                    setVehicleOptions(res.data)
                }
            })
        };
        fetchVehicle();
    }, [])

    //ref variables
    const inputRef = useRef(null);

    //methods
    const handleFileChange = (event) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setAvatarPreview(fileReader.result);
            }
        };
        fileReader.readAsDataURL(event.target.files[0]);
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            isVerified: false,
            password: "",
            isSuperUser: false,
            isActive: false,
            isEmailVerified: false,
            isKYCVerified: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let formData = new FormData();
            let user = {
                user_type: "BRANGER",
                email: values.email,
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phoneNumber,
                password: values.password,
                dp: "",
                is_superuser: false,
                is_active: values.isActive,
                is_email_verified: values.isEmailVerified,
                is_phone_verified: false,
                is_kyc_verified: values.isKYCVerified,
            };
            Object.keys(user).forEach((key) => {
                formData.append(key, user[key]);
            });
            for (var key of formData.entries()) {
            }

            if (ID === null) {
                try {
                    const response = await postUsers(formData);
                    setIsBusinessDetailButtonEnabled(false);
                    setID(response.data.data.id);
                    newUserID = response.data.data.id;
                    toast.success(`User Created Successfully! with ID ${newUserID}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } catch (error) {
                    showError(error?.data)
                }
            } else {
                formData.append("user_id", ID);
                try {
                    const response = await putUser(formData);
                    toast.success(`User updated successfully! with ID ${ID}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } catch (error) {
                    showError(error?.data)
                }
            }
        },
    });

    const validationSchemaSecondary = yup.object({
        vehicleType: yup
            .string()
            .required("Vehicle type is required"),
        brangerType: yup
            .string()
            .required("Branger type is required"),
        abn: yup
            .string()
            .required("ABN number is required"),
        licence_expiry_date: yup.string().required("Licence expiry date is required"),
    });

    const formikSecondary = useFormik({
        initialValues: {
            vehicleType: 5,
            abn: "",
            isBusinessVerified: false,
            brangerType: "",
            licence_expiry_date: null
        },
        validationSchema: validationSchemaSecondary,
        onSubmit: async (values) => {
            const branger = {
                user_id: ID,
                vehicle_type_id: values.vehicleType,
                abn_number: values.abn,
                is_verified: values.isBusinessVerified,
                branger_type: "FULLTIME",
            };

            const formData = new FormData();

            formData.append("license_expiry", moment(values.licence_expiry_date).format("YYYY-MM-DD"));
            formData.append("user_id", ID);

            try {
                await onBoardBranger(branger).unwrap();
                toast.success("Branger Onboarded Successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                showError(error?.data)
            }

            try {
                const response = await putBrangerDocuments(formData).unwrap();
                refetch();
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    const tabsData = [
        { label: 'Branger Details' },
        { label: 'Document' },
        { label: 'Payments' },
        // Add more tabs as needed
    ];

    const breadcrumbs = [
        <Link to="/brangers">
            Brangers
        </Link>,
        <Typography key="2" variant="body1">
            {id ? "Edit Branger" : "Create Branger"}
        </Typography>,
    ];

    return (
        <Box>
            <TabTitle title={id ? "Edit Branger" : "Create Branger"} />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Button
                            onClick={() => {
                                inputRef.current.click();
                            }}
                        >
                            <Avatar
                                style={{ height: "80px", width: "80px" }}
                                src={avatarPreview ? avatarPreview : null}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <input
                            name="avatar"
                            ref={inputRef}
                            style={{ display: "none" }}
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomInput
                                id="firstName"
                                name="firstName"
                                label="First Name"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.firstName &&
                                    Boolean(formik.errors.firstName)
                                }
                                helperText={
                                    formik.touched.firstName && formik.errors.firstName
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomInput
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.lastName && Boolean(formik.errors.lastName)
                                }
                                helperText={
                                    formik.touched.lastName && formik.errors.lastName
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ marginTop: "20px" }}>
                            <MuiPhoneNumber sx={{
                                '& .MuiInput-root': {
                                    border: '1px solid #dbdbeb61',
                                    borderRadius: '3px',
                                    padding: '10px',
                                    margin: '0',
                                    '&::after': {
                                        display: 'none',
                                    },
                                    '&::before': {
                                        display: 'none',
                                    },

                                },
                                '& .Mui-error': {
                                    borderColor: '#d32f2f',
                                },
                                '& .MuiInputLabel-root': {
                                    position: 'absolute',
                                    top: '-7px',
                                    left: '7px',
                                    background: '#2b2c40',
                                    zIndex: '1',
                                    width: 'fit-content',
                                    padding: '0 5px',
                                },
                                "& input": {
                                    color: "#dbdbebde",
                                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                    '&[type=number]': {
                                        '-moz-appearance': 'textfield',
                                    },
                                },
                                "& .Mui-disabled": {
                                    WebkitTextFillColor: "#dbdbeb61 !important",
                                    '& fieldset': {
                                        borderColor: '#dbdbeb61 !important',
                                    },
                                },
                                '& fieldset': {
                                    borderColor: '#dbdbeb61'
                                },
                                '& .MuiInputBase-root,label': {
                                    color: '#dbdbeb61'
                                },
                                '& svg': {
                                    fill: '#dbdbeb61'
                                },
                                '& .MuiMenu-list': {
                                    backgroundColor: 'rgb(43, 44, 64) !important'
                                },
                                "& fieldset:hover": {
                                    borderColor: "white !important"
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover': {
                                        '& fieldset': {
                                            borderColor: '#dbdbeb61',
                                        },
                                    },
                                },
                                '& .Mui-focused': {
                                    borderWidth: '2px',
                                    borderColor: '#696cff',
                                },
                                '& .MuiFormLabel-root': {
                                    '&.Mui-focused': {
                                        color: 'rgb(105, 108, 255) !important',
                                    },
                                },

                            }}
                                name="phoneNumber"
                                label="Phone Number"
                                id="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={value => formik.setFieldValue("phoneNumber", value)}
                                defaultCountry="au"
                                disableAreaCodes={true}
                                inputProps={{
                                    name: 'Phone Number',
                                    required: true,
                                }}
                                error={
                                    formik.touched.phoneNumber &&
                                    Boolean(formik.errors.phoneNumber)
                                }
                                helperText={
                                    formik.touched.phoneNumber && formik.errors.phoneNumber
                                }
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomInput
                                id="password"
                                type="password"
                                name="password"
                                label="Password"
                                disabled={isPasswordDisabled}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.password && Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password && formik.errors.password
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomInput
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomSelect
                                id="isSuperUser"
                                name="isSuperUser"
                                label="Is Superuser"
                                value={formik.values.isSuperUser}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomSelect
                                name="isActive"
                                label="Is Active"
                                id="isActive"
                                value={formik.values.isActive}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomSelect
                                id="isEmailVerified"
                                name="isEmailVerified"
                                label="Is Email Verified"
                                value={formik.values.isEmailVerified}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <CustomSelect
                                id="isKYCVerified"
                                name="isKYCVerified"
                                value={formik.values.isKYCVerified}
                                label="Is KYC Verified"
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <CustomButton
                            color="primary"
                            type="submit"
                            buttonName="Submit"
                            isLoading={isPostLoading || isPutUserLoading}
                            disabled={isPostLoading || isPutUserLoading}
                            onClick={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </CustomPaperBox>

            <CustomPaperBox>
                <CustomTabs tabsData={tabsData}
                    activeTab={currentTab}
                    onChange={(e, value) => setCurrentTab(value)}>
                    {/* tab 1 */}
                    <Box sx={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <CustomInput
                                        id="abn"
                                        label="ABN Number"
                                        name="abn"
                                        value={formikSecondary.values.abn}
                                        onChange={formikSecondary.handleChange}
                                        error={
                                            formikSecondary.touched.abn &&
                                            Boolean(formikSecondary.errors.abn)
                                        }
                                        helperText={
                                            formikSecondary.touched.abn && formikSecondary.errors.abn
                                        }
                                    />
                                </Box>
                            </Grid>
                            {vehicleOptions?.length !== 0 && <Grid item xs={12} md={6}>
                                <Box>
                                    <CustomSelect
                                        name="vehicleType"
                                        label="Vehicle Type"
                                        id="vehicleType"
                                        value={formikSecondary.values.vehicleType}
                                        onChange={formikSecondary.handleChange}
                                        error={
                                            formikSecondary.touched.vehicleType &&
                                            Boolean(formikSecondary.errors.vehicleType)
                                        }
                                        helperText={
                                            formikSecondary.touched.vehicleType && formikSecondary.errors.vehicleType
                                        }
                                    >
                                        {vehicleOptions?.length !== 0 && vehicleOptions.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>{item.type_name}</MenuItem>
                                            )
                                        })}
                                    </CustomSelect>
                                </Box>
                            </Grid>}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <CustomSelect
                                        id="isBusinessVerified"
                                        name="isBusinessVerified"
                                        label="Is Business Verified"
                                        value={formikSecondary.values.isBusinessVerified}
                                        onChange={formikSecondary.handleChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </CustomSelect>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <CustomSelect
                                        name="brangerType"
                                        label="Branger Type"
                                        id="brangerType"
                                        value={formikSecondary.values.brangerType}
                                        onChange={formikSecondary.handleChange}
                                        error={
                                            formikSecondary.touched.brangerType &&
                                            Boolean(formikSecondary.errors.brangerType)
                                        }
                                        helperText={
                                            formikSecondary.touched.brangerType && formikSecondary.errors.brangerType
                                        }
                                    >
                                        <MenuItem value="FULLTIME">Full Time</MenuItem>
                                        <MenuItem value="FREELANCER">Freelancer</MenuItem>
                                    </CustomSelect>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Box>
                                        <CustomDatePicker
                                            selectedDate={formikSecondary.values.licence_expiry_date}
                                            id="licence_expiry_date"
                                            name="licence_expiry_date"
                                            error={
                                                formikSecondary.touched.licence_expiry_date &&
                                                Boolean(formikSecondary.errors.licence_expiry_date)
                                            }
                                            helperText={
                                                formikSecondary.touched.licence_expiry_date && formikSecondary.errors.licence_expiry_date
                                            }
                                            onChange={date => formikSecondary.setFieldValue('licence_expiry_date', date)}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            </Grid>
                            <Grid alignItems="flex-end" item xs={12} md={4}>
                                <CustomButton
                                    color="primary"
                                    type="submit"
                                    buttonName="Submit"
                                    isLoading={isOnboardLoading}
                                    disabled={isBusinessDetailButtonEnabled || isOnboardLoading}
                                    onClick={formikSecondary.handleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {/* tab 2 */}
                    <BrangerDocuments brangerID={ID} />
                    {/* tab 3 */}
                    <Box>Work in progress</Box>
                </CustomTabs>
            </CustomPaperBox>
        </Box>
    );
};

export default CreateBranger;