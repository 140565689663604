import { Backdrop, Box, Grid, Typography } from "@mui/material";
import CustomDateRangePicker from "../../theme/style-components/CustomDateRangePicker";
import CustomTable from "../../theme/style-components/CustomTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useDeleteOrderMutation, useGetMyOrderQuery, useLazyGetAllOrderPdfQuery, useLazyGetInvoicePrintQuery, useLazyGetOrderPdfQuery } from "../../store/orders/orderApiSlice";
import CustomChips from "../../theme/style-components/CustomChips";
import swal from "sweetalert";
import ComponentTitle from "../../shared/ComponentTitle";
import TabTitle from "../../shared/TabTitle";
import LottieLoader from "../loader/LottieLoader";
import qrLogo from "../../assets/qr_logo.png"
import { showError } from "../../shared/helperFunction";
import CustomModal from "../../theme/style-components/CustomModal";
import { useAddTaskAttachmentsMutation, useDeleteAttachmentMutation, useLazyGetTaskAttachmentsQuery } from "../../store/tasks/taskApiSlice";
import { deleteAttachments, selectAttachments, setAttachments } from "../../store/attachments/attachmentsSlice";
import Compressor from "compressorjs";
import { AddCircle, CloudUpload, Delete } from "@mui/icons-material";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";
import FmdGoodIcon from '@mui/icons-material/FmdGood';

const MyOrders = (props) => {
    const navigate = useNavigate();
    //state variables
    const [idFilter, setidFilter] = useState("");
    const [nameFilter, setNameFilter] = useState("");
    const [ordersToDisplay, setOrdersToDisplay] = useState();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState();
    const [query, setQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [orderStatus, setOrderStatus] = useState("");
    const [searchText, setSearchText] = useState("");
    const [isLottieLoader, setIsLottieLoader] = useState(false);
    //attachment State
    const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
    const [isUploadAttchmentModalOpen, setIsUploadAttachmentModalOpen] = useState(false);
    const [attachID, setAttachID] = useState("");

    // const [attachmentData, setAttachmentsData] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });

    const [selectedOrder, setSelecteOrder] = useState({});

    const [deleteOrder] = useDeleteOrderMutation();

    const dispatch = useDispatch();

    const authState = useSelector(state => state.auth);
    const { userType, userID } = authState || {};

    const toggle = () => {
        setOpen(!open);
    };

    const handleDateChange = (date) => {
        setDateRange(date);
        if (date) {
            setQuery((prevQuery) =>
                (`&from_d=${moment(date.startDate).format("YYYY-MM-DD")}&to_d=${moment(date.endDate).format("YYYY-MM-DD")}`)
            );
        }
        setOpen(!open)
    };

    //base query hooks
    const {
        isLoading,
        data: ordersReturned,
        isSuccess,
        isError,
        error: myOrderError
    } = useGetMyOrderQuery({ page: page, query: query });

    const [getAllOrderPdf] = useLazyGetAllOrderPdfQuery();
    const [getInvoicePrint] = useLazyGetInvoicePrintQuery();
    const [getOrderPdf] = useLazyGetOrderPdfQuery();

    const [deleteAttachment] = useDeleteAttachmentMutation();
    const inputRef = useRef(null);

    const [formValue, setFormValue] = useState({
        comment: "",
        attachment_file: null
    });

    const [error, setError] = useState(false);

    const handleSubmit = () => {
        if (!formValue.attachment_file) {
            setError(true);
        } else {
            handleAddAttachmentSubmit(formValue);
        }
    }


    const attachments = useSelector(selectAttachments) || [];
    const [addTaskAttachments, { isLoading: isAddAttachmentLoading }] = useAddTaskAttachmentsMutation();

    const [
        getTaskAttachments,
        {
            isLoading: isAttachmentLoading,
            data: attachmentDataReturn,
            isSuccess: isAttachmentSuccess,
            isError: isAttachmentError,
            error: attachmentError,
        },
    ] = useLazyGetTaskAttachmentsQuery();

    useEffect(() => {
        if (!isLoading) {
            setOrdersToDisplay(ordersReturned?.data);
            setCount(ordersReturned?.page_details?.count)
        }
    }, [isLoading, page, ordersReturned?.data]);

    const handleExportOrderClicked = async () => {
        try {
            let result = await getAllOrderPdf(
                {
                    idFilter: idFilter,
                    merchantIDFilter: userID,
                    orderStatus: orderStatus,
                    nameFilter: nameFilter,
                    startDate: dateRange.startDate ? moment(dateRange.startDate).format("YYYY-MM-DD") : "",
                    endDate: dateRange.startDate ? moment(dateRange.endDate).format("YYYY-MM-DD") : ""
                }
            ).unwrap();
            toast.success("Packages exported!!", {
                position: toast.POSITION.TOP_RIGHT
            });
            var hiddenElement = document.createElement("a");
            var url = window.URL || window.webkitURL;
            var blobPDF = url.createObjectURL(result);
            hiddenElement.href = blobPDF;
            hiddenElement.target = "_blank";
            hiddenElement.download = `order_print_report.csv`;
            hiddenElement.click();
        } catch (error) {
            toast.error("Something went wrong!!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handleAddAttachmentSubmit = async (formValue) => {
        let formData = new FormData();
        formData.append("order_id", attachID);
        if (formValue.comment) {
            formData.append("comment", formValue.comment);
        };
        formData.append("attachment", formValue.attachment_file);

        try {
            const response = await addTaskAttachments(formData).unwrap();
            if (response?.data) {
                toast.success("Attachment uploaded successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                const refetch = refetchGetTaskAttachment();
                setIsUploadAttachmentModalOpen(false);
            }
        } catch (err) {
            if (err) {
                toast.error(err.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };


    const refetchGetTaskAttachment = () => {
        getTaskAttachments(attachID)
            .unwrap()
            .then((res) => {
                if (res) {
                    dispatch(setAttachments(res?.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // useEffect(() => {
    //     setDateRange({
    //         startDate: new Date(),
    //         endDate: new Date()
    //     });
    //     setQuery((prevQuery) =>
    //         (`&from_d=${moment().format('YYYY-MM-DD')}&to_d=${moment().format('YYYY-MM-DD')}`)
    //     );
    // }, [])


    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&order_search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&order_search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Assuming you're handling file input change event
        setError(false);
        if (!file) {
            return;
        };
        try {
            const compressedFile = await compressImage(file);
            if (compressedFile) {
                setFormValue((pre) => ({ ...pre, attachment_file: compressedFile }))
            }
        } catch (error) {
            console.error('Error compressing image:', error);
        }
    };

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                maxWidth: 1920,
                maxHeight: 1080,
                maxSize: 3 * 1024, // max size in bytes (3 MB)
                success: (compressedBlob) => {
                    const compressedFile = new File([compressedBlob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                    resolve(compressedFile);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    };


    const handleDateRangeChange = (dates) => {
        setDateRange({
            startDate: dates[0],
            endDate: dates[1],
        });

        if (dates[0] && dates[1]) {
            setQuery((prevQuery) =>
                (`&from_d=${moment(dates[0]).format("YYYY-MM-DD")}&to_d=${moment(dates[1]).format("YYYY-MM-DD")}`)
            );
        } else {
            setQuery("");
        }
    };


    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const setSelectedData = (item) => {
        setSelecteOrder(item)
    };

    const handleAttachment = async (orderID) => {
        setAttachID(orderID)
        setIsAttachmentModalOpen(true);
        if (orderID) {
            await getTaskAttachments(orderID)
                .unwrap()
                .then((res) => {
                    if (res) {
                        dispatch(setAttachments(res?.data));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const columns = [
        { id: 'id', label: 'Order ID' },
        { id: 'pickup_date', label: 'Pickup Date' },
        { id: 'pickup_address', label: 'Pickup Address' },
        { id: 'total_jobs', label: 'Total Jobs' },
        { id: 'price', label: 'Price' },
        { id: 'payment_method', label: 'Payment Method' },
        { id: 'task_status', label: 'Task Status' },
        { id: 'status', label: 'Status' },
        { id: 'track_order', label: 'History' },
        { id: 'attachment', label: 'Attachment' },
        { id: 'action', label: 'Action' },
    ];

    const statusMappings = {
        ADDING_PACKAGES: { label: "Draft", color: "info" },
        CREATED: { label: "Draft", color: "info" },
        PROCESSING_PAYMENT: { label: "Payment Pending", color: "warning" },
        PAYMENT_FAILED: { label: "Payment failed", color: "error" },
        SCHEDULED: { label: "Scheduled", color: "primary" },
        COMPLETED: { label: "Completed", color: "success" },

    };

    const getStatusCount = (data, status, color) => {
        const count = data?.packages?.filter((item) => item.task_status === status)?.length;
        return count > 0 ? (
            <CustomChips
                color={color}
                labelColor={color}
                label={`${status.charAt(0) + status.toLowerCase().slice(1)} (${count}/${data?.packages?.length})`} />
        ) : null;
    };

    const renderStatusCounts = (data) => {
        let hasValue = false;

        const checkAndRender = (status, color) => {
            const result = getStatusCount(data, status, color);
            if (result) {
                hasValue = true;
                return result;
            }
            return null;
        };

        return (
            <>
                {checkAndRender("ASSIGNED", "primary")}
                {checkAndRender("UNASSIGNED", "warning")}
                {checkAndRender("STARTED", "info")}
                {checkAndRender("PICKEDUP", "info")}
                {checkAndRender("DELIVERED", "success")}
                {checkAndRender("FAILED", "error")}
                {checkAndRender("CANCELED", "error")}
                {checkAndRender("DELETED", "error")}
                {!hasValue && <Typography variant="body2" style={{ margin: "0 !important" }}>N/A</Typography>}
            </>
        );
    };

    const handleTrackOrderClick = (hash) => {
        if (hash) {
            window.open(`/tracking/${hash}`, "_blank")
        } else {
            toast.error("Tracking hash not found")
        }
    }
    console.log(ordersToDisplay, "ordersToDisplay");

    const myOrderData = ordersToDisplay?.length > 0 ? ordersToDisplay.map((data) => {

        // Previously, the subscription type was fred_pilot, and after changing the subscription, check the amount.
        const is_fred_amount = (data?.quote_distribution?.order_amount / 100) === 0.01

        return ({
            id: data.id,
            pickup_date: moment.utc(data.scheduled_dt).format('DD MMMM YYYY HH:mm'),
            pickup_address: <Typography sx={{ width: "225px" }}>{data.pickup_address_details.address}</Typography>,
            total_jobs: data.packages.length,
            price: <Typography sx={{ width: "115px" }}>
                AUD {data?.subscription?.name === "Pilot_Fred" || is_fred_amount ? "0.00" : (data.quote / 100)}
                {data.payment_skipped && <br />}
                {data.payment_skipped && data?.merchant_address_details?.is_custom_invoice !== true && "(Off Portal)"}
            </Typography>,
            payment_method: data?.subscription?.name === "Pilot_Fred" || is_fred_amount ? <Typography>FRED_PILOT_DELIVERY</Typography> : data.payment_method || "N/A",
            task_status: renderStatusCounts(data),
            status: <Box>
                <CustomChips label={statusMappings[data.order_status]?.label || "N/A"}
                    color={statusMappings[data.order_status]?.color || "info"}
                    labelColor={statusMappings[data.order_status]?.color || "info"} />
            </Box>,
            track_order: <CustomButton buttonName={<Box sx={{ display: "flex", gap: "5px", fontSize: "12px", "& svg": { fill: "#03c3ec !important" } }}>Track order</Box>} onClick={() => handleTrackOrderClick(data?.packages?.[0]?.task_details?.tracking_hash)} color="info" outline />,
            attachment: <CustomButton buttonName="Attachment" onClick={() => handleAttachment(data.id)} color="primary" outline />,
            action: "ACTION"
        })
    }) : []

    const handleCreateClick = () => {
        navigate("/my-orders/create")
    };

    const handleEditClick = () => {
        navigate(`/my-orders/${selectedOrder.id}`)
    };

    const handleExport = () => {
        handleExportOrderClicked();
    };

    const innerTableHeaders = [
        { id: 'package_id', label: 'Package ID', width: "100px" },
        { id: 'price', label: 'Price', width: "80px" },
        { id: 'distance', label: 'Distance', width: "80px" },
        { id: 'delivery_details', label: 'Delivery Details', textAlign: "left", width: "200px" },
        { id: 'post_code', label: 'Post Code', width: "80px" },
        { id: 'status', label: 'Status', width: "150px" },
        { id: 'assigned_branger', label: 'Assigned Branger', width: "150px" },
    ];

    const innerTableData = [];

    if (ordersToDisplay?.length > 0) {
        ordersToDisplay.forEach(data => {
            // Previously, the subscription type was fred_pilot, and after changing the subscription, check the amount.
            const is_fred_amount = (data?.quote_distribution?.order_amount / 100) === 0.01

            const group = data.packages.map(packageData => ({
                task_failed_reason: packageData?.task_failed_reason ? packageData?.task_failed_reason : null,
                package_id: <Box sx={{ display: "flex", gap: "5px" }}><Typography sx={{
                    textWrap: "auto",
                    wordBreak: " break-all",
                }}>{packageData.id}</Typography>{packageData.is_qr_required && <Box sx={{ backgroundColor: "white" }}><img src={qrLogo} height="30" width="30" /></Box>}</Box>,
                price: <Typography> AUD {packageData.quote_distribution?.amount && data?.subscription?.name === "Pilot_Fred" || is_fred_amount ? "0.00" : (packageData.quote_distribution?.amount / 100).toFixed(2)}</Typography>,
                distance: <Typography>{packageData?.quote_distribution?.distance ? `${(packageData.quote_distribution.distance / 1000).toFixed(2) + " " + "km"}` : "NA"}</Typography>,
                delivery_details: <Box>
                    <Typography textAlign="left">Name: {packageData.contact_name}
                        <br />Email: {packageData.contact_email}
                        <br /> Phone: {packageData.contact_phone} <br />
                        Address:  {packageData.drop_address.address} <br />
                        {packageData?.contact_note && <CustomChips textTransform="capitalize" label={`Note : ${packageData.contact_note}`} labelColor="info" color="info" />}
                    </Typography>
                </Box>,
                post_code: <Typography sx={{
                    textWrap: "auto",
                    wordBreak: " break-all",
                }}>{packageData?.drop_address?.postcode}</Typography>,
                status:
                    <Box sx={{ textAlign: "center" }}><CustomChips label={packageData.task_status ? packageData.task_status : "NA"} labelColor="primary" color="primary" />
                        {packageData?.task_failed_reason &&
                            <Box>
                                <Typography variant="span">Failed Reason:</Typography><Typography sx={{ marginLeft: "5px" }} variant="span">{packageData?.task_failed_reason}</Typography>
                            </Box>
                        }</Box>,
                assigned_branger: <Typography sx={{
                    textWrap: "auto",
                    wordBreak: " break-all",
                    margin: "0 auto"
                }}>
                    {packageData.assigned_branger_email
                        ? packageData.assigned_branger_email
                        : "No Branger Assigned"}
                </Typography>,
            }));
            innerTableData.push(group);
        });
    };

    const handleCommentChange = (e) => {
        setFormValue((pre) => ({ ...pre, comment: e.target.value }))
    }

    const handleDelteClick = () => {
        const id = selectedOrder.id
        swal({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            buttons: {
                cancel: 'Cancel',
                confirm: 'Delete',
            },
            dangerMode: true,
        }).then(async (confirmed) => {
            if (confirmed) {
                // User clicked the 'OK' button
                // Perform the desired action
                const response = await deleteOrder(id).unwrap().then((res) => {
                    const filtered = ordersToDisplay.filter((item) => item.id !== id);
                    if (filtered?.length) {
                        setOrdersToDisplay(filtered)
                    }
                    res && toast.success("Order deleted!!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }).catch((err) => {
                    err && toast.error(err.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
            } else {
                // User clicked the 'Cancel' button
                // Handle the cancellation
            }
        });
    };

    const breadcrumbs = [
        <Typography key="1" variant="body1">
            My Orders
        </Typography>,
    ];

    const handlePrintInvoice = async () => {
        try {
            setIsLottieLoader(true);
            let result = await getInvoicePrint(selectedOrder.id).unwrap();
            if (result) {
                setIsLottieLoader(false);
            }
            var hiddenElement = document.createElement('a');
            var url = window.URL || window.webkitURL;
            var blobPDF = url.createObjectURL(result);
            hiddenElement.href = blobPDF;
            hiddenElement.target = '_blank';
            hiddenElement.download = `Brang_invoice_order#${selectedOrder.id}.pdf`;
            hiddenElement.click();
        } catch (error) {
            if (error) {
                setIsLottieLoader(false);
            }
            if (error) {
                showError(error?.data)
            }
        }
    };

    const handlePrint = async () => {
        try {
            setIsLottieLoader(true);
            let result = await getOrderPdf(selectedOrder.id).unwrap();
            if (result) {
                setIsLottieLoader(false);
            }
            var hiddenElement = document.createElement('a');
            var url = window.URL || window.webkitURL;
            var blobPDF = url.createObjectURL(result);
            hiddenElement.href = blobPDF;
            hiddenElement.target = '_blank';
            hiddenElement.download = `order_print_report#${selectedOrder.id}.pdf`;
            hiddenElement.click();
        } catch (error) {
            if (error) {
                setIsLottieLoader(false);
            }
            showError(error?.data);
        }
    };

    const handleAttachmentClose = () => {
        setIsAttachmentModalOpen(false);
    };

    const handleUploadAttachmentClose = () => {
        setIsUploadAttachmentModalOpen(false);
    };

    const handleAttachmentSubmit = () => {
        setIsAttachmentModalOpen(false);
    };

    const handleDeleteAttachments = async (attID) => {
        setIsLottieLoader(true);
        const response = await deleteAttachment(attID)
            .unwrap()
            .then((res) => {
                if (res) {
                    dispatch(deleteAttachments(attID));
                    setIsLottieLoader(false);
                    toast.success("Attachment deleted successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((err) => {
                setIsLottieLoader(false);
                showError(err?.data)
            });
    };

    return (
        <Box >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLottieLoader}
            >
                <LottieLoader />
            </Backdrop>
            <TabTitle title="My Orders" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <Box sx={{
                backgroundColor: 'rgb(43, 44, 64)',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
                borderRadius: '8px',
                padding: '24px',
                marginTop: "20px",
            }}>
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <CustomDateRangePicker dateRange={dateRange} label="Filter By Date" onChange={handleDateRangeChange} />
                    </Grid>
                </Grid>
            </Box>
            {
                ordersToDisplay && count !== undefined ? (
                    <>
                        <CustomTable
                            //pagination props
                            title="My Order"
                            page={page}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}

                            //create button props
                            handleCreateClick={handleCreateClick}
                            createButtonName="Create Order"

                            //edit menu props
                            isShowEditMenu={true}
                            handleEditClick={handleEditClick}

                            //delete menu props
                            isShowDeleteMenu={true}
                            handleDelteClick={handleDelteClick}

                            //action props
                            isActionVisible={true}

                            //collapse props
                            isCollapse={true}

                            //data props
                            data={myOrderData}
                            columns={columns}

                            //print invoice menu props
                            isShowPrintInvoiceMenu={true}
                            handlePrintInvoiceClick={handlePrintInvoice}

                            //search props
                            isShowSearch={true}
                            handleOnSearch={handleOnSearch}
                            searchText={searchText}
                            searchPlaceholder="Search Order"

                            //print menu props
                            isShowPrintMenu={true}
                            handlePrintClick={handlePrint}

                            //export button
                            isShowExportCSV={true}
                            handleExport={handleExport}

                            //set Selected row data
                            setSelectedData={setSelectedData}

                            //innerTable
                            innerTableHeaders={innerTableHeaders}
                            innerTableData={innerTableData}
                            collapseTitle="Drops"
                        />
                    </>
                ) : (
                    <Box>
                        <LottieLoader />
                    </Box>
                )}


            <CustomModal isModalOpen={isAttachmentModalOpen} isShowActionButton={false} title="Attachments" handleClose={handleAttachmentClose} onCancel={handleAttachmentClose}>
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLottieLoader}
                    >
                        <LottieLoader />
                    </Backdrop>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12} lg={12} sm={12} sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "10px"
                        }}>
                            <Box>
                                <Typography>
                                    Order ID: {attachID && attachID}
                                </Typography>
                            </Box>
                        </Grid>
                        <>
                            < Grid item xs={6} md={4} lg={4} sm={6} sx={{
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "10px"
                            }}>
                                <Box sx={{
                                    textAlign: 'center'
                                }}>
                                    <Box sx={{
                                        width: "150px",
                                        height: "150px",
                                        border: "1px solid white",
                                        borderRadius: "10px",
                                        position: "relative",
                                        '& img': {
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: "10px",
                                        }
                                    }}>
                                        <Box sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            right: '0',
                                            bottom: '0',
                                            transform: 'translate(-50%,-50%)',
                                            background: "#696cff",
                                            borderRadius: "50%",
                                            padding: "8px",
                                            width: "40px",
                                            height: "40px",
                                        }}>
                                            <AddCircle cursor="pointer" onClick={() => {
                                                setIsUploadAttachmentModalOpen(true);
                                                setFormValue({})
                                            }} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {attachments?.length > 0 &&
                                <>
                                    {attachments.map((att, index) => {
                                        return (
                                            <>
                                                <Grid item key={index} xs={6} md={4} lg={4} sm={6} sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderRadius: "10px"
                                                }}>
                                                    <Box sx={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <Box sx={{
                                                            width: "150px",
                                                            height: "150px",
                                                            border: "1px solid white",
                                                            borderRadius: "10px",
                                                            position: "relative",
                                                            '& img': {
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'contain',
                                                                borderRadius: "10px",
                                                            }
                                                        }}
                                                        >
                                                            <Box sx={{
                                                                position: "absolute",
                                                                top: "-12px",
                                                                right: "-7px",
                                                                background: "#696cff",
                                                                borderRadius: "50%",
                                                                padding: "7px",
                                                                width: "35px",
                                                                height: "35px",
                                                                "& svg": {
                                                                    fontSize: "22px"
                                                                }
                                                            }}>
                                                                <Delete cursor="pointer"
                                                                    onClick={() => handleDeleteAttachments(att.id)}
                                                                />
                                                            </Box>
                                                            <Link
                                                                to={att.attachment} target="_blank">
                                                                <img src={att.attachment} />
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </>
                            }
                        </>
                    </Grid>
                </>
            </CustomModal >
            <CustomModal
                isModalOpen={isUploadAttchmentModalOpen}
                isShowActionButton={false}
                title="Upload Attachment"
                handleClose={handleUploadAttachmentClose}
                onSubmit={handleAttachmentSubmit} onCancel={handleUploadAttachmentClose}>
                <>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1px solid #dbdbeb61",
                            padding: "14px 10px",
                            borderRadius: "4px",
                            color: "#dbdbebde",
                        }}>
                            <Typography>Attachment</Typography>
                            <Typography>{formValue.attachment_file ? formValue.attachment_file.name : "Not Uploaded"}</Typography>
                            <input
                                type="file"
                                ref={inputRef}
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <CloudUpload
                                cursor="pointer"
                                onClick={() => {
                                    inputRef.current.click();
                                    // setCurrentDocument(document.key);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                        <CustomInput label="Comment" value={formValue.comment} onChange={handleCommentChange} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box sx={{
                            textAlign: 'center',
                            paddingTop: '32px'
                        }}>
                            <Box>
                                <CustomButton onClick={handleSubmit} disabled={isAddAttachmentLoading} isLoading={isAddAttachmentLoading} buttonName="Upload Attachment" color="primary" />
                            </Box>
                        </Box>
                    </Grid>
                </>
            </CustomModal>
        </Box >
    );
};

export default MyOrders;