import { createSlice } from "@reduxjs/toolkit";

const mapSlice = createSlice({
    name: 'map',
    initialState: {
        tasksDate: ''
    },
    reducers: {
        setTasksDate: (state, action) => {
            state.tasksDate = action.payload;
        }
    }
})

export const { setTasksDate } = mapSlice.actions

export default mapSlice.reducer

export const selectTasksDate = (state) => state.map.tasksDate;