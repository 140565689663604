import { Box, Grid, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import LottieLoader from '../loader/LottieLoader';
import TabTitle from '../../shared/TabTitle';
import ComponentTitle from '../../shared/ComponentTitle';
import CustomTable from '../../theme/style-components/CustomTable';
import { useDeleteBufferOrderMutation, useGetFreditOrdersQuery } from '../../store/api-keys-fredit/freditApiKeySlice';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import CustomSelect from '../../theme/style-components/CustomSelect';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import CreateProcessModal from './CreateProcessModal';
import { removeRepeatedWordsWithCommas } from '../../shared/helperFunction';
import moment from 'moment';
import 'moment-timezone';

const Fredit = () => {
    const [freditOrdersToDisplay, setFreditOrdersToDisplay] = useState();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState();
    const [query, setQuery] = useState(``);
    const [searchText, setSearchText] = useState("");
    const [ordersStatus, setOrdersStatus] = useState("none");
    const [selectedRowsIds, setSelectedRowsIds] = useState([]);
    const [isOpenProcessModal, setIsOpenProcessModal] = useState(false);
    const [isShowAllDeleteBtn, setIsShowAllDeleteBtn] = useState(true);
    const [isShowCreateBtn,setIsShowCreateBtn] = useState(true);
    const [isSelectioEnable,setIsSelectioEnable] = useState(true);

    const [deleteBufferOrder] = useDeleteBufferOrderMutation();

    //base query hooks
    const {
        isLoading,
        isError,
        data: freditOrdersReturned,
        refetch: refetchFreditOrders
    } = useGetFreditOrdersQuery({ page: page, query: query });

    useEffect(() => {
        if (!isLoading && !isError) {
            setFreditOrdersToDisplay(freditOrdersReturned?.data);
            setCount(freditOrdersReturned?.page_details?.count)
        }else{
            setFreditOrdersToDisplay([]);
        }
    }, [isLoading, page, freditOrdersReturned?.data, isError]);

    const breadcrumbs = [
        <Typography key="1" variant="body1">
            Fredit
        </Typography>,
    ];

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const setSelectedData = (item) => {
        console.log("row selected")
    };

    const handleCreateClick = () => {
        if (selectedRowsIds?.length > 0) {
            setIsOpenProcessModal(true)
        } else {
            toast.error("Please select the order", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleDelteClick = (selectedID) => {
        let ids = '';
        if (Array.isArray(selectedID)) {
            const idsString = selectedID.join(',');
            ids = idsString;
          } else {
            ids = selectedID;
          }
          if(selected.length > 0){
            swal({
                title: 'Are you sure?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancel',
                    confirm: 'Delete',
                },
                dangerMode: true,
            }).then(async (confirmed) => {
                if (confirmed) {
                    const response = await deleteBufferOrder(ids).unwrap().then((res) => {
                        if (res) {
                            setPage(1);
                        }
                        refetchFreditOrders();
                        resetSelectedRowsIds();
                        res && toast.success("Order deleted!!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }).catch((err) => {
                        if (err) {
                            if (err?.data?.message) {
                                toast.error(err?.data?.message, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                            if (err?.error) {
                                toast.error(err?.error, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                        }
                    });
                } else {
                    // User clicked the 'Cancel' button
                    // Handle the cancellation
                }
            });
          }else{
            toast.error("Please select the order", {
                position: toast.POSITION.TOP_RIGHT
            });    
          }
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    const handleStatusChange = (e) => {
        resetSelectedRowsIds();
        setSearchText('');
        const selectedValue = e.target.value;
        setPage(1);
        setOrdersStatus(selectedValue)
        handleStatusQueryChange(selectedValue);
    };

    const handleStatusQueryChange = (selectedValue) => {
        let newQuery = `&show_processed_orders=false&show_unsupporteed_orders=false&show_deleted_orders=false`;

        if (selectedValue) {
            if (selectedValue === 'none') {
                newQuery = newQuery.replace('&show_processed_orders=false&show_unsupporteed_orders=false&show_deleted_orders=false', '');
            } else if (selectedValue === 'show_processed_orders') {
                newQuery = newQuery.replace('&show_processed_orders=false', '&show_processed_orders=true');
            } else if (selectedValue === 'show_unsupporteed_orders') {
                newQuery = newQuery.replace('&show_unsupporteed_orders=false', '&show_unsupporteed_orders=true');
            } else if (selectedValue === 'show_deleted_orders') {
                newQuery = newQuery.replace('&show_deleted_orders=false', '&show_deleted_orders=true');
            }
        }

        setQuery(newQuery);
    }

    const columns = useMemo(() => {
        let baseColumns = [
            { id: 'id', label: 'Id' },
            { id: 'name', label: 'Name' },
            { id: 'mobile_number', label: 'Mobile Number' },
            { id: 'patient_id', label: 'Patient Id' },
            { id: 'order_id', label: 'Order Id' },
            { id: 'package_id', label: 'Package Id' },
            { id: 'address', label: 'Address' },
            { id: 'created_at', label: 'Created At' },
        ];
    
        if (ordersStatus !== 'show_processed_orders') {
            baseColumns = baseColumns.filter(column => column.id !== 'order_id' && column.id !== 'package_id');
        }
    
        return baseColumns;
    }, [ordersStatus]);

    useEffect(() => {
        if (query.includes('show_deleted_orders=true') || query.includes('show_processed_orders=true') || query.includes('show_unsupporteed_orders=true')) {
            setIsShowAllDeleteBtn(false);
            setIsShowCreateBtn(false);
            setIsSelectioEnable(false);
        } else {
            setIsShowAllDeleteBtn(true);
            setIsShowCreateBtn(true);
            setIsSelectioEnable(true)
        }
    }, [query]);

    const freditOrdersData = freditOrdersToDisplay?.length > 0 ? freditOrdersToDisplay.map((data) => ({
        id: data.id,
        patient_id: data?.patient_id || "NA",
        order_id: data?.created_order || "NA",
        package_id: data?.created_package || "NA",
        name: (data?.patient_title + " " + data.patient_first_name + " " + (data?.patient_last_name ? data?.patient_last_name : "")) || "NA",
        mobile_number: data?.patient_mobile_number || "NA",
        address: <Box>
             {removeRepeatedWordsWithCommas(`${data?.patient_address}, ${data?.patient_suburb}, ${data?.patient_postal_state} ${data?.patient_postcode}`) || "NA"}
        </Box>,
        created_at: data?.created_at ? moment.tz(data?.created_at, 'Australia/Melbourne').format('DD MMMM YYYY HH:mm') : "NA",
    })) : [];

    const handleSelectRow = (selectedRow) => {
        setSelectedRowsIds(selectedRow);
    };

    const handleRefreshClick = async () => {
        setPage(1);
        setSearchText('');
        const newSearchText = '';

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
       const refetch = await refetchFreditOrders().then(res=> {
           if(res.isSuccess){
            resetSelectedRowsIds();
               toast.success("Orders successfully refreshed", {
                   position: toast.POSITION.TOP_RIGHT
               });
           }
       }).catch((err) => {
        toast.error("Orders Refreshed Failed", {
            position: toast.POSITION.TOP_RIGHT
        });
       })
    }

    const handleAllDelete = () => {
        handleDelteClick(selected)
    }

    const resetSelectedRowsIds = () => {
        setSelectedRowsIds([]);
        setSelectAll(false);
        setSelected([]);
    }

    return (
        <Box >
            <TabTitle title="Fredit" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox>
                <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Box>
                            <CustomSelect
                                value={ordersStatus}
                                label="Status"
                                onChange={handleStatusChange}
                            >
                                <MenuItem value="none">---</MenuItem>
                                <MenuItem value="show_processed_orders">Processed</MenuItem>
                                <MenuItem value="show_unsupporteed_orders">Unsupported</MenuItem>
                                <MenuItem value="show_deleted_orders">Deleted</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                </Grid>
            </CustomPaperBox>

            {
                freditOrdersToDisplay && count !== undefined ? (
                    <>
                        <CustomTable
                            //pagination props
                            title="Fredit Orders"
                            page={page}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}

                            //create button props
                            handleCreateClick={handleCreateClick}
                            createButtonName="Process"
                            isShowCreateButton={isShowCreateBtn}

                            isShowRefreshButton={true}
                            handleRefreshClick={handleRefreshClick}

                            //Checkbox
                            isSelectioEnable={isSelectioEnable}
                            onSelect={handleSelectRow}
                            selected={selected}
                            setSelectAll={setSelectAll}
                            setSelected={setSelected}
                            selectAll={selectAll}

                            //delete props
                            isShowAllDeleteBtn={isShowAllDeleteBtn}
                            handleAllDelete={handleAllDelete}

                            //data props
                            data={freditOrdersData}
                            columns={columns}

                            //search props
                            isShowSearch={true}
                            handleOnSearch={handleOnSearch}
                            searchText={searchText}
                            searchPlaceholder="Search"

                            //set Selected row data
                            setSelectedData={setSelectedData}
                        />
                    </>
                ) : (
                    <Box>
                        <LottieLoader />
                    </Box>
                )
            }

            {isOpenProcessModal && <CreateProcessModal resetSelectedRowsIds={resetSelectedRowsIds} refetchFreditOrders={refetchFreditOrders} setPage={setPage} isOpenProcessModal={isOpenProcessModal} setIsOpenProcessModal={setIsOpenProcessModal} selectedRowsIds={selectedRowsIds} />}
        </Box >
    )
}

export default Fredit;
