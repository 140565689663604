import { createSlice } from '@reduxjs/toolkit'


const brangerSlice = createSlice({
    name: 'brangers',
    initialState: {
        brangers: []
    },
    reducers: {
        setBrangers: (state, action) => {
            state.brangers = action.payload;
        },
    }
})

export const { setBrangers } = brangerSlice.actions

export default brangerSlice.reducer;


//export const selectAddresses = (state) => state.merchant.address;
export const selectBrangers = (state) => state.brangers.brangers
export const selectFoo = (state) => state.merchant.foo