import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import successImage from "../../assets/payment-suceess.jpg"

const PaymentSuccess = () => {
    return (
        <Box>
            <Box sx={{
                maxWidth: '800px',
                width: '100%',
                margin: '0 auto',
                height: '400px',
                '& img': {
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                    objectFit: 'contain'
                }
            }}>
                <img src={successImage} />
            </Box>
            <Typography variant='h4' sx={{ color: "#696cff", textAlign: 'center' }}>
                Payment successful!
            </Typography>
        </Box>

    )
}

export default PaymentSuccess;

