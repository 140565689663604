import React, { useEffect } from 'react'
import CreateExternalTransaction from './CreateExternalTransaction'
import { useParams } from 'react-router-dom'
import { useLazyGetSingleExternalTransactionQuery } from '../../store/external-transaction/externalTransactionsApiSlice';
import LottieLoader from '../loader/LottieLoader';

const EditExternalTransaction = () => {
    const { id } = useParams();

    const [getSingleExternalTransaction, {
        isLoading,
        data: dataReturned,
        isSuccess,
    }] = useLazyGetSingleExternalTransactionQuery();

    useEffect(() => {
        const getSingleRecord = async () => {
            try {
                const response = await getSingleExternalTransaction(id).unwrap().then((res) => {
                    console.log(res, "RES");
                }).catch((err) => {
                    console.log(err);
                })
            } catch (err) {
                console.log(err);
            }
        };

        if (id) {
            getSingleRecord()
        }
    }, [id]);

    return (
        isLoading ? <LottieLoader /> : isSuccess && dataReturned && <CreateExternalTransaction transactionData={dataReturned?.data?.[0]} />
    )
}

export default EditExternalTransaction;
