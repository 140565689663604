import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: "order",
    initialState: {
        packages: [],
        pdf: ""
    },
    reducers: {
        setPackages: (state, action) => {
            state.packages = action.payload;
        },
        addToPackages: (state, action) => {
            state.packages.push(action.payload);
        },
        editPackages: (state, action) => {
            const index = state.packages.findIndex(obj => obj.id === action.payload.id);
            if (index !== -1) {
                state.packages[index] = action.payload;
            }
        },
        deletePackages: (state, action) => {
            state.packages = state.packages.filter(obj => obj.id !== action.payload);
        },
    },
});

export const { setPackages, addToPackages, editPackages, deletePackages, getOrderPdf } = orderSlice.actions;

export default orderSlice.reducer;

export const selectPackages = (state) => state.order.packages;