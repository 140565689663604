import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { decryptFn } from "../../shared/helperFunction";
import StatusForUser from './StatusForUser';
import CustomChips from '../../theme/style-components/CustomChips';
import moment from 'moment';

const DeliveryStatus = ({ branger, dropETA, refetchData }) => {
  const isAuth = decryptFn(localStorage.getItem("accessToken"));
  const pickup_address = branger?.pickup_address;
  const theme = useTheme();

  // Use MUI's breakpoints to detect screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // max-width: 600px

  const getStatusCount = (dataStatus, status, color) => {
    const count = dataStatus === status ? 1 : 0
    return count > 0 ? (
      <CustomChips
        sx={{
          position: isSmallScreen ? "relative" : "absolute",
          top: "0",
          right: "0"
        }}
        color={color}
        labelColor={color}
        label={`${status.charAt(0) + status.toLowerCase().slice(1)}`} />
    ) : null;
  };

  const renderStatusCounts = (dataStatus) => {
    let hasValue = false;

    const checkAndRender = (status, color) => {
      const result = getStatusCount(dataStatus, status, color);
      if (result) {
        hasValue = true;
        return result;
      }
      return null;
    };

    return (
      <>
        {checkAndRender("ASSIGNED", "primary")}
        {checkAndRender("UNASSIGNED", "warning")}
        {checkAndRender("STARTED", "info")}
        {checkAndRender("PICKEDUP", "info")}
        {checkAndRender("DELIVERED", "success")}
        {checkAndRender("FAILED", "error")}
        {checkAndRender("CANCELED", "error")}
        {checkAndRender("DELETED", "error")}
        {!hasValue && <Typography variant="body2" style={{ margin: "0 !important" }}>N/A</Typography>}
      </>
    );
  };

  const task_status = branger?.package?.[0]?.task_status;
  const drop_address = branger?.package?.[0]?.drop_address?.address;
  let lastDeliveredIndex = -1;
  branger?.package?.forEach((item, index) => {
    if (item?.task_status === "DELIVERED") {
      lastDeliveredIndex = index;
    }
  });

  return (
    <Box className="delivery_status_main">
      <Box className="delivery_status_container">
        <Box className="delivery_status_header">
          <Typography variant='h2'>{isAuth ? "Delivery Status" : drop_address}</Typography>
          <Button className="refresh-btn" onClick={refetchData}> <RefreshIcon /> Refresh </Button>
        </Box>
        <Box className="timeline">
          {!isAuth ? <StatusForUser dropETA={dropETA} branger={branger} /> :
            <Box>
              <Box className="timeline-item">
                {/* <Box className="time">-</Box> */}
                <Box className={`timeline-dot ${(task_status === "STARTED" || task_status !== "PICKEDUP") ? "active_status warning_color" : ""}`}>P</Box>
                <Box className="timeline-content">
                  <Box className="status">{pickup_address}</Box>
                  {/* <Box className="estimate-card"> Estimated Time of Arrival : 12 Min </Box> */}
                </Box>
              </Box>
              {branger?.package?.length > 0 && branger?.package?.map((item, index) => {
                const delivery_started_time = item.delivery_started_at ? moment.utc(branger?.delivery_started_at).format("HH:mm") : null;
                const delivered_at = item.delivered_at ? moment.utc(branger?.package?.delivered_at).format("HH:mm") : null;
                // Set active only for the last DELIVERED task
                let isActive = (index === lastDeliveredIndex);
                return (
                  <Box className="timeline-item">
                    <Box className="time">
                      {/* <Box className="now-label">Now</Box> */}
                      {/* {item?.task_status} */}
                    </Box>
                    <Box className={`timeline-dot ${(isActive) ? "active_status" : ""}`}>{`D${index + 1}`}</Box>
                    <Box className="timeline-content" sx={{ display: isSmallScreen ? "flex": "block", justifyContent: "space-between", gap: "10px"  }}>
                      <Box className="status">{item?.drop_address?.address}</Box>
                      <Box className="status">{renderStatusCounts(item?.task_status)}</Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default DeliveryStatus