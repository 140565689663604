import { createSlice } from '@reduxjs/toolkit'


const addressBookSlice = createSlice({
    name: 'addressBook',
    initialState: {
        addressBook: [],
        totalRecord: ""
    },
    reducers: {
        setAddressBook: (state, action) => {
            state.addressBook = action.payload.addressBook;
            state.totalRecord = action.payload.totalRecord;
        },
        deleteAddressBook: (state, action) => {
            state.addressBook = state.addressBook.filter((item) => item.id !== action.payload);
            state.totalRecord = Number(state.totalRecord) - 1;
        },
    }
})

export const { setAddressBook, deleteAddressBook } = addressBookSlice.actions

export default addressBookSlice.reducer;

export const selectAddressBook = (state) => state.addressBook;
export const selectTotal = (state) => state.addressBook.totalRecord;