import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/token/",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    signup: builder.mutation({
      query: (credentials) => ({
        url: "/user/merchant_lead_signup/",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    loginWithRefreshToken: builder.mutation({
      query: (refreshToken) => ({
        url: "/token/refresh/",
        method: "POST",
        body: { ...refreshToken },
      }),
    }),
    forgetPassword: builder.mutation({
      query: (email) => ({
        url: "/user/forget_password/",
        method: "POST",
        body: { ...email },
      }),
    }),
    resetPassword: builder.mutation({
      query: (newPassword) => ({
        url: "/user/forget_password/",
        method: "PUT",
        body: { ...newPassword },
      }),
    }),
    loginWithGoogle: builder.mutation({
      query: (auth_token) => ({
        url: "social_auth/google/",
        method: "POST",
        body: { auth_token },
      }),
    }),
  }),
});

export const { useLoginMutation, useLoginWithRefreshTokenMutation, useForgetPasswordMutation, useResetPasswordMutation, useLoginWithGoogleMutation, useSignupMutation } =
  authApiSlice;