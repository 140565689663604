import React, { useEffect, useState } from 'react';
import { selectAddressBook, setAddressBook } from '../../store/address-book/addressBookSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import CustomTable from '../../theme/style-components/CustomTable';
import LottieLoader from '../loader/LottieLoader';
import CustomModal from '../../theme/style-components/CustomModal';
import CreateAddressBook from './CreateAddressBook';
import { useDeleteAddressBookMutation, useGetAddressBooksQuery } from '../../store/address-book/addressBookApiSlice';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import ComponentTitle from '../../shared/ComponentTitle';
import TabTitle from '../../shared/TabTitle';
import { showError } from '../../shared/helperFunction';

const AddressBook = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState();
    const [addressBookToDisplay, setAddressBookToDisplay] = useState();
    const [selectedAddressBook, setSelectedAddressBook] = useState();
    const [isModalOpen, setIsModalOpen] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [query, setQuery] = useState("");
    const [searchText, setSearchText] = useState("");
    const addressBook = useSelector(selectAddressBook);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
        refetch: refetchAddressBook
    } = useGetAddressBooksQuery({ page: page, query: query });
    const [deleteAddressBook, { isDeleteLoading }] = useDeleteAddressBookMutation();

    //hooks
    useEffect(() => {
        if (!isLoading) {
            dispatch(setAddressBook({
                addressBook: dataReturned.data,
                totalRecord: dataReturned?.page_details?.count
            }));
        };
    }, [isLoading, page, dataReturned?.data]);

    //hooks
    useEffect(() => {
        if (addressBook) {
            setAddressBookToDisplay(addressBook?.addressBook);
            setCount(addressBook?.totalRecord)
        }
    }, [addressBook]);

    const setSelectedData = (item) => {
        setSelectedAddressBook(item)
    };

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'phone', label: 'Phone' },
        { id: 'address', label: 'Address' },
        { id: 'postcode', label: 'Postcode' },
        { id: 'note', label: 'Note' },
        { id: 'action', label: 'Action' },
    ];

    const addressData = addressBookToDisplay?.length > 0 ? addressBookToDisplay.map((data) => ({
        id: data.id,
        name: data.contact_name,
        email: data.contact_email,
        phone: data.contact_phone,
        address: data.drop_address.address,
        postcode: data.drop_address.postcode,
        note: data.drop_address.note ? data.drop_address.note : "NA",
        action: "ACTION"
    })) : []

    const handleCreateClick = () => {
        setIsModalOpen(!isModalOpen);
        setIsEdit(false);
    };

    const handleEditClick = () => {
        setIsModalOpen(!isModalOpen)
        setIsEdit(true);
    };

    const handleDeleteClick = async () => {
        swal({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            buttons: {
                cancel: 'Cancel',
                confirm: 'Delete',
            },
            dangerMode: true,
        }).then(async (confirmed) => {
            if (confirmed) {
                try {
                    const res = await deleteAddressBook(selectedAddressBook.id).unwrap();
                    if (res) {
                        refetchAddressBook();
                        toast.success(`Address book deleted successfully`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    };
                } catch (err) {
                    showError(err?.data)
                };
            } else {
                // User clicked the 'Cancel' button
                // Handle the cancellation
            }
        });
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    const breadcrumbs = [
        <Typography key="2" variant="body1">
            Address Book
        </Typography>,
    ];

    return (
        <Box>
            <TabTitle title="Address Book" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            {addressBookToDisplay && !isLoading && count !== undefined ? (
                <>
                    <CustomTable
                        //pagination props
                        title="Branger"
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}

                        //create button props
                        handleCreateClick={handleCreateClick}
                        createButtonName="Create Address Book"

                        //edit menu props
                        isShowEditMenu={true}
                        handleEditClick={handleEditClick}

                        //delete menu props
                        isShowDeleteMenu={true}
                        handleDelteClick={handleDeleteClick}

                        //search props
                        isShowSearch={true}
                        handleOnSearch={handleOnSearch}
                        searchText={searchText}
                        searchPlaceholder="Search"

                        //action props
                        isActionVisible={true}

                        //data props
                        data={addressData}
                        columns={columns}

                        //set Selected row data
                        setSelectedData={setSelectedData}
                    />
                </>
            ) : <Box>
                <LottieLoader />
            </Box>}

            <CustomModal maxWidth="md" title={isEdit ? "Update Address Book" : "Create Address Book"} isModalOpen={isModalOpen}
                isShowActionButton={false}
                handleClose={() => setIsModalOpen(false)} >
                <CreateAddressBook refetchAddressBook={refetchAddressBook} handleClose={() => setIsModalOpen(false)} isEdit={isEdit} ID={selectedAddressBook?.id} />
            </CustomModal>
        </Box>
    )
}

export default AddressBook;
