import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import CheckoutForm from "../payment/CheckoutForm";
import { useLazyGetSavedCardQuery, useStripeIntentWalletMutation } from "../../store/payments/paymentsApiSlice";
import CustomButton from "../../theme/style-components/CustomButton";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import CustomInput from "../../theme/style-components/CustomInput";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LottieLoader from "../loader/LottieLoader";
import ComponentTitle from "../../shared/ComponentTitle";


const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBKEY
);
const Recharge = () => {
    const navigate = useNavigate();
    const authState = useSelector(state => state.auth)
    const { userType, userID } = authState || {}
    const [stripeIntentWallet, { isLoading }] = useStripeIntentWalletMutation();
    const [getSavedCard, { isLoading: isSavedCardLoading, data }] = useLazyGetSavedCardQuery();
    const [message, setMessage] = useState("");

    const [showNewCard, setShowNewCard] = useState(false);
    const [savedCardsData, setSavedCardsData] = useState([])
    const [savedCardsDetails, setSavedCardsDetails] = useState("");
    const [saveCardLoading, setSaveCardLoading] = useState(false);
    const [inputAmount, setInputAmount] = useState();
    //stripe options
    const [options, setOptions] = useState({
        clientSecret: "",
    });

    useEffect(() => {
        if (userID) getSavedCard(userID).unwrap().then((res) => {
            if (res?.data) {
                setSavedCardsData(res?.data)
                setSavedCardsDetails({ amount_cents: Number(inputAmount), payment_method_key: res?.data[0]?.id })
                if (!res?.data?.length) {
                    setShowNewCard(false)
                }
            }
        })
    }, [userID]);


    useEffect(() => {
        const payment = async () => {
            try {
                setSaveCardLoading(true);
                let response = await stripeIntentWallet({
                    amount_cents: Number(inputAmount),
                }).unwrap();
                let successMessage = "";

                if (response) {
                    setSaveCardLoading(false);
                }

                setOptions({
                    clientSecret: response.data.clientSecret,
                });
                setMessage(successMessage);
            } catch (err) {
                if (err) {
                    let errorMessage;
                    setSaveCardLoading(false);
                    for (const key in err.data.errors) {
                        if (Array.isArray(err.data.errors[key]) && err.data.errors[key].length > 0) {
                            errorMessage = err.data.errors[key][0];
                            break;
                        }
                    }
                    if (errorMessage) {
                        toast.error(errorMessage, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            }
        };
        if (showNewCard) {
            payment(inputAmount);
        }
    }, [showNewCard]);


    const handleAmountChange = (e) => {
        setInputAmount(e.target.value * 100)
    };

    const callSavedStripe = async () => {
        try {
            setSaveCardLoading(true);
            const response = await stripeIntentWallet({ amount_cents: inputAmount, payment_method_key: savedCardsDetails?.payment_method_key }).unwrap().then((res) => {
                setSaveCardLoading(false)
                res && toast.success("paid successfully!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (userType === "ADMIN") {
                    navigate("/wallet")
                } else {
                    navigate("/wallet")
                }
            });
        } catch (err) {
            setSaveCardLoading(false);
            if (err) {
                let errorMessage;
                for (const key in err.data.errors) {
                    if (Array.isArray(err.data.errors[key]) && err.data.errors[key].length > 0) {
                        errorMessage = err.data.errors[key][0];
                        break;
                    }
                }
                if (errorMessage) {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        }
    }

    return (
        <Box>
            <ComponentTitle title="Recharge" />
            <CustomPaperBox >
                <Box>
                    <Grid container spacing={2} >
                        {showNewCard && options?.clientSecret ?
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    width: "100%",
                                    maxWidth: "50%",
                                    margin: "0 auto"
                                }}>
                                    <Typography sx={{ color: "#dbdbebde" }} variant="h4">New Card</Typography>
                                    <Box>
                                        <Elements stripe={stripePromise} options={options}>
                                            <CheckoutForm />
                                        </Elements>
                                    </Box>
                                    <Box sx={{
                                        textAlign: "center"
                                    }}>
                                        <CustomButton
                                            color="primary"
                                            type="submit"
                                            buttonName="Back"
                                            onClick={() => setShowNewCard(!showNewCard)}
                                        /></Box>
                                </Box>
                            </Grid>
                            : isSavedCardLoading ? <Grid item lg={12} md={12} sm={12} xs={12}>
                                <LottieLoader />
                            </Grid> :
                                <>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{
                                            width: "100%",
                                            maxWidth: "50%",
                                            margin: "0 auto"
                                        }}>
                                            <Box sx={{
                                                position: 'relative'
                                            }}>
                                                <CustomInput fullWidth type="number"
                                                    onChange={handleAmountChange}
                                                    label="Amount" />
                                                <AttachMoneyIcon sx={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                }} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {savedCardsData?.length !== 0 && <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{
                                            width: "100%",
                                            maxWidth: "50%",
                                            margin: "0 auto"
                                        }}>
                                            <Typography sx={{ color: "#dbdbebde" }} variant="h4">Saved Card</Typography>
                                            {isSavedCardLoading ? <Box>Loading...</Box> :
                                                <Box>
                                                    {savedCardsData.map((card) => {
                                                        return (
                                                            <Box sx={{
                                                                border: savedCardsDetails.payment_method_key === card?.id ? '1px solid #696cff' : "1px solid #dbdbeb4f",
                                                                borderRadius: '5px',
                                                                padding: '5px 10px',
                                                                marginBottom: '10px',
                                                                cursor: "pointer"
                                                            }}
                                                                onClick={() => setSavedCardsDetails({
                                                                    amount_cents: inputAmount,
                                                                    payment_method_key: card?.id
                                                                })}>
                                                                <Typography variant='p'>**** **** **** {card?.card?.last4}</Typography>
                                                                <Typography>{card?.card?.brand}</Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                    <Box sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}>
                                                        <CustomButton
                                                            disabled={saveCardLoading || !inputAmount}
                                                            onClick={() => callSavedStripe()}
                                                            buttonName={saveCardLoading ? "processing..." : "Pay With Saved Card"} color="primary" />
                                                    </Box>
                                                </Box>}
                                        </Box>
                                    </Grid>}
                                </>
                        }
                        {
                            !showNewCard && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    textAlign: "center"
                                }}>

                                    <CustomButton
                                        disabled={!inputAmount}
                                        onClick={() => setShowNewCard(!showNewCard)}
                                        buttonName={userType === "ADMIN" ? "Skip Payment" : "Add New Card"} color="primary" /></Box>
                            </Grid>
                        }
                    </Grid >
                </Box >
            </CustomPaperBox >
        </Box>
    )
};

export default Recharge;