import { createSlice } from "@reduxjs/toolkit";

const attachmentsSlice = createSlice({
    name: "attachments",
    initialState: {
        attachments: [],
    },
    reducers: {
        setAttachments: (state, action) => {
            state.attachments = action.payload;
        },
        deleteAttachments: (state, action) => {
            state.attachments = state.attachments.filter(obj => obj.id !== action.payload);
        },
    },
});

export const { setAttachments, deleteAttachments } = attachmentsSlice.actions;

export default attachmentsSlice.reducer;

export const selectAttachments = (state) => state.attachments.attachments;