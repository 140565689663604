import { createSlice } from '@reduxjs/toolkit'


const refundSlice = createSlice({
    name: 'refundList',
    initialState: {
        refundList: [],
        totalRecord: ""
    },
    reducers: {
        setRefundList: (state, action) => {
            state.refundList = action.payload.refundList;
            state.totalRecord = action.payload.totalRecord;
        },
    }
})

export const { setRefundList } = refundSlice.actions

export default refundSlice.reducer;

export const selectRefund = (state) => state.refundList;
export const selectTotal = (state) => state.refundList.totalRecord;