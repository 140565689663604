import Compressor from "compressorjs";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import CustomTabs from "../../theme/style-components/CustomTabs";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomModal from "../../theme/style-components/CustomModal";
import MerchantAddress from "../profile/MerchantAddress";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDeleteMerchantAddressMutation, useGetMerchantAddressWitUserIdQuery, useLazyGetMerchantQuery, useOnBoardMerchantMutation } from "../../store/merchant/merchantApiSlice";
import { usePostUsersMutation, usePutUsersMutation } from "../../store/users/usersApiSlice";
import { deleteAddressFromState } from "../../store/merchant/merchantSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Avatar, Box, Breadcrumbs, Button, Grid, MenuItem, Stack, Typography } from "@mui/material";
import CustomSelect from "../../theme/style-components/CustomSelect";
import CustomButton from "../../theme/style-components/CustomButton";
import AddressList from "../profile/AddressList";
import { AddCircle } from "@mui/icons-material";
import ComponentTitle from "../../shared/ComponentTitle";
import TabTitle from "../../shared/TabTitle";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidNumber } from 'libphonenumber-js';
import { showError } from "../../shared/helperFunction";

const phoneRegex =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object({
    firstName: yup
        .string("Enter first name")
        .min(3, "First name must contain 3 characters minimum")
        .required("First Name is required")
        .max(50, "First name can contain a maximum of 50 characters"),
    email: yup
        .string("Enter mail")
        .email("Enter a valid email")
        .required("Email address is required"),
    phoneNumber: yup
        .string("Enter a phone number")
        .test('isValid', 'Invalid phone number', (value) => {
            // Ensure the value is a string before parsing
            if (typeof value === 'string') {
                return value ? isValidNumber(value) : false;
            }
            return false; // Return false for non-string values
        }),
    password: yup
        .string("Password")
        .required("Password is required")
        .min(8, "Password should be minimum 8 characters"),
});

const CreateMerchant = (props) => {
    //route parameters
    let { merchantID } = useParams();

    //state variables
    const [avatarPreview, setAvatarPreview] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [showMerchantDialog, setShowMerchantDialog] = useState(false);
    const [isBusinessDetailButtonEnabled, setIsBusinessDetailButtonEnabled] =
        useState(true);
    const [ID, setID] = useState(merchantID ? merchantID : null);
    const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
    const [addressID, setAddressID] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [merchant, setMerchant] = useState({});

    //hooks
    const dispatch = useDispatch();

    //base query hooks
    const [getMerchant, {
        isLoading: isMerchantLoading,
        // data: merchant,
        isSuccess,
        isError,
        error,
    }] = useLazyGetMerchantQuery();

    useEffect(() => {
        const callGetMerchant = async () => {
            const data = await getMerchant(merchantID).unwrap().then((res) => {
                setMerchant(res)
            })
        }
        if (merchantID) {
            callGetMerchant();
        }
    }, [merchantID, ID])

    const [postUsers, { isLoading: isPostUserLoading }] = usePostUsersMutation();
    const [onBoardMerchant, { isLoading: isOnboardLoading }] = useOnBoardMerchantMutation();
    const [putUser, { isLoading: isPutUserLoading }] = usePutUsersMutation();
    const [deleteAddress, { isLoading: isDeleteMerchantAddressLoading }] =
        useDeleteMerchantAddressMutation();

    const { isLoading: isAddressLoading, data: addressData, isError: isAddressError, refetch: refetchAddress } =
        useGetMerchantAddressWitUserIdQuery({ userID: merchantID });

    //variables
    let newUserID = 0;

    //use effect hooks
    useEffect(() => {
        if (merchantID != null && !isError && !isMerchantLoading && merchant?.data) {
            formik.setFieldValue("firstName", merchant?.data[0]?.first_name);
            formik.setFieldValue("lastName", merchant?.data[0]?.last_name);
            formik.setFieldValue("phoneNumber", merchant?.data[0]?.phone);
            formik.setFieldValue("email", merchant?.data[0]?.email);
            formik.setFieldValue("isSuperUser", merchant?.data[0]?.is_superuser);
            formik.setFieldValue("isActive", merchant?.data[0]?.is_active);
            formik.setFieldValue("isKYCVerified", merchant?.data[0]?.is_kyc_verified);
            formik.setFieldValue("password", 123456789);

            setIsPasswordDisabled(true);
            setAvatarPreview(merchant?.data[0].dp)
            formik.setFieldValue(
                "isEmailVerified",
                merchant?.data[0]?.is_email_verified
            );
            if (merchant?.data[0]?.merchant_details?.business_name) {
                formikSecondary.setFieldValue(
                    "businessName",
                    merchant?.data[0]?.merchant_details?.business_name
                );
            }

            if (merchant?.data[0]?.merchant_details?.abn_number) {
                formikSecondary.setFieldValue(
                    "abn",
                    merchant?.data[0]?.merchant_details?.abn_number
                );
            }

            const isVerified = merchant?.data[0]?.merchant_details?.is_verified;

            if (isVerified === true || isVerified === false) {
                formikSecondary.setFieldValue("isBusinessVerified", isVerified);
            }

            setIsBusinessDetailButtonEnabled(false);
        }
    }, [merchantID, merchant]);

    //ref variables
    const inputRef = useRef(null);

    //methods
    const handleFileChange = (event) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setAvatarPreview(fileReader.result);
                const fileObj = event.target.files && event.target.files[0];

                if (!fileObj) {
                    return;
                }

                new Compressor(fileObj, {
                    quality: 0.6,
                    maxWidth: 1920,
                    maxHeight: 1080,
                    maxSize: 3 * 1024, // max size in bytes (3 MB)
                    success: (compressedFile) => {
                        const file = new File([compressedFile], fileObj.name, { type: fileObj.type });
                        formik.setFieldValue("dp", file)
                    },
                    error: (err) => {
                        console.log(err.message);
                    },
                });
                // formik.setFieldValue("dp", event.target.files[0])
            }
        };
        fileReader.readAsDataURL(event.target.files[0]);
    };

    const editMerchantAddress = (editId) => {
        setAddressID(editId);
        editId && setShowMerchantDialog(true);
    };

    const deleteMerchantAddress = async (deleteID) => {
        var answer = window.confirm(
            "Are you sure you want to delete the selected address?"
        );
        if (answer) {
            try {
                const response = await deleteAddress(deleteID).unwrap();
                dispatch(deleteAddressFromState(deleteID))
                setIsOpen(false)
            } catch (error) {
                showError(error?.data)
            }
        } else {
            //some code
        }
    };

    //formik variables
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            // isVerified: false,
            password: "",
            isSuperUser: false,
            isActive: false,
            isEmailVerified: false,
            isKYCVerified: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let formData = new FormData();
            let user = {
                user_type: "MERCHANT",
                email: values.email,
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phoneNumber,
                password: values.password,
                dp: values.dp,
                is_superuser: false,
                is_active: values.isActive,
                is_email_verified: values.isEmailVerified,
                is_phone_verified: false,
                is_kyc_verified: values.isKYCVerified,
            };

            if (!values.dp) {
                delete user["dp"]
            };

            if (values.password === 123456789) {
                delete user["password"]
            };

            Object.keys(user).forEach((key) => {
                formData.append(key, user[key]);
            });
            for (var key of formData.entries()) {
            }

            if (ID === null) {
                const response = await postUsers(formData).unwrap().then((res) => {
                    if (res.data) {
                        newUserID = res.data.id;
                        toast.success(`User Created Successfully! with ID ${res.data.id}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsBusinessDetailButtonEnabled(false);
                        setID(res.data.id);
                    }
                }).catch((err) => {
                    showError(err?.data)
                });
            } else {
                formData.append("user_id", ID);
                try {
                    const response = await putUser(formData);
                    toast.success(`User Updated Successfully! with ID ${ID}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } catch (error) {
                    showError(error?.data)
                }
            }
        },
    });

    const validationSchemaSecondary = yup.object({
        businessName: yup
            .string()
            .min(3, "Bussiness name must contain 3 characters minimum")
            .required("Bussiness name is required")
            .max(50, "Bussiness name can contain a maximum of 50 characters"),
        abn: yup
            .string()
            .required("ABN number is required")
    });

    const formikSecondary = useFormik({
        initialValues: {
            businessName: "",
            abn: "",
            isBusinessVerified: false,
        },
        validationSchema: validationSchemaSecondary,
        onSubmit: async (values) => {
            const merchant = {
                user_id: ID,
                business_name: values.businessName,
                abn_number: values.abn,
                is_verified: values.isBusinessVerified,
            };
            try {
                await onBoardMerchant(merchant).unwrap();
                toast.success("Merchant Onboarded Successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    //TabPanel

    const tabProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const closeMenuMethod = (value) => {
        setIsOpen(value);
    }

    const tabsData = [
        { label: 'Merchant Details' },
        { label: 'Shops' },
        { label: 'Payments' },
        // Add more tabs as needed
    ];

    const handleClose = () => {
        setAddressID(0);
        setShowMerchantDialog((prevState) => !prevState);
    };

    const breadcrumbs = [
        <Link to="/merchants">
            Merchants
        </Link>,
        <Typography key="2" variant="body1">
            {merchantID ? "Edit Merchant" : "Create Merchant"}
        </Typography>,
    ];

    return (
        <Box>
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <TabTitle title={merchantID ? "Edit Merchant" : "Create Merchant"} />
            <CustomPaperBox>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Button
                                onClick={() => {
                                    inputRef.current.click();
                                }}
                            >
                                <Avatar
                                    style={{ height: "80px", width: "80px" }}
                                    src={avatarPreview ? avatarPreview : null}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <input
                                name="avatar"
                                ref={inputRef}
                                style={{ display: "none" }}
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomInput
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName && formik.errors.firstName
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomInput
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.lastName && Boolean(formik.errors.lastName)
                                    }
                                    helperText={
                                        formik.touched.lastName && formik.errors.lastName
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ marginTop: "20px" }}>
                                <MuiPhoneNumber sx={{
                                    '& .MuiInput-root': {
                                        border: '1px solid #dbdbeb61',
                                        borderRadius: '3px',
                                        padding: '10px',
                                        margin: '0',
                                        '&::after': {
                                            display: 'none',
                                        },
                                        '&::before': {
                                            display: 'none',
                                        },

                                    },
                                    '& .Mui-error': {
                                        borderColor: '#d32f2f',
                                    },
                                    '& .MuiInputLabel-root': {
                                        position: 'absolute',
                                        top: '-7px',
                                        left: '7px',
                                        background: '#2b2c40',
                                        zIndex: '1',
                                        width: 'fit-content',
                                        padding: '0 5px',
                                    },
                                    "& input": {
                                        color: "#dbdbebde",
                                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0,
                                        },
                                        '&[type=number]': {
                                            '-moz-appearance': 'textfield',
                                        },
                                    },
                                    "& .Mui-disabled": {
                                        WebkitTextFillColor: "#dbdbeb61 !important",
                                        '& fieldset': {
                                            borderColor: '#dbdbeb61 !important',
                                        },
                                    },
                                    '& fieldset': {
                                        borderColor: '#dbdbeb61'
                                    },
                                    '& .MuiInputBase-root,label': {
                                        color: '#dbdbeb61'
                                    },
                                    '& svg': {
                                        fill: '#dbdbeb61'
                                    },
                                    '& .MuiMenu-list': {
                                        backgroundColor: 'rgb(43, 44, 64) !important'
                                    },
                                    "& fieldset:hover": {
                                        borderColor: "white !important"
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover': {
                                            '& fieldset': {
                                                borderColor: '#dbdbeb61',
                                            },
                                        },
                                    },
                                    '& .Mui-focused': {
                                        borderWidth: '2px',
                                        borderColor: '#696cff',
                                    },
                                    '& .MuiFormLabel-root': {
                                        '&.Mui-focused': {
                                            color: 'rgb(105, 108, 255) !important',
                                        },
                                    },

                                }}
                                    name="phoneNumber"
                                    label="Phone Number"
                                    id="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={value => formik.setFieldValue("phoneNumber", value)}
                                    defaultCountry="au"
                                    disableAreaCodes={true}
                                    inputProps={{
                                        name: 'Phone Number',
                                        required: true,
                                    }}
                                    error={
                                        formik.touched.phoneNumber &&
                                        Boolean(formik.errors.phoneNumber)
                                    }
                                    helperText={
                                        formik.touched.phoneNumber && formik.errors.phoneNumber
                                    }
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomInput
                                    type="password"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    disabled={isPasswordDisabled}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password && Boolean(formik.errors.password)
                                    }
                                    helperText={
                                        formik.touched.password && formik.errors.password
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomInput
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomSelect
                                    id="isSuperUser"
                                    name="isSuperUser"
                                    label="Is Superuser"
                                    value={formik.values.isSuperUser}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </CustomSelect>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomSelect
                                    name="isActive"
                                    label="Is Active"
                                    id="isActive"
                                    value={formik.values.isActive}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </CustomSelect>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomSelect
                                    id="isEmailVerified"
                                    name="isEmailVerified"
                                    label="Is Email Verified"
                                    value={formik.values.isEmailVerified}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </CustomSelect>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <CustomSelect
                                    id="isKYCVerified"
                                    name="isKYCVerified"
                                    value={formik.values.isKYCVerified}
                                    label="Is KYC Verified"
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </CustomSelect>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomButton
                                isLoading={isPostUserLoading || isPutUserLoading}
                                disabled={isPostUserLoading || isPutUserLoading}
                                color="primary"
                                type="submit"
                                buttonName="Submit"
                                onClick={formik.handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </form>
            </CustomPaperBox>

            <CustomPaperBox>
                <CustomTabs tabsData={tabsData}
                    activeTab={currentTab}
                    onChange={(e, value) => setCurrentTab(value)}>
                    {/* tab 1 */}
                    <Box sx={{ marginTop: "20px" }}>
                        <form onSubmit={formikSecondary.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <CustomInput
                                            label="Business Name"
                                            variant="outlined"
                                            fullWidth
                                            id="businessName"
                                            name="businessName"
                                            value={formikSecondary.values.businessName}
                                            error={
                                                formikSecondary.touched.businessName &&
                                                Boolean(formikSecondary.errors.businessName)
                                            }
                                            helperText={
                                                formikSecondary.touched.businessName && formikSecondary.errors.businessName
                                            }
                                            onChange={formikSecondary.handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <CustomInput
                                            label="ABN Number"
                                            variant="outlined"
                                            fullWidth
                                            id="abn"
                                            name="abn"
                                            value={formikSecondary.values.abn}
                                            onChange={formikSecondary.handleChange}
                                            error={
                                                formikSecondary.touched.abn &&
                                                Boolean(formikSecondary.errors.abn)
                                            }
                                            helperText={
                                                formikSecondary.touched.abn && formikSecondary.errors.abn
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <CustomSelect
                                            id="isBusinessVerified"
                                            name="isBusinessVerified"
                                            label="Is Business Verified"
                                            value={formikSecondary.values.isBusinessVerified}
                                            onChange={formikSecondary.handleChange}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </CustomSelect>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} sx={{ marginTop: "16px" }}>
                                    <CustomButton
                                        color="primary"
                                        type="submit"
                                        buttonName="Submit"
                                        isLoading={isOnboardLoading}
                                        disabled={isBusinessDetailButtonEnabled || isOnboardLoading}
                                        onClick={formikSecondary.handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    {/* tab 2 */}
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <h2>Pickup Addresses</h2>
                                <AddressList
                                    merchantID={ID}
                                    isAddressLoading={isAddressLoading}
                                    addressData={addressData}
                                    isAddressError={isAddressError}
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    refetchAddress={refetchAddress}
                                    editMerchantAddress={editMerchantAddress}
                                    deleteMerchantAddress={deleteMerchantAddress}
                                />
                                <Box sx={{ justifyContent: "center", display: "flex" }}>
                                    <Button sx={{
                                        color: "#696cff"
                                    }}
                                        onClick={() => {
                                            setAddressID(0);
                                            setShowMerchantDialog((prevState) => !prevState);
                                        }}
                                    >
                                        <AddCircle fontSize="large" />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* tab 3 */}
                    <Box>Item Three</Box>
                </CustomTabs>

                <CustomModal handleClose={handleClose} isModalOpen={showMerchantDialog}
                    title={addressID === 0 ? "Add Address" : "Edit Address"}
                    isShowActionButton={false}>
                    <MerchantAddress
                        title={addressID === 0 ? "Add Address" : "Edit Address"}
                        userEditing={false}
                        merchantID={ID}
                        setIsOpen={setIsOpen}
                        refetchAddress={refetchAddress}
                        addressID={addressID}
                        setShowMerchantDialog={setShowMerchantDialog}
                    />
                </CustomModal>
            </CustomPaperBox>
        </Box >
    );
};

export default CreateMerchant;