import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDRMPR6CsWoj8OXherH96CZIkr_NSY-Txs",
    authDomain: "brangtest-81aa8.firebaseapp.com",
    databaseURL: "https://brangtest-81aa8-default-rtdb.firebaseio.com",
    projectId: "brangtest-81aa8",
    storageBucket: "brangtest-81aa8.appspot.com",
    messagingSenderId: "756510553119",
    appId: "1:756510553119:web:a35223043fa28ac52f512a",
    measurementId: "G-YM6NL2GKND"
  };
  
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);