import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import CustomButton from './CustomButton'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CustomPopup = ({ type,titleText, maxWidth, children, open, Transition, handleClose, isTitleDisplay }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth={maxWidth}
            aria-describedby="alert-dialog-slide-description"
            sx={{'& .MuiPaper-root':{backgroundColor:'#2b2c40', maxWidth: "512px"}}}
        >
            <DialogContent sx={{paddingTop:'40px'}}>
            {type && <Box sx={{textAlign:'center',marginBottom: "20px",'& svg':{color:'#f8bb86',fontSize:'80px'}}}><ErrorOutlineIcon /></Box>}
            {isTitleDisplay && <DialogTitle sx={{textAlign:'center',color:'#fff', fontSize: "30px"}}>{titleText}</DialogTitle>}                
                <DialogContentText id="alert-dialog-slide-description" sx={{color:'#fff'}}>
                    {children}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default CustomPopup