import { useState } from "react";
import { useResetTaskMutation } from "../../store/tasks/taskApiSlice";
import CustomSelect from "../../theme/style-components/CustomSelect";
import CustomButton from "../../theme/style-components/CustomButton";
import { Box, Grid, MenuItem } from "@mui/material";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import { toast } from "react-toastify";
import CustomInput from "../../theme/style-components/CustomInput";

const ResetDelivery = (props) => {
    const [selectedStatusValue, setSelectedStatusValue] = useState(null);
    const [reason, setReason] = useState("");
    const [isTaskReset, setIsTaskReset] = useState(false);
    const handleStatusChange = (event) => {
        setSelectedStatusValue(event.target.value);
    }

    //base query logic
    const [resetTask, { isLoading }] = useResetTaskMutation();

    return (
        <>
            <Grid item xs={12} md={12}>
                <Box>
                    <CustomSelect
                        defaultValue={30}
                        value={selectedStatusValue}
                        onChange={handleStatusChange}
                        label="Status"
                    >
                        <MenuItem value={null}>None</MenuItem>
                        <MenuItem value={"UNASSIGNED"}>Unassigned</MenuItem>
                        <MenuItem value={"ASSIGNED"}>Assigned</MenuItem>
                        <MenuItem value={"STARTED"}>Started</MenuItem>
                        <MenuItem value={"PICKEDUP"}>Pickedup</MenuItem>
                        <MenuItem value={"DELIVERED"}>Delivered</MenuItem>
                        <MenuItem value={"FAILED"}>Failed</MenuItem>
                        <MenuItem value={"CANCELED"}>Canceled</MenuItem>
                        <MenuItem value={"DELETED"}>Deleted</MenuItem>
                    </CustomSelect>
                </Box>
            </Grid>
            {selectedStatusValue === "UNASSIGNED" && <Grid item xs={12} md={12}>
                <Box>
                    <CustomCheckBox
                        label="Delete Assigned Branger?"
                        checked={isTaskReset}
                        onChange={() => {
                            setIsTaskReset((prevState) => !prevState);
                        }}
                    />
                </Box>
            </Grid>}

            {(selectedStatusValue === "CANCELED" || selectedStatusValue === "FAILED") && <Grid item xs={12} md={12}>
                <Box>
                    <CustomInput
                        label="Reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </Box>
            </Grid>}

            <Grid item xs={12} md={12}>
                <Box sx={{ textAlign: "center" }}>
                    <CustomButton
                        onClick={async () => {
                            if (!props.assignTask) {
                                let data = {
                                    order_id: props.orderID,
                                    task_status: selectedStatusValue === "SELECT STATUS" ? null : selectedStatusValue,
                                    task_reset: isTaskReset,
                                };

                                if ((selectedStatusValue === "CANCELED" || selectedStatusValue === "FAILED") && reason) {
                                    data.failed_reason = reason
                                }

                                try {
                                    const response = await resetTask(data).unwrap();
                                    toast.success(
                                        `Order ${props.orderID} updated successfully`,
                                        {
                                            position: toast.POSITION.TOP_RIGHT,
                                        }
                                    );
                                    if (response) {
                                        props.handleRefetch();
                                        props.setShowResetTask(false);
                                    }
                                } catch (error) {
                                    toast.error(`${error.data.errors.non_field_errors[0]}`, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            }
                            else {
                                const taskData = {
                                    task_id: props.taskID,
                                    task_status: selectedStatusValue === "SELECT STATUS" ? null : selectedStatusValue,
                                    task_reset: isTaskReset,
                                }

                                if ((selectedStatusValue === "CANCELED" || selectedStatusValue === "FAILED") && reason) {
                                    taskData.failed_reason = reason
                                }

                                try {
                                    const response = await resetTask(taskData).unwrap();
                                    toast.success(
                                        `Task ${props.taskID} updated successfully`,
                                        {
                                            position: toast.POSITION.TOP_RIGHT,
                                        }
                                    );
                                    if (response) {
                                        props.setShowResetTask(false);
                                    }
                                } catch (error) {
                                    toast.error(`${error.data.errors.non_field_errors[0]}`, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            }
                        }}
                        buttonName="Submit"
                        color="primary"
                    />
                </Box>
            </Grid>
        </>
    );
};

export default ResetDelivery;