import React from 'react'
import OrderDetails from './OrderDetails'
import DriverDetails from './DriverDetails'
import DeliveryStatus from './DeliveryStatus'
import './style.css';
import { Box, Typography } from '@mui/material';

const DrawerContent = ({ multiBranger, dropETA, refetchData }) => {
    return (
        <div className="orderMain">
            <OrderDetails multiBranger={multiBranger} />
            {multiBranger && multiBranger?.length > 0 && multiBranger?.map((branger, index) => {
                return (
                    <React.Fragment key={index}>
                        <DriverDetails branger={branger} />
                        <DeliveryStatus refetchData={refetchData} dropETA={dropETA} branger={branger} />
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default DrawerContent;