import React from 'react'
import CustomPopup from '../../theme/style-components/CustomPopup';
import { Box, Link } from '@mui/material';
import CustomButton from '../../theme/style-components/CustomButton';

const ErrorPopup = ({ isErrorFredModalOpen, handleClose, errorMessageFred }) => {
    const message = errorMessageFred === "out of subscription boundary" ? <Box sx={{ textAlign: "center", marginBottom: '25px', '& a': { color: '#696cff', textDecoration: "none" } }}>This address is over the 10km maximum allowance for the Pilot. Please email <Link href="mailto:support@brang.com.au">support@brang.com.au</Link> for a quote and delivery availability.</Box> :
        errorMessageFred === "Your maximum of 1 daily deliveries has been reached. Please contact support@brang.com.au for further assistance." ?
            <Box sx={{ textAlign: "center", marginBottom: '25px', '& a': { color: '#696cff', textDecoration: "none" } }}>Your maximum of 1 daily deliveries has been reached. Please contact <Link href="mailto:support@brang.com.au">support@brang.com.au</Link> for further assistance.</Box> :
            <Box sx={{ textAlign: "center", marginBottom: '25px', '& a': { color: '#696cff', textDecoration: "none" } }}>You have reached your maximum 30 free deliveries for this month. Your delivery allowance will be reset next month. Please contact <Link href="mailto:support@brang.com.au">support@brang.com.au</Link> for further assistance.</Box>

    return (
        <CustomPopup type="error" open={isErrorFredModalOpen} handleClose={handleClose}>
            <Box sx={{ textAlign: "center", marginBottom: '25px', '& a': { color: '#696cff', textDecoration: "none" } }}>{errorMessageFred}</Box>
            <Box sx={{ textAlign: 'center' }}><CustomButton buttonName="Ok" color="primary" onClick={handleClose} /></Box>
        </CustomPopup>
    )
}

export default ErrorPopup;