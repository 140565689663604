import { createSlice } from '@reduxjs/toolkit'

const externalTransactionsSlice = createSlice({
    name: 'externalTransaction',
    initialState: {
        externalTransactions: [],
        totalRecord: ""
    },
    reducers: {
        setExternalTransactions: (state, action) => {
            state.externalTransactions = action.payload.externalTransactions;
            state.totalRecord = action.payload.totalRecord;
        },
    }
})

export const { setExternalTransactions } = externalTransactionsSlice.actions

export default externalTransactionsSlice.reducer;

export const selectExternalTransactions = (state) => state.externalTransactions;
export const selectTotal = (state) => state.externalTransactions.totalRecord;