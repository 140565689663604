import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, Polyline, InfoWindow } from "google-maps-react";
import { useLazyGetPackageTrackingQuery } from '../../store/tasks/taskApiSlice';
import { useParams } from 'react-router-dom';
import LottieLoader from '../loader/LottieLoader';
import BrangerDetailsCard from './BrangerDetailsCard';
import driverVan from "../../assets/driver-van.png"
import { decryptFn } from '../../shared/helperFunction';


const TrackingPage = ({ google }) => {
    const mapRef = useRef(null);
    const [directions, setDirections] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [waypoints, setWaypoints] = useState([]);
    const [mapBounds, setMapBounds] = useState(null);
    const [polylinePaths, setPolylinePaths] = useState([]);
    const polylineRef = useRef(null);
    const intervalRef = useRef(null);
    const [getPackageTracking, { isLoading, data: trackingData }] = useLazyGetPackageTrackingQuery();
    const { hash } = useParams();
    const [responseMessage, setResponseMessage] = useState(null);
    const [multiBranger, setMultiBranger] = useState([]);

    useEffect(() => {
        fetchMapData()
      }, [])
    
      const fetchMapData = async () => {
        try {
          const response = await getPackageTracking(hash).unwrap();
          const { data, message } = response;
    
          if (message) {
            setResponseMessage(message)
          };
    
          if (data && data?.pickup) {
    
            // Specify the property you want to check (e.g., 'city')
            const propertyToCheck = 'branger_details';
    
            // Check if the specified property is null in at least one object
            const isBrangerNotAssigned = data?.packages.some(obj => obj[propertyToCheck] === null);
    
            // setIsBrangerDetailsNull(data?.packages.some(obj => obj[propertyToCheck] === null));
    
            if (!isBrangerNotAssigned) {
              let temp = data?.packages;
              let pickup = data?.pickup;
              let temp_array = [];
              temp.map((item) => {
                let branger_id = temp_array.find((obj) => item?.branger_details?.user === obj?.user)
                if (branger_id === undefined) {
                  let temp_obj = {
                    branger_id: item?.branger_details?.id,
                    user: item?.branger_details?.user,
                    branger_email: item?.branger_details?.branger_email,
                    branger_first_name: item?.branger_details?.branger_first_name,
                    branger_last_name: item?.branger_details?.branger_last_name,
                    branger_phone: item?.branger_details?.branger_phone,
                    pickup: { lat: Number(data?.pickup?.lat), lng: Number(data?.pickup?.long) },
                    lat: item?.branger_details.lat,
                    long: item?.branger_details.long,
                    business_name: item?.business_name,
                    customer_email: item?.customer_email,
                    customer_name: item?.customer_name,
                    customer_phone: item?.customer_phone,
                    task_status: item?.task_status,
                    drop_address: {
                      address: item?.drop_address?.address,
                    },
                    package: [item],
                    pickup_address: data?.pickup.address
                  }
                  temp_array.push(temp_obj)
                }
                else {
                  branger_id.package.push(item)
                }
              })
    
            //   if (pickup) {
            //     setPoints((prev) => [...prev, { lat: Number(pickup?.lat), lng: Number(pickup?.long) }]);
            //   };          
            //   if (temp?.length > 0) {
            //     temp?.map((pack) => {
            //       setPoints((prev) => [...prev, { lat: Number(pack?.drop_address?.lat), lng: Number(pack?.drop_address?.long), package: pack }]);
            //     })
            //   }
              setMultiBranger(temp_array);
            }
          }
          if (message) {
            setResponseMessage(message)
          }
        } catch (err) {
          console.log(err);
        }
      };

    const getDirections = () => {
        const directionsService = new google.maps.DirectionsService();

        const requests = origins.map((origin, index) => {
            const request = {
                origin,
                destination: destinations[index],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,

            };

            if (destinations[index] && destinations[index].length > 0) {
                request.destination = destinations[index]
            };

            if (waypoints[index] && waypoints[index].length > 0) {
                request.waypoints = waypoints[index].map(waypoint => ({
                    location: waypoint.location,
                    stopover: true
                }));
            }

            return request;
        });

        Promise.all(
            requests.map(request =>
                new Promise((resolve, reject) => {
                    directionsService.route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            resolve(result);
                        } else {
                            reject(status);
                        }
                    });
                })
            )
        )
            .then(results => {
                setDirections(results);
            })
            .catch(error => {
                console.error('Error fetching directions:', error);
            });
    };

    const updateOriginLocation = async () => {
        try {
            const response = await getPackageTracking(hash).unwrap();
            const { data, message } = response;

            if (data && data?.pickup) {
                let temp = data?.packages;
                let temp_array = []
                temp.map((item) => {
                    let branger_id = temp_array.find((obj) => item.branger_details.id == obj.branger_id)
                    if (branger_id == undefined) {
                        let temp_obj = {
                            branger_id: item?.branger_details?.id,
                            branger_email: item?.branger_details?.branger_email,
                            branger_first_name: item?.branger_details?.branger_first_name,
                            branger_last_name: item?.branger_details?.branger_last_name,
                            branger_phone: item?.branger_details?.branger_phone,
                            pickup: { lat: Number(data?.pickup?.lat), lng: Number(data?.pickup?.long) },
                            lat: item?.branger_details.lat,
                            long: item?.branger_details.long,
                            business_name: item?.business_name,
                            customer_email: item?.customer_email,
                            customer_name: item?.customer_name,
                            customer_phone: item?.customer_phone,
                            task_status: item?.task_status,
                            drop_address: {
                                address: item?.drop_address?.address,
                            },
                            package: [item],
                            pickup_address: data?.pickup.address
                        }
                        temp_array.push(temp_obj)
                    }
                    else {
                        branger_id.package.push(item)
                    }
                })
                const start = []
                if (temp_array.length) {
                    temp_array.map((item) => {
                        start.push({ lat: Number(item.lat), lng: Number(item.long) })
                    })
                };
                // setOrigins(start);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const adjustMapBounds = () => {
        const bounds = new google.maps.LatLngBounds();

        directions.forEach(direction => {
            const { routes } = direction;

            routes.forEach(route => {
                const { legs } = route;

                legs.forEach(leg => {
                    const { steps } = leg;

                    steps.forEach(step => {
                        const { start_location, end_location } = step;

                        bounds.extend(start_location);
                        bounds.extend(end_location);
                    });
                });
            });
        });

        origins.forEach(origin => bounds.extend(origin));
        if (destinations.length > 0) {
            destinations.forEach(destination => bounds.extend(destination));
        }

        if (waypoints.length > 0) {
            waypoints.forEach(waypointGroup => {
                waypointGroup.forEach(waypoint => {
                    bounds.extend(waypoint.location);
                });
            });
        }

        mapRef?.current?.map?.fitBounds(bounds);
        setMapBounds(bounds);
    };

    const updatePolylinePaths = () => {
        const paths = directions.map(direction => {
            const { routes } = direction;
            const path = [];

            routes.forEach(route => {
                const { overview_path } = route;

                path.push(...overview_path);
            });
            return path;
        });

        setPolylinePaths(paths);
    };

    const token = decryptFn(localStorage.getItem("accessToken"));

    const request = {
        method: 'SUBSCRIBE',
        params: ["tracking_hash"],
        tracking_hash: hash,
    };

    useEffect(() => {
        if (!isLoading && trackingData) {

            const wsClient = new WebSocket(`ws://api.testnet.brangchain.com.au/ws/tracking/?tracking_hash=${hash}`);
            wsClient.onopen = () => {
                wsClient.send(JSON.stringify(request));
            };

            wsClient.onmessage = (evt) => {
                const brangersData = JSON.parse(evt.data);
                if (brangersData?.data) {
                    const start = []
                    if (brangersData?.data?.length) {
                        brangersData?.data.map((item) => {
                            start.push({ lat: Number(item.lat), lng: Number(item.long) })
                        })
                    };
                    setOrigins(start);
                    getDirections();
                }
            };

            // wsClient.onclose = () => console.log('ws closed');
            return () => {
                wsClient.close();
            };
        }
    }, [trackingData, isLoading, origins]);


    // useEffect(() => {
    //     if (origins?.length) {
    //         getDirections();
    //     }

    //     intervalRef.current = setInterval(updateOriginLocation, 30000);

    //     return () => {
    //         clearInterval(intervalRef.current);
    //     };
    // }, [origins, destinations, waypoints]);

    useEffect(() => {
        if (directions.length > 0 || google) {
            if (origins?.length) {
                // adjustMapBounds();
                updatePolylinePaths();
            }
        }
    }, [directions, google, origins, destinations, waypoints]);

    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedMarkerAddress, setSelectedMarkerAddress] = useState('');
    const [selectedMarkerETA, setSelectedMarkerETA] = useState('');
    const [showInfoWindow, setShowInfoWindow] = useState('');
    const [ETA, setETA] = useState([]);

    const handleMarkerClick = async (markerLocation, originIndex, waypointIndex = null) => {
        try {
            let address = '';
            let eta = '';
            let distance = '';

            const origin = origins[originIndex];

            // Calculate ETA and distance from the origin to the clicked marker
            const service = new google.maps.DistanceMatrixService();
            const destinations = [markerLocation];

            // Add waypoints' locations if applicable
            if (waypointIndex !== null) {
                waypoints[originIndex].forEach(waypoint => {
                    destinations.push(waypoint.location);
                });
            }

            const matrixOptions = {
                origins: [origin],
                destinations,
                travelMode: google.maps.TravelMode.DRIVING,
            };

            const response = await new Promise((resolve, reject) => {
                service.getDistanceMatrix(matrixOptions, (result, status) => {
                    if (status === google.maps.DistanceMatrixStatus.OK) {
                        resolve(result);
                    } else {
                        reject(status);
                    }
                });
            });

            if (response.rows.length > 0 && response.rows[0].elements.length > 0) {
                eta = response.rows[0].elements[0].duration.text;
                distance = response.rows[0].elements[0].distance.text;
            }

            // Fetch address for the clicked marker
            const geocoder = new google.maps.Geocoder();
            const geocodeResult = await new Promise((resolve, reject) => {
                geocoder.geocode({ location: markerLocation }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        resolve(results);
                    } else {
                        reject(status);
                    }
                });
            });

            address = geocodeResult[0].formatted_address;

            // Update selected marker and its address/ETA/distance
            setSelectedMarker(markerLocation);
            setSelectedMarkerAddress(address);
            setSelectedMarkerETA(`${eta} (${distance})`);
            setShowInfoWindow(true);
        } catch (error) {
            console.error('Error fetching address, ETA, and distance:', error);
        }
    };

    const renderMarkers = () => {
        const markers = [];

        directions.forEach((direction, index) => {
            const { routes } = direction;
            const origin = origins[index];
            const destination = destinations[index];
            const waypointGroup = waypoints[index];

            markers.push(
                <Marker
                    key={`origin-${index}`}
                    position={origin}
                    onClick={() => handleMarkerClick(origin, index)}
                    title={`Origin ${index + 1}`}
                    icon={{
                        url: driverVan,
                        fillColor: '#EB00FF',
                        scale: 7,
                    }}
                />
            );

            if (waypointGroup && waypointGroup.length > 0) {
                waypointGroup.forEach((waypoint, waypointIndex) => {
                    markers.push(
                        <Marker
                            key={`waypoint-${index}-${waypointIndex}`}
                            position={waypoint.location}
                            title={`Waypoint ${waypointIndex + 1} for Origin ${index + 1}`}
                            onClick={() => handleMarkerClick(waypoint.location, index, waypointIndex)}
                            label={{
                                text: `${waypointIndex === 0 ? "P" : "D" + (waypointIndex)}`,
                                color: '#FFFFFF',
                                fontSize: '16px',
                            }}
                        />
                    );
                });
            }

            markers.push(
                <Marker
                    key={`destination-${index}`}
                    position={destination}
                    title={`Destination ${index + 1}`}
                    // onClick={() => handleMarkerClick(destination, index)}
                    onClick={() => handleMarkerClick(destination, index)}
                    label={{
                        text: `D${waypointGroup.length}`,
                        color: '#FFFFFF',
                        fontSize: '16px',
                    }}
                />
            );
        });

        return markers;
    };

    const renderPolylines = () => {
        return polylinePaths.map((path, index) => (
            <Polyline
                key={`polyline-${index}`}
                path={path}
                strokeColor="#0000FF"
                strokeOpacity={0.8}
                strokeWeight={2}
                ref={polylineRef}
            />
        ));
    };

    const [isBrangerSelected, setIsBrangerSelected] = useState(false);

    useEffect(() => {
        if (multiBranger && selectedMarker) {
            let isSelectedBranger = false;
            multiBranger.map((item) => {
                if (Number(item.lat) === selectedMarker.lat && Number(item.long) === selectedMarker.lng) {
                    isSelectedBranger = true;
                }
            });
            setIsBrangerSelected(isSelectedBranger);
        }
    }, [selectedMarker, multiBranger, isBrangerSelected]);

    const calculateETA = async (loc) => {
        const latOrigin = { lat: loc.origin.lat, lng: loc.origin.lng };
        const latDestination = { lat: loc.destination.lat, lng: loc.destination.lng };

        let eta = '';
        let distance = '';

        const service = new google.maps.DistanceMatrixService();
        const result = await new Promise((resolve, reject) => {
            service.getDistanceMatrix(
                {
                    origins: [latOrigin],
                    destinations: [latDestination],
                    travelMode: 'DRIVING',
                },
                (response, status) => {
                    if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                        eta = response.rows[0].elements[0].duration.text;
                        distance = response.rows[0].elements[0].distance.text;
                        resolve(`${distance} ${eta}`);
                    } else {
                        reject(null);
                    }
                }
            );
        });
        return result;
    };

    // Function to fetch the ETA for an item and store it in the state
    const fetchAndSetETA = async (loc, index) => {
        const result = await calculateETA(loc);
        setETA((prevETA) => {
            const updateETA = [...prevETA];
            if (loc.etaType === "pickup") {
                updateETA[index] = { pickup: result };
            } if (loc.etaType === "drop") {
                updateETA[index] = { drop: result };
            }
            return updateETA;
        });
    };

    useEffect(() => {
        // Fetch the ETA for each item in the multiple array
        multiBranger.forEach((item, index) => {
            if (item.task_status === "STARTED") {
                fetchAndSetETA(({
                    origin: {
                        lat: Number(item.lat),
                        lng: Number(item.long)
                    }, destination: item.pickup, etaType: "pickup"
                }), index);
            } if (item.task_status === "PICKEDUP") {
                fetchAndSetETA(({
                    origin: {
                        lat: Number(item.lat),
                        lng: Number(item.long)
                    }, destination: { lat: Number(item.package[0].drop_address.lat), lng: Number(item.package[0].drop_address.long) }, etaType: "drop"
                }), index);
            }
        });
    }, [multiBranger]);

    return (
        <Box>
            {responseMessage === "order COMPLETED!" ?
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Grid item>
                        <Paper elevation={3}
                            style={{ padding: '1rem 10rem', background: 'green', color: 'white' }}>
                            <Typography variant="h5" align="center" style={{ fontSize: "14px" }}>
                                Order Delivered Successfully.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid> :
                <>
                    <Box sx={{
                        height: '400px',
                        marginBottom: '20px',
                        '&>div': {
                            height: '400px !important',
                        },
                    }}>
                        <Map
                            style={{ height: "100%", width: "100%" }}
                            google={google}
                            zoom={14}
                            initialCenter={{ lat: -37.82296, lng: 144.979401 }}
                            ref={mapRef}>
                            {directions.length > 0 && renderPolylines()}
                            {directions.length > 0 && renderMarkers()}
                            {selectedMarker && (
                                <InfoWindow
                                    position={selectedMarker}
                                    onCloseClick={() => {
                                        setSelectedMarker(null);
                                        showInfoWindow(!showInfoWindow)
                                    }}
                                    visible={showInfoWindow}
                                >
                                    <Box p={2}>
                                        <Typography variant="subtitle1">Address: {selectedMarkerAddress}</Typography>
                                        {!isBrangerSelected && <Typography variant="subtitle1">{`${selectedMarkerETA ? "ETA:" : ""} ${selectedMarkerETA}`}</Typography>}
                                    </Box>
                                </InfoWindow>
                            )}
                        </Map>
                    </Box>
                    <BrangerDetailsCard isLoading={isLoading} multiBranger={multiBranger} />
                </>
            }
        </Box>
    );
};


export default GoogleApiWrapper({
    apiKey: 'AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0'
})(TrackingPage);

