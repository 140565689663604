import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import logo from "../../assets/logos/sidebar-logo.png";
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';

const Header = ({ handleToggle, isDrawerOpen }) => {
  const theme = useTheme();

  // Use MUI's breakpoints to detect screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // max-width: 600px

  return (
    <Box className='header'>
      <Box className="main_logo">
        <img src={logo} alt="" />
      </Box>
      <Box className="left_arrow">
        <Button onClick={handleToggle} >{isDrawerOpen ?  <ArrowBackIosIcon className='left_toggle_arrow' /> : isSmallScreen ? <ExpandLessSharpIcon className="top_arrow"/> : <ArrowForwardIosIcon/> }</Button>
      </Box>
    </Box>
  )
}

export default Header