import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut, setUser } from "../store/auth/authSlice";
import { decryptFn, encryptFn } from "../shared/helperFunction";


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = decryptFn(localStorage.getItem("accessToken"))
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }
        return headers;
    },
});

let isRefreshing = false;
let failedRequests = [];
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.data?.code === "token_not_valid") {
        const refreshToken = decryptFn(localStorage.getItem("refreshToken"))
        if (!isRefreshing) {
            isRefreshing = true;
            try {
                const refreshResult = await baseQuery({
                    url: '/token/refresh/',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: { refresh: refreshToken },
                }, api, extraOptions);
                if (refreshResult?.data) {
                    localStorage.setItem("refreshToken", encryptFn(refreshResult?.data.refresh));
                    localStorage.setItem("accessToken", encryptFn(refreshResult?.data.access));
                    const user = api.getState().auth.user
                    api.dispatch(setCredentials({
                        refreshToken: encryptFn(refreshResult.data.refresh),
                        accessToken: encryptFn(refreshResult.data.access), ...user
                    }));
                    setTimeout(async () => {
                        for (const failedRequest of failedRequests) {
                            try {
                                const retryResult = await baseQuery(failedRequest.args, failedRequest.api, failedRequest.extraOptions);
                                failedRequest.resolve(retryResult);
                            } catch (error) {
                                failedRequest.reject(error);
                            }
                        }
                        // Clear the array of failed requests
                        failedRequests = [];
                    }, 1000); // Add a delay of 1 second before retrying failed requests
                }
                else {
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("accessToken", "");
                    const port = window.location.port
                    window.location.href = window.location.protocol + '//' + window.location.hostname + (port ? ':' + port : '') + '/login';
                    api.dispatch(logOut());
                    api.dispatch(setUser(null));
                }
            } finally {
                localStorage.setItem("refreshToken", "");
                localStorage.setItem("accessToken", "");
                const port = window.location.port
                window.location.href = window.location.protocol + '//' + window.location.hostname + (port ? ':' + port : '') + '/login';
                isRefreshing = false;
                api.dispatch(logOut());
                api.dispatch(setUser(null));
            }
        }
        // Queue up the failed request
        return new Promise((resolve, reject) => {
            failedRequests.push({ args, api, extraOptions, resolve, reject });
        });
    }
    return result;
};
export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
})