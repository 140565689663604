import React from 'react';
import OrdersChart from './OrdersChart';
import MerchantChart from './MerchantChart';
import BrangersChart from './BrangersChart';
import { Box, Grid } from '@mui/material';
import Map from './Map';
import RevenueChart from './RevenueChart';
import ExpenseChart from './ExpenseChart';

const AdminDashboard = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        padding: "24px",
                        backgroundColor: "#2B2C40",
                        borderRadius: "10px",
                        height:"100%"
                    }}>
                        <OrdersChart />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        padding: "24px",
                        backgroundColor: "#2B2C40",
                        borderRadius: "10px",
                        height:"100%"
                    }}>
                        <MerchantChart />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                <Box sx={{
                        padding: "24px",
                        backgroundColor: "#2B2C40",
                        borderRadius: "10px",
                        height:"100%"
                    }}>
                    <BrangersChart />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                     <Box sx={{
                        padding: "24px",
                        backgroundColor: "#2B2C40",
                        borderRadius: "10px",
                        height:"100%"
                    }}>
                    <Map />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                <Box sx={{
                        padding: "24px",
                        backgroundColor: "#2B2C40",
                        borderRadius: "10px",
                        height:"100%"
                    }}>
                    <RevenueChart />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                <Box sx={{
                        padding: "24px",
                        backgroundColor: "#2B2C40",
                        borderRadius: "10px",
                        height:"100%"
                    }}>
                    <ExpenseChart />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminDashboard;
