import { Link, useNavigate, useParams } from "react-router-dom";
import { useLazyGetSavedCardQuery, usePayWithWalletMutation, useStripeIntentMutation } from "../../store/payments/paymentsApiSlice";
import { useLazyGetUserProfileQuery } from "../../store/users/usersApiSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLazyGetMyOrderQuery } from "../../store/orders/orderApiSlice";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { selectCurrentUserObject } from "../../store/auth/authSlice";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../payment/CheckoutForm";
import CustomButton from "../../theme/style-components/CustomButton";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import ComponentTitle from "../../shared/ComponentTitle";
import TabTitle from "../../shared/TabTitle";


const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBKEY
);

const PackagePayment = () => {
    const navigate = useNavigate()
    let { orderID } = useParams();
    const authState = useSelector(state => state.auth)
    const { userType, userID } = authState || {}
    const [stripeIntent, { isLoading }] = useStripeIntentMutation();
    const [payWithWallet, { isLoading: payWithWalletLoading }] = usePayWithWalletMutation();
    const [getSavedCard, { isLoading: isSavedCardLoading, data }] = useLazyGetSavedCardQuery();
    const [getUserProfile] = useLazyGetUserProfileQuery();
    // const savedCardsData = data?.data

    //state variables
    const [flag, setFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [key, setKey] = useState("");
    const [paymentFlag, setPaymentFlag] = useState(false);
    const [showNewCard, setShowNewCard] = useState(false);
    const [savedCardsData, setSavedCardsData] = useState([])
    const [savedCardsDetails, setSavedCardsDetails] = useState("");
    const [saveCardLoading, setSaveCardLoading] = useState(false);
    const [getMyOrder, { isLoading: isGetMyOrderLoading }] = useLazyGetMyOrderQuery();

    //stripe options
    const [options, setOptions] = useState({
        clientSecret: "",
    });

    let user = useSelector(selectCurrentUserObject);

    useEffect(() => {
        const fetchSavedCard = async () => {
            try {
                const response = await getSavedCard(userID).unwrap().then((res) => {
                    if (res?.data) {
                        setSavedCardsData(res?.data)
                        setSavedCardsDetails({ order_id: orderID, payment_method_key: res?.data[0]?.id })
                        if (!res?.data?.length) {
                            setShowNewCard(false)
                        }
                    }
                })
            } catch (err) {
                console.log(err);
            }
        }

        if (userID) {
            fetchSavedCard()
        }
    }, [userID]);

    useEffect(() => {
        const payment = async () => {
            try {
                let response = await stripeIntent({
                    order_id: Number(orderID),
                }).unwrap();
                let successMessage = "";

                if (!response.data.skip) {
                    setPaymentFlag(true);
                    successMessage += "Secret Key: ";
                    successMessage += response.data.clientSecret;
                }
                else {
                    // setPaymentFlag(false);
                    successMessage += 'Custom Invoice is set. Skipping Stripe.';
                    toast.success("skipping the stripe payment: custom invoice enabled", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    if (userType === "ADMIN") {
                        navigate("/orders")
                    } else {
                        navigate("/myorders")
                    }
                }
                setOptions({
                    clientSecret: response.data.clientSecret,
                });
                setMessage(successMessage);
            } catch (error) {
                console.log(error, "error");
                // let errorMessage = "";
                // errorMessage += error.data.message && error.data.message;
                // errorMessage += " ";
                const errorMessage = error.data && error.data.message;
                setMessage(errorMessage);
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (userType === "ADMIN") {
                    navigate("/orders")
                } else {
                    navigate("/myorders")
                }
            }
        };
        if (!flag) {
            // payment(orderID);
            setFlag(true);
        }
    }, []);

    const [isCustomInvoice, setIsCustomInvoice] = useState(false);

    useEffect(() => {

        const fetchMyOrder = async () => {
            try {
                const result = await getMyOrder({ page: 1, query: `&order_id=${orderID}` }).unwrap().then((res) => {
                    if (res?.data[0]) {
                        if (res?.data[0].merchant_address_details.is_custom_invoice === true) {
                            setIsCustomInvoice(true);
                        };
                    };
                });
            } catch (error) {
                toast.error(`Something went wrong!!`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }

        if (userType === "MERCHANT") {
            fetchMyOrder()
        }

    }, [userType, isCustomInvoice])


    useEffect(() => {
        const payment = async () => {
            try {
                let response = await stripeIntent({
                    order_id: Number(orderID),
                }).unwrap();
                let successMessage = "";

                if (!response.data.skip) {
                    setPaymentFlag(true);
                    successMessage += "Secret Key: ";
                    successMessage += response.data.clientSecret;
                }
                else {
                    // setPaymentFlag(false);
                    successMessage += 'Custom Invoice is set. Skipping Stripe.';
                    toast.success("skipping the stripe payment: custom invoice enabled", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    if (userType === "ADMIN") {
                        navigate("/orders")
                    } else {
                        navigate("/myorders")
                    }
                }

                setOptions({
                    clientSecret: response.data.clientSecret,
                });
                setMessage(successMessage);
            } catch (error) {
                console.log(error, "error");
                // let errorMessage = "";
                // errorMessage += error.data.message && error.data.message;
                // errorMessage += " ";
                const errorMessage = error.data && error.data.message;
                setMessage(errorMessage);
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (userType === "ADMIN") {
                    navigate("/orders")
                } else {
                    navigate("/myorders")
                }
            }
        };
        if (showNewCard) {
            payment(orderID);
            // setFlag(true);
        };
    }, [showNewCard]);

    const callSavedStripe = async () => {
        try {
            setSaveCardLoading(true)
            const response = await stripeIntent(savedCardsDetails).unwrap().then((res) => {
                setSaveCardLoading(false)
                res && toast.success("paid successfully!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (userType === "ADMIN") {
                    navigate("/orders")
                } else {
                    navigate("/myorders")
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleWalletPay = async (e) => {
        let response = await payWithWallet({
            order_id: Number(orderID),
        }).unwrap().then((res) => {
            if (res) {
                toast.success(`${res.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (userType === "ADMIN") {
                    navigate("/orders")
                } else {
                    navigate("/my-orders")
                }
            }
        }).catch((err) => {
            if (err) {
                let errorMessage;
                for (const key in err.data.errors) {
                    if (Array.isArray(err.data.errors[key]) && err.data.errors[key].length > 0) {
                        errorMessage = err.data.errors[key][0];
                        break;
                    }
                }
                if (errorMessage) {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                };
                if (errorMessage) {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                if (err?.data?.message) {
                    toast.error(err?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        });
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await getUserProfile()
                    .unwrap()
                    .then((res) => {
                        console.log("refresh the balance");
                    })
            } catch (err) {
                console.log(err);
            }
        }

        fetchUserProfile()
    }, []);

    const breadcrumbs = [
        <Link key="1" to={userType === "ADMIN" ? "/orders" : "/my-orders"}>
            {userType === "ADMIN" ? "Orders" : "My Orders"}
        </Link>,
        <Typography key="2" variant="body1">
            Payment
        </Typography>,
    ];

    console.log(isCustomInvoice, "isCustomInvoice");

    return (
        <Box >
            <TabTitle title="Package Payment" />
            <ComponentTitle breadcrumbs={breadcrumbs} />

            <CustomPaperBox>
                <Grid container spacing={2} >
                    {!isCustomInvoice && showNewCard && options?.clientSecret ?
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{
                                width: "100%",
                                maxWidth: "50%",
                                margin: "0 auto"
                            }}>
                                <Typography sx={{ color: "#dbdbebde" }} variant="h4">New Card</Typography>
                                <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm />
                                </Elements>
                            </Box>
                            <Box sx={{
                                textAlign: "center"
                            }}>
                                <CustomButton
                                    color="secondary"
                                    type="submit"
                                    buttonName="Back"
                                    onClick={() => setShowNewCard(!showNewCard)}
                                /></Box>
                        </Grid>
                        : isSavedCardLoading ? <Grid item lg={12} md={12} sm={12} xs={12}><Box sx={{
                            width: '100%',
                            maxWidth: "50%",
                            margin: '0 auto',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>Loading...</Box> </Grid> :
                            <>
                                {userType !== "ADMIN" && !isCustomInvoice && <Box sx={{
                                    width: "100%",
                                    maxWidth: "50%",
                                    margin: "0 auto",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "20px"
                                }}>

                                    <Typography style={{
                                        display: "flex",
                                    }}>Available Balance: {
                                            user?.wallet_balance_cents === 0 || user?.wallet_balance_cents ?
                                                <Typography style={{
                                                    fontWeight: "500",
                                                    marginLeft: "10px"
                                                }}>$ {user?.wallet_balance_cents / 100}</Typography> : <CircularProgress size={20} />
                                        }
                                    </Typography>
                                    <CustomButton
                                        color="primary"
                                        onClick={handleWalletPay}
                                        disabled={payWithWalletLoading}
                                        isLoading={payWithWalletLoading}
                                        buttonName="Pay With Wallet"
                                    />
                                </Box>
                                }
                                {!isCustomInvoice && savedCardsData?.length !== 0 && <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box sx={{
                                        width: "100%",
                                        maxWidth: "50%",
                                        margin: "0 auto"
                                    }}>
                                        <Typography variant="h4">Saved Card</Typography>
                                        {isSavedCardLoading ? <Box>Loading...</Box> :
                                            <Box>
                                                {savedCardsData.map((card) => {
                                                    return (
                                                        <Box sx={{
                                                            border: savedCardsDetails.payment_method_key === card?.id ? '1px solid #696cff' : "1px solid #dbdbeb4f",
                                                            borderRadius: '5px',
                                                            padding: '5px 10px',
                                                            marginBottom: '10px',
                                                            cursor: "pointer"
                                                        }}
                                                            onClick={() => setSavedCardsDetails({ order_id: orderID, payment_method_key: card?.id })}>
                                                            <Typography variant='p'>**** **** **** {card?.card?.last4}</Typography>
                                                            <Typography>{card?.card?.brand}</Typography>
                                                        </Box>
                                                    );
                                                })}
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <CustomButton
                                                        color="primary"
                                                        type="submit"
                                                        disabled={saveCardLoading}
                                                        isLoading={saveCardLoading}
                                                        onClick={() => callSavedStripe()}
                                                        buttonName="Submit"
                                                    />
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>}
                            </>
                    }
                    {!showNewCard && <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{
                            textAlign: "center"
                        }}><CustomButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{
                                    textTransform: "capitalize",
                                    minWidth: "120px",
                                    backgroundColor: "rgb(19, 148, 255)",
                                    color: "rgb(255, 255, 255)",
                                    borderRadius: "20px",
                                    marginTop: "10px",
                                }}
                                onClick={() => setShowNewCard(!showNewCard)}
                                buttonName={userType === "ADMIN" || isCustomInvoice ? "Skip Payment" : "Add New Card"}
                            /></Box>
                    </Grid>}
                </Grid>
            </CustomPaperBox>
        </Box >
    );
};

export default PackagePayment;