import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import profileimg from '../../assets/dashboard-image/profile-img.png'
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { logOut, selectCurrentUserObject, setUserType } from '../../store/auth/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ThemeContext } from '../../theme/theme-context/ThemeContext';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    marginTop: '12px',
    // width: `calc(100% - 100px)`,
    backgroundColor: 'unset',
    boxShadow: 'none',
    [theme.breakpoints.up('xs')]: {
        width: '100%',
        zIndex: '0',
        padding: '0px 24px',
        zIndex: '999',

    },
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - 90px)`,
        paddingLeft: '25px !important',
        zIndex: '999'
    },

    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: 260, // Assuming drawerWidth is 240 as per your original code        
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - 240px)`,
            paddingLeft: '25px !important',
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const Header = ({ open, handleDrawer, setOpen }) => {
    let user = useSelector(selectCurrentUserObject);
    const { dp = "", first_name = "" } = user;
    // Use useContext to access the current theme and toggle function
    const { theme, toggleTheme } = useContext(ThemeContext);
    const [anchorEl, setAnchorEl] = useState(false);
    const profileMenu = anchorEl;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = async () => {
        dispatch(logOut());
        dispatch(setUserType(null));
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/login", { state: { from: location }, replace: true });
        localStorage.clear();
    };

    const handleProfile = async () => {
        navigate("/profile", { state: { from: location }, replace: true });
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" open={open} onClick={() => open && setOpen(false)}>
            <Toolbar sx={{
                backgroundColor: 'rgba(43, 44, 64, 0.95)',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
                borderRadius: '8px',
                justifyContent: "space-between"
            }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start"
                    sx={{
                        marginRight: 5,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <IconButton onClick={toggleTheme}>
                        {theme === 'light' ? <DarkModeIcon sx={{ fill: "#32475cde" }} /> : <LightModeIcon sx={{ fill: '#dbdbeb99' }} />}</IconButton>
                    <IconButton><NotificationsNoneIcon sx={{ fill: '#dbdbeb99', cursor: 'pointer', width: '28px', height: '28px' }} /></IconButton>
                    <Box
                        id="basic-button"
                        aria-controls={profileMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={profileMenu ? 'true' : undefined}
                        onClick={handleProfileClick}
                        sx={{
                            padding: "0px",
                            justifyContent: 'end',
                            cursor: 'pointer',
                            height: '40px',
                            '& img': {
                                width: "40px",
                                height: '40px',
                                borderRadius: '100%',
                                objectFit: "cover"
                            }
                        }}
                    >
                        <img src={dp ? dp : profileimg} />
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={profileMenu}
                        onClose={handleClose}
                        // MenuListProps={{
                        //     'aria-labelledby': 'basic-button',
                        // }}

                        sx={{
                            '& .MuiMenu-paper': {
                                width: "230px",
                                backgroundColor: 'rgb(43, 44, 64)',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px -3px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                color: "#dbdbeb99",
                                left: 'auto !important',
                                right: '45px',
                                marginTop: '20px'
                            }
                        }}

                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                    >
                        <Box sx={{ cursor: "default" }}>
                            <Box
                                sx={{
                                    padding: "0px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0px 12px',
                                    gap: '12px',
                                    marginBottom: '10px',
                                    marginTop: '8px',
                                    '& img': {
                                        width: "40px",
                                        height: '40px',
                                        borderRadius: '100%',
                                        objectFit: "cover"
                                    }
                                }}
                            >
                                <img src={dp ? dp : profileimg} />
                                <Typography sx={{
                                    wordBreak: 'break-all'
                                }}>{first_name ? first_name : "User"}</Typography>
                            </Box>
                        </Box>
                        <MenuItem onClick={handleProfile}>
                            <PersonIcon sx={{ width: '22px', marginRight: "8px" }} />
                            Profile
                        </MenuItem>
                        <MenuItem onClick={handleSignOut}>
                            <PowerSettingsNewIcon sx={{ width: '20px', marginRight: "8px" }} />
                            Sign Out</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;