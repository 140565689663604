import { Box } from '@mui/material';
import React from 'react'

const CustomIconBox = ({ children, color }) => {

    const bgMappings = {
        primary: "#232333 !important",
        secondary: "#8592a329 !important",
        success: '#71dd3729 !important',
        error: "#ff3e1d29 !important",
        warning: "#ffab0029 !important",
        info: "#03c3ec29 !important",
    };

    const colorMappings = {
        primary: "#232333 !important",
        secondary: "#8592a3 !important",
        success: '#67c932 !important',
        error: "#ff3e1d !important",
        warning: "#ffab00 !important",
        info: "#03c3ec !important",

    };

    return (
        <Box
            sx={{
                backgroundColor: bgMappings[color] || "",
                padding: '7px 0px',
                borderRadius: '4px',
                marginBottom: "5px",
                maxWidth: '42px',
                width: '100%',
                height: '42px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: colorMappings[color] || "",
                '& svg': {
                    fontSize: '30px',
                },
            }}
        >
            {children}
        </Box>
    )
}

export default CustomIconBox
