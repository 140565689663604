// import React, { useState, useEffect } from "react";
// import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
// import { makeStyles } from "@material-ui/core";
// import Input from "@material-ui/core/Input";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Grid from "@material-ui/core/Grid";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Button from "@material-ui/core/Button";
// import Divider from "@material-ui/core/Divider";
// import PlacesAutocomplete, {
//     geocodeByAddress,
//     getLatLng,
// } from "react-places-autocomplete";
// import Box from "@material-ui/core/Box";
// import MerchantAddressList from "./MerchantAddressList";
// import {
//     usePostMerchantAddressMutation,
//     useGetMerchantAddressByAddressIDQuery,
//     usePutMerchantAddressMutation
// } from "../../../features/users/merchantApiSlice";
// import { setAddresses, addToAddress, editAddress, selectAddresses } from "../../../features/users/merchantSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
// import _ from '../../../../node_modules/underscore';

import { useDispatch, useSelector } from "react-redux";
import { useGetMerchantAddressByAddressIDQuery, usePostMerchantAddressMutation, usePutMerchantAddressMutation } from "../../store/merchant/merchantApiSlice";
import { addToAddress, editAddress, selectAddresses } from "../../store/merchant/merchantSlice";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import { Box, Button, Divider, FormControl, Grid, Input, InputAdornment, InputLabel } from "@mui/material";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";
import { showError } from "../../shared/helperFunction";
let _ = require('underscore');




const MerchantAddress = (props) => {
    const searchOptions = {
        // location: new google.maps.LatLng(-34, 151),
        // radius: 2000,
        // types: ['address']
        componentRestrictions: { country: "au" }
    }

    //base query hooks

    const {
        isLoading: isGetAddressLoading,
        data: addressToEdit,
        isSuccess,
        isError: isGetAddressError,
        error: getAddressError,
    } = useGetMerchantAddressByAddressIDQuery(props.addressID);

    const [postMerchantAddress, { isLoading, isSuccess: isPostSuccess }] = usePostMerchantAddressMutation();
    const [putMerchantAddress, { isLoading: isPutLoading, isSuccess: isPutSuccess }] = usePutMerchantAddressMutation();

    const dispatch = useDispatch();

    let addresses = useSelector(selectAddresses);


    const [error, setError] = useState(null);
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [placeID, setPlaceID] = useState("");
    const [note, setNote] = useState("");
    const [autocompleteAddress, setAutocompleteAddress] = useState("");
    const [existingAddresses, setExistingAddresses] = useState([]);

    const validate = () => {
        let isInvalid = false;
        let errorNew = {};

        if (title.trim().length === 0) {
            errorNew.title = {
                isError: true,
                text: "Address must have a title",
            };

            isInvalid = true;
        }
        if (address.trim().length === 0) {
            errorNew.address = {
                isError: true,
                text: "Address cannot be empty",
            };

            isInvalid = true;
        }
        if (city.trim().length === 0) {
            errorNew.city = {
                isError: true,
                text: "City cannot be empty",
            };

            isInvalid = true;
        }

        if (country.trim().length === 0) {
            errorNew.country = {
                isError: true,
                text: "Country cannot be empty",
            };

            isInvalid = true;
        }

        if (postcode.trim().length === 0) {
            errorNew.postcode = {
                isError: true,
                text: "Postcode cannot be empty",
            };

            isInvalid = true;
        }

        if (!note) {
            errorNew.note = {
                isError: true,
                text: "Note cannot be empty",
            };

            isInvalid = true;
        }

        if (isInvalid) {
            setError(errorNew);
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) {
            let newMerchantAddress = {
                user_id: props.merchantID,
                address: address,
                city: city,
                title: title,
                country: country,
                postcode: postcode,
                lat: latitude,
                long: longitude,
                place_id: placeID,
                note: note,
            };
            if (props.addressID === 0) {
                postAddress(newMerchantAddress);
            } else {
                newMerchantAddress = { ...newMerchantAddress, address_id: props.addressID }
                putAddress(newMerchantAddress);
            }
        }
    };

    const postAddress = async (data) => {
        try {
            const response = await postMerchantAddress(data).unwrap();
            props.setShowMerchantDialog(prev => !prev)
            props.setIsOpen(false);
            props.refetchAddress()
            toast.success(`Address added for Merchant ${props.merchantID}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            dispatch(addToAddress(data));
        } catch (error) {
            showError(error?.data)
        }
    };

    const putAddress = async (data) => {
        try {
            await putMerchantAddress(data).unwrap().then((res) => {
                console.log(res);
                if (res) {
                    props.setShowMerchantDialog(prev => !prev)
                    props.setIsOpen(false);
                    props.refetchAddress()
                    toast.success(`Address edited for Merchant ${props.merchantID}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    let index = 0;

                    for (let i = 0; i < addresses.length; i++) {
                        if (_.isEqual(addresses[i], addressToEdit.data[0])) {
                            index = i;
                        }
                    }
                    // let index = addresses.forEach(address => {
                    //   if(_.isEqual(address, addressToEdit.data[0])){
                    //     return address;
                    //   }
                    //   else{
                    //     temp++;
                    //   }
                    // });
                    // alert(index);
                    dispatch(editAddress({ editedAddress: data, indexToEdit: index }));
                }
            }).catch((err) => {
                if (err) {
                    showError(err?.data)
                }
            })

        }
        catch (error) {
            if (error) {
                showError(error?.data)
            }
        }
    }

    const handleAddressSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let index = 0;
        const addressLength = results[0].address_components.length;
        let postCode = {};
        let cityObject = {};
        let countryObject = {};
        setAutocompleteAddress(value);
        setAddress(value);
        let stateExists = false;
        const myFunction = (item, index, array) => {
            if (array[index].types[0] == "postal_code") {
                postCode = item;
            }
            if (array[index].types[0] == "country") {
                setCountry(item.long_name);
            }
            if (array[index].types[0] == "administrative_area_level_2") {
                stateExists = true;
            }

            if (!stateExists) {
                if (array[index].types[0] == "administrative_area_level_1") {
                    cityObject = item;
                }
            } else {
                if (array[index].types[0] == "administrative_area_level_2") {
                    cityObject = item;
                }
            }
        };
        results[0].address_components.forEach(myFunction);

        setPostcode(postCode.long_name);
        setCity(cityObject.long_name);
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        setPlaceID(results[0].place_id);
    };

    //hooks
    useEffect(() => {
        if (props.addressID != 0) {
            if (!isGetAddressLoading) {
                if (isGetAddressError) {
                } else {
                    setAddress(addressToEdit.data[0].address);
                    setTitle(addressToEdit.data[0].title);
                    setCity(addressToEdit.data[0].city);
                    setPostcode(addressToEdit.data[0].postcode);
                    setNote(addressToEdit.data[0].note);
                    setLatitude(addressToEdit.data[0].lat);
                    setLongitude(addressToEdit.data[0].long);
                    setPlaceID(addressToEdit.data[0].place_id);
                    setCountry(addressToEdit.data[0].country);
                }
            }
        }
    }, [props.addressID, isGetAddressLoading]);

    const handleClose = () => {
        props.setShowMerchantDialog(false);
    }

    return (
        <>
            <Grid item xs={12} md={6} >
                <CustomInput label="Shop Name" value={title} onChange={(e) => {
                    setTitle(e.target.value);
                    setError(null);
                }}
                    error={error != null && error.title && error.title.isError}
                    helperText={
                        error != null &&
                        error.title &&
                        error.title.isError &&
                        error.title.text
                    }
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <PlacesAutocomplete
                    value={autocompleteAddress}
                    onChange={(e) => {
                        setAutocompleteAddress(e);
                    }}
                    searchOptions={searchOptions}
                    onSelect={(e) => handleAddressSelect(e)}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div>

                            <CustomInput
                                label="Search Address"
                                fullWidth
                                id="standard-adornment-amount"
                                startAdornment={
                                    <InputAdornment position="start"></InputAdornment>
                                }
                                {...getInputProps({ placeholder: "search" })}
                            ></CustomInput>
                            <div
                                style={
                                    suggestions.length > 0
                                        ? {
                                            position: "absolute",
                                            zIndex: 9999,
                                            backgroundColor: "#2b2c40",
                                            marginRight: "15px",
                                            padding: "15px",
                                            border: "1px solid #2b2c40",
                                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px 4px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                            minWidth: "200px",
                                        }
                                        : { display: "none" }
                                }
                            >
                                {loading ? <div>...loading</div> : null}

                                {suggestions.map((suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active
                                            ? "#dbdbeb0a"
                                            : "#2b2c40",
                                        padding: "10px 10px",
                                    };

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, { style })}
                                        >
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </Grid>

            <Grid item xs={12} md={6} >
                <CustomInput
                    multiline
                    maxRows={4}
                    label="Address"
                    value={address}
                    disabled={true}
                    id="standard-adornment-amount"
                    error={error != null && error.address && error.address.isError}
                    helperText={
                        error != null &&
                        error.address &&
                        error.address.isError &&
                        error.address.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setAddress(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <CustomInput
                    type="text"
                    label="City"
                    value={city}
                    id="standard-adornment-amount"
                    disabled={true}
                    error={error != null && error.city && error.city.isError}
                    helperText={
                        error != null &&
                        error.city &&
                        error.city.isError &&
                        error.city.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setCity(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6} >
                <CustomInput
                    type="number"
                    value={postcode}
                    label="Postcode"
                    id="standard-adornment-amount"
                    margin="normal"
                    error={
                        error != null && error.postcode && error.postcode.isError
                    }
                    helperText={
                        error != null &&
                        error.postcode &&
                        error.postcode.isError &&
                        error.postcode.text
                    }
                    onChange={(e) => {
                        setError(null);

                        setPostcode(e.target.value);
                    }}
                />
            </Grid >

            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <CustomInput
                    type="text"
                    label="Note"
                    value={note}
                    id="standard-adornment-amount"
                    margin="normal"
                    onChange={(e) => {
                        setNote(e.target.value);
                    }}
                    error={error != null && error.note && error.note.isError}
                    helperText={
                        error != null &&
                        error.note &&
                        error.note.isError &&
                        error.note.text
                    }
                />
            </Grid>

            <Grid item xs={12} gap={2} md={12} sx={{ justifyContent: "end", display: "flex" }}>
                <CustomButton buttonName="Submit" isLoading={isPutLoading || isLoading || isPutSuccess || isPostSuccess} disabled={isPutLoading || isLoading || isPutSuccess || isPostSuccess} color="primary" onClick={handleSubmit} />
                <CustomButton buttonName="Cancel" color="secondary" onClick={handleClose} />
            </Grid>
        </>
    );
};

export default MerchantAddress;