import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

const CustomTabs = ({ tabsData, activeTab, onChange, children, isBacis }) => {
    return (
        <Box>
            {!isBacis &&
                <>
                    <Tabs value={activeTab} onChange={onChange} sx={{
                        borderRadius: '6px',
                        marginTop: '10px',
                        '& .MuiTabs-scroller': {
                            overflow: 'auto !important',
                            '&::-webkit-scrollbar': {
                                height: "0px"
                            }
                        },
                        '& span': {
                            display: 'none'
                        },
                        '& button': {
                            width: '175px',
                            minHeight: '42px',
                        },
                        '& .Mui-selected': {
                            color: '#fff !important',
                            backgroundColor: '#696cff',
                            borderRadius: '6px',
                        }
                    }}>
                        {tabsData.map((tab, index) => (
                            <Tab key={index} label={tab.label} value={index} sx={{
                                color: '#dbdbeb99',
                            }} />
                        ))}
                    </Tabs>
                    <div>{children[activeTab]}</div>
                </>
            }

            {isBacis && <>
                <Tabs variant="fullWidth" value={activeTab} onChange={onChange} sx={{
                    backgroundColor: 'rgba(43, 44, 64, 0.95)',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
                    '& .Mui-selected': {
                        color: '#696cff !important',
                        borderBottomColor: '#696cff',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#696cff'
                    },
                    '& button': {
                        '& span': {
                            display: 'none'
                        }
                    },
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'space-between',
                    }
                }}>
                    {tabsData.map((tab, index) => (
                        <Tab key={index} label={tab.label} value={index} sx={{
                            color: '#dbdbeb99',
                        }} />
                    ))}
                </Tabs>
                <div>{children[activeTab]}</div>
            </>}
        </Box>
    );
};

export default CustomTabs;
