import Packages from "../packages/Packages";
import CreateMyOrder from "./CreateMyOrder";



const EditMyOrder = () => {
    return (<>
        <CreateMyOrder />
        <Packages />
    </>);
};

export default EditMyOrder;
