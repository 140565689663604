import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import { selectMerchants, setMerchants } from "../../store/merchant/merchantSlice";
import { Box, Breadcrumbs, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { useGetMerchantsQuery } from "../../store/merchant/merchantApiSlice";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomSelect from "../../theme/style-components/CustomSelect";
import CustomTable from "../../theme/style-components/CustomTable";
import LottieLoader from "../loader/LottieLoader";
import ComponentTitle from "../../shared/ComponentTitle";
import TabTitle from "../../shared/TabTitle";
import CustomChips from "../../theme/style-components/CustomChips";

const Merchants = () => {
    const [selectedMerchant, setSelectedMerchant] = useState();
    const [isUserVerifiedFilter, setIsUserVerifiedFilter] = useState(null);
    const [isUserActiveFilter, setIsUserActiveFilter] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [query, setQuery] = useState("");
    const [searchText, setSearchText] = useState("")
    const [idFilter, setIdFilter] = useState("");
    const [count, setCount] = useState();
    const [merchantsToDisplay, setMerchantsToDisplay] = useState();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const merchants = useSelector(selectMerchants);

    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
    } = useGetMerchantsQuery({ page: page, query: query });

    const searchByID = (ID) => {
        setIdFilter(ID);
    };

    //hooks
    useEffect(() => {
        if (!isLoading) {
            dispatch(setMerchants(dataReturned?.data));
            setCount(dataReturned?.page_details?.count);
        }
    }, [isLoading, page, dataReturned?.data]);

    //hooks
    useEffect(() => {
        if (merchants) {
            setMerchantsToDisplay(merchants);
        }
    }, [merchants]);

    const handleCreateClick = () => {
        navigate("/merchants/create")
    };

    const handleEditClick = () => {
        navigate(`/merchants/edit/${selectedMerchant.id}`)
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&user_search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&user_search=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    const handleIdSearch = (e) => {
        const newSearchId = e.target.value;

        setIdFilter(newSearchId);
        let newQuery = query;

        // Remove existing id parameter from the query
        newQuery = newQuery.replace(/&id=[^&]*/g, '');

        // Add new id parameter if ID is not empty
        if (newSearchId !== '') {
            newQuery += `&id=${encodeURIComponent(newSearchId)}`;
        }

        setQuery(newQuery);
    };

    const handleVerifieChange = (e) => {
        setIsUserVerifiedFilter(e.target.value);
        if (e.target.value !== null) {
            setPage(1);
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=false`, "")
            );
            setQuery(
                (prevQuery) =>
                    prevQuery + `&user_verified=${e.target.value}`
            );
        }

        if (e.target.value === null) {
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_verified=false`, "")
            );
        }
    }

    const handleActiveChange = (e) => {
        setIsUserActiveFilter(e.target.value);
        if (e.target.value !== null) {
            setPage(1);
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=false`, "")
            );
            setQuery(
                (prevQuery) =>
                    prevQuery + `&user_active=${e.target.value}`
            );
        }
        if (e.target.value === null) {
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=true`, "")
            );
            setQuery((prevQuery) =>
                prevQuery.replace(`&user_active=false`, "")
            );
        }
    };

    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'name', label: 'Name' },
        { id: 'phone', label: 'Phone' },
        { id: 'email', label: 'Email' },
        { id: 'bussiness', label: 'Bussiness' },
        { id: 'status', label: 'Status' },
        { id: 'action', label: 'Action' },
    ];

    const brangerData = merchantsToDisplay?.length > 0 ? merchantsToDisplay.map((data) => ({
        id: data.id,
        name: <p>{data.first_name} {data.last_name}</p>,
        phone: data.phone,
        email: data.email,
        bussiness: data?.merchant_details?.business_name,
        status: data.is_active ? <CustomChips label="Enable" color="primary" labelColor="primary" /> : <CustomChips label="Disable" color="secondary" labelColor="secondary" />,
        action: "ACTION"
    })) : [];

    const setSelectedData = (item) => {
        setSelectedMerchant(item)
    };

    const breadcrumbs = [
        <Typography key="1" variant="body1">
            Merchants
        </Typography>,
    ];

    return (
        <Box >
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <TabTitle title="Merchants" />
            <CustomPaperBox>
                <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomInput
                                placeholder="Search by id"
                                type="search"
                                value={idFilter}
                                onChange={handleIdSearch}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomSelect
                                value={isUserVerifiedFilter}
                                label="Status"
                                onChange={handleVerifieChange}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                <MenuItem value={true}>Verified</MenuItem>
                                <MenuItem value={false}>Not Verified</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomSelect
                                value={isUserActiveFilter}
                                label="Status"
                                onChange={handleActiveChange}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                </Grid>
            </CustomPaperBox>
            {merchantsToDisplay && count !== undefined ? (
                <>
                    <CustomTable
                        //pagination props
                        title="Merchant"
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}

                        //create button props
                        handleCreateClick={handleCreateClick}
                        createButtonName="Create Merchant"

                        //edit menu props
                        isShowEditMenu={true}
                        handleEditClick={handleEditClick}

                        //action props
                        isActionVisible={true}

                        //collapse props
                        isCollapse={false}

                        //data props
                        data={brangerData}
                        columns={columns}

                        //search props
                        isShowSearch={true}
                        handleOnSearch={handleOnSearch}
                        searchText={searchText}
                        searchPlaceholder="Search Name"

                        //export button
                        isShowExportCSV={false}

                        //set Selected row data 
                        setSelectedData={setSelectedData}
                    />
                </>
            ) : <Box>
                <LottieLoader />
            </Box>}
        </Box >
    );
};

export default Merchants;




