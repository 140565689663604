import { Box, Breadcrumbs, Stack, Typography } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ComponentTitle = ({ breadcrumbs }) => {
    return (
        <Stack spacing={2}>
            <Breadcrumbs
                sx={{
                    "& a": {
                        color: "#8592a3",
                        fontSize: "1.2rem",
                        textDecoration: "none"
                    },
                    "& a:hover": {
                        color: "#8592a3",
                        fontSize: "1.2rem",
                        textDecoration: "underline"
                    },
                    "& p": {
                        color: "#696cff !important", fontSize: "1.2rem"
                    }
                }}
                separator={<NavigateNextIcon sx={{ fill: '#dbdbeb99', }} fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    )
}

export default ComponentTitle
