import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { Box, Typography, ButtonGroup, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

// Example data for each time range
const data = {
    weekly: [
        { day: 'Mon', orders: 12 },
        { day: 'Tue', orders: 19 },
        { day: 'Wed', orders: 25 },
        { day: 'Thu', orders: 30 },
        { day: 'Fri', orders: 22 },
        { day: 'Sat', orders: 40 },
        { day: 'Sun', orders: 38 },
    ],
    monthly: [
        { month: 'January', orders: 150 },
        { month: 'February', orders: 200 },
        { month: 'March', orders: 180 },
        { month: 'April', orders: 220 },
        { month: 'May', orders: 240 },
        { month: 'June', orders: 210 },
    ],
    '3month': [
        { quarter: 'Q1', orders: 500 },
        { quarter: 'Q2', orders: 600 },
        { quarter: 'Q3', orders: 550 },
    ],
    '6month': [
        { half: 'H1', orders: 1200 },
        { half: 'H2', orders: 1300 },
    ],
    yearly: [
        { year: '2023', orders: 3000 },
        { year: '2024', orders: 3200 },
    ],
};

const OrdersChart = () => {
    const [timeRange, setTimeRange] = useState('weekly');
    const [isExporting, setIsExporting] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const chartRef = useRef(null);

    const handleExportAction = async (type) => {
        setIsExporting(true);
        try {
            
            await new Promise(resolve => {
                setTimeout(resolve, 1000);
            });
            const chartInstance = window.Apex._chartInstances.find(
                (chart) => chart.id === "order-chart"
            );

            if (!chartInstance) {
                console.error('Chart instance not found');
                setIsExporting(false);
                return;
            }

       
            
            if (type === 'svg') {
                chartInstance.chart.exports.exportToSVG();
            } else if (type === 'png') {
                chartInstance.chart.exports.exportToPng();
            }
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
        }
    };

    const handleTimeRangeChange = (value) => {
        setTimeRange(value);
    };

    const chartData = data[timeRange];
    const chartSeries = [
        {
            name: 'Orders',
            data: chartData.map((item) => item.orders),
        },
    ];
    console.log(chartSeries,"")

    const chartCategories =
        timeRange === 'weekly'
            ? chartData.map((item) => item.day)
            : timeRange === 'monthly'
                ? chartData.map((item) => item.month)
                : timeRange === '3month'
                    ? chartData.map((item) => item.quarter)
                    : timeRange === '6month'
                        ? chartData.map((item) => item.half)
                        : chartData.map((item) => item.year);

    const chartOptions = {
        chart: {
            id: 'order-chart',
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: chartCategories,
            labels: {
                style: {
                    colors: isExporting ? '#000000' : '#FFFFFF',
                },
            },
            axisBorder: {
                show: true,
                color: '#9797A933',
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: false,
        },
        grid: {
            show: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 5,
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return value + ' Orders';
                },
            },
        },
        fill: {
            opacity: 1,
            colors: ['#696CFF', '#FF5733', '#28a745', '#f39c12', '#8e44ad'],
        },
    };

    const generateCSV = () => {
        const header = "Category,Orders\n";
        const rows = chartData.map(item => `${item[Object.keys(item)[0]]},${item.orders}`).join("\n");
        const csvContent = `data:text/csv;charset=utf-8,${header}${rows}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'orders_chart_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Box>
                <Box sx={{
                    display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "18px", borderBottom: "1px solid #9797A933", marginBottom: "16px",
                    "& a": {
                        textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500"
                    }
                }}>
                    <Typography sx={{ color: "white", fontSize: "20px", fontWeight: "700" }}>Orders Data Chart</Typography>
                    <Link to="/orders">View All</Link>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "base",
                    marginBottom: "16px",
                    width: '100%',
                    '@media (max-width:768px)': {
                        flexWrap: 'wrap',
                        gap: "10px"
                    },
                }}>
                    <Box>
                        <Typography variant='h2' sx={{ color: "white", fontSize: "16px", fontWeight: "700" }}>Total Delivery</Typography>
                        <Typography variant='h2' sx={{ marginTop: "6px", color: "#696CFF", fontSize: "24px", fontWeight: "700" }}>13,235</Typography>
                    </Box>
                    <Box>
                        <Typography variant='h2' sx={{ color: "white", fontSize: "16px", fontWeight: "700" }}>Blockchain Delivery</Typography>
                        <Typography variant='h2' sx={{ marginTop: "6px", color: "#696CFF", fontSize: "24px", fontWeight: "700" }}>13,235</Typography>
                    </Box>
                    <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="outlined primary button group"
          sx={{
            display: 'flex',
            gap: '7px',
            boxShadow: "inherit",
            '@media (max-width:768px)': { flexWrap: 'wrap' },
          }}
        >
          {['weekly', 'monthly', '3month', '6month', 'yearly'].map((range) => (
            <Button
              key={range}
              onClick={() => setTimeRange(range)}
              sx={{
                backgroundColor: timeRange === range ? '#696CFF' : '#232333',
                width: '38px',
                height: '26px',
                color: 'white',
                borderRight: '0 !important',
                borderRadius: '20px !important',
                '&:hover': { backgroundColor: '#696CFF' },
              }}
            >
              {range === 'weekly' ? 'W' : range === 'monthly' ? '1M' : range === '3month' ? '3M' : range === '6month' ? '6M' : '1Y'}
            </Button>
          ))}
        </ButtonGroup>
        <Box sx={{ position: 'relative',bottom:"22px" }}>
                    <IconButton onClick={handleClickMenu} sx={{ marginTop: '16px' }} >
                        <MenuIcon style={{ color: '#FFFFFF' }} />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => { handleExportAction('svg'); handleCloseMenu(); }}>Download SVG</MenuItem>
                        <MenuItem onClick={() => { generateCSV(); handleCloseMenu(); }}>Download CSV</MenuItem>
                        <MenuItem onClick={() => { handleExportAction('png'); handleCloseMenu(); }}>Download PNG</MenuItem>
                    </Menu>
                    </Box>
                </Box>
                <Box id="chart">
                    <ApexCharts options={chartOptions} series={chartSeries} type="bar" height={350} ref={chartRef} />
                </Box>
            </Box>

        </>
    );
};

export default OrdersChart;
