import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

export default function CustomCheckBox({ checked, id, onChange, name, label, value }) {
    return (
        <FormControlLabel control={<Checkbox
            sx={{
                '& svg': {
                    fill: '#696cff'
                }
            }}
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />} label={label} />
    );
}