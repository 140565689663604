import { TextField } from '@mui/material'
import React from 'react'

const CustomInput = ({ name,min, id, required, readOnly, minRows, multiline, endAdornment, label, value, type, placeholder, onChange, onBlur, error, disabled, helperText, InputProps
}) => {
    return (
        <TextField name={name} fullWidth sx={{
            "& input": {
                color: "#dbdbebde",
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type=number]': {
                    '-moz-appearance': 'textfield',
                },
            },
            "& .Mui-disabled": {
                WebkitTextFillColor: "#dbdbeb61 !important",
                '& fieldset': {
                    borderColor: '#dbdbeb61 !important',
                },
            },
            '& fieldset': {
                borderColor: '#dbdbeb61'
            },
            '& .MuiInputBase-root,label': {
                color: '#dbdbeb61'
            },
            '& svg': {
                fill: '#dbdbeb61'
            },
            '& .MuiMenu-list': {
                backgroundColor: 'rgb(43, 44, 64) !important'
            },
            "& fieldset:hover": {
                borderColor: "white !important"
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& fieldset': {
                        borderColor: '#dbdbeb61',
                    },
                },
            },
            '& .Mui-focused': {
                '& fieldset': {
                    borderColor: 'rgb(105, 108, 255) !important',
                },
            },
            '& .MuiFormLabel-root': {
                '&.Mui-focused': {
                    color: 'rgb(105, 108, 255) !important',
                },
            },

        }} id={id} min={min} readOnly={readOnly} multiline={multiline} minRows={minRows} type={type} required={required} endadornment={endAdornment} onBlur={onBlur} placeholder={placeholder} disabled={disabled} error={error} value={value} label={label} helperText={helperText} onChange={onChange} InputProps={InputProps} />
    )
}

export default CustomInput;
