import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import React, { useEffect, useRef, useState } from 'react';
import { onValue, ref } from "firebase/database";
import { db } from "../../firebase/firebaseConfig";
import driverVan from "../../assets/plusing_circle_small.gif";
import { Box, Typography } from '@mui/material';
import redIcon from "../../assets/red_marker_map.svg";
import greenIcon from "../../assets/green_marker_map.svg";
import blueIcon from "../../assets/blue_map_marker.svg";
import deliveryVan from "../../assets/driver-van.png"
import { decryptFn } from '../../shared/helperFunction';
import ReactDOMServer from 'react-dom/server';

const TrackingMap = ({ google, multiBranger, points, handleETA }) => {
    const isAuth = decryptFn(localStorage.getItem("accessToken"));
    const [mapRef, setMapRef] = useState(null);
    const [brangersMarker, setBrangersMarker] = useState([]);
    const [brangersIds, setBrangersIds] = useState([]);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedMarkerAddress, setSelectedMarkerAddress] = useState('');
    const [selectedMarkerETA, setSelectedMarkerETA] = useState('');
    const [previousPoints, setPreviousPoints] = useState(null);
    const [startMarker, setStartMarker] = useState([]);
    const [endMarker, setEndMarker] = useState([]);
    const [destinationLoc, setDestinationLoc] = useState({});
    const directionsService = new google.maps.DirectionsService();
    let directionsRenderer = new google.maps.DirectionsRenderer({
        map: mapRef,
        suppressMarkers: true,
    });

    const onMapReady = (mapProps, map) => {
        setMapRef(map);
    };

    console.log(process.env.REACT_APP_FIREBASE_COLLECTION, "map101");

    const fetchDriverLocationData = (ids, setDataCallback) => {
        const dataMap = {}; // To hold all the data
        const unsubscribes = {};

        ids.forEach((id) => {
            const dataRef = ref(db, `${process.env.REACT_APP_FIREBASE_COLLECTION}/${id}`);
            unsubscribes[id] = onValue(dataRef, (snapshot) => {
                const updatedData = snapshot.exists() ? snapshot.val() : null;

                // Update data in the dataMap
                dataMap[id] = updatedData;

                // Trigger the callback with the entire updated list
                const dataArray = Object.values(dataMap);
                setDataCallback(dataArray);
            });
        });
    };

    useEffect(() => {
        if (multiBranger && multiBranger?.length > 0) {
            const ids = multiBranger?.map((item) => item?.user);
            if (ids && ids?.length > 0) {
                setBrangersIds(ids)
            }
        }
    }, [multiBranger])

    useEffect(() => {
        // Declare unsubscribe variable outside of the if block
        let unsubscribe;

        if (brangersIds && brangersIds?.length > 0) {
            // Assign the result of fetchDriverLocationData to unsubscribe
            unsubscribe = fetchDriverLocationData(brangersIds, setBrangersMarker);
        }

        // Cleanup function
        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, [brangersIds]);  // Dependency array for multiBranger

    //handle marker click
    const handleMarkerClick = async (markerLocation) => {
        setShowInfoWindow(true);
        try {
            let address = '';
            let eta = '';
            let distance = '';
            const origin = { lat: brangersMarker[0]?.latitude, lng: brangersMarker[0]?.longitude };

            // Calculate ETA and distance from the origin to the clicked marker
            const service = new google.maps.DistanceMatrixService();
            const destinations = [markerLocation];

            const matrixOptions = {
                origins: [origin],
                destinations,
                travelMode: google.maps.TravelMode.DRIVING,
            };

            const response = await new Promise((resolve, reject) => {
                service.getDistanceMatrix(matrixOptions, (result, status) => {
                    if (status === google.maps.DistanceMatrixStatus.OK) {
                        resolve(result);
                    } else {
                        reject(status);
                    }
                });
            });

            if (response.rows.length > 0 && response.rows[0].elements.length > 0) {
                eta = response.rows[0].elements[0].duration.text;
                distance = response.rows[0].elements[0].distance.text;
            }

            // Fetch address for the clicked marker
            const geocoder = new google.maps.Geocoder();
            const geocodeResult = await new Promise((resolve, reject) => {
                geocoder.geocode({ location: markerLocation }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        resolve(results);
                    } else {
                        reject(status);
                    }
                });
            });

            address = geocodeResult[0].formatted_address;
            // Update selected marker and its address/ETA/distance
            setSelectedMarker(markerLocation);
            setSelectedMarkerAddress(address);
            if (destinationLoc?.lat === markerLocation?.lat && destinationLoc?.long === markerLocation?.long) {
                setSelectedMarkerETA(`${eta} (${distance})`);
            } else { setSelectedMarkerETA(null) }
        } catch (error) {
            console.error('Error fetching address, ETA, and distance:', error);
        }
    };

    const getEndCoordinates = (multiBranger, pickup_loc) => {
        let endCoordinates = null;
        // Iterate through each 'multiBranger'
        multiBranger?.forEach((item) => {
            // Check if item has packages
            if (item?.package?.length > 0) {
                item.package.forEach((pkg) => {
                    // If the task is started, return the coordinates for that task
                    if ((pkg.task_status === "STARTED" && pkg.task_status !== "PICKEDUP" && !pkg.pickedup_at) && pkg?.drop_address?.lat && pkg?.drop_address?.long) {
                        return endCoordinates = {
                            ...pickup_loc,
                            package: pkg
                        };
                    }

                    // If the 'delivery_started_at' is present, return the coordinates
                    else if ((pkg.delivery_started_at && pkg.task_status !== "DELIVERED") && pkg?.drop_address?.lat && pkg?.drop_address?.long) {
                        return endCoordinates = {
                            lat: Number(pkg.drop_address.lat),
                            lng: Number(pkg.drop_address.long),
                            package: pkg
                        };
                    }
                });
            }
        });
        setDestinationLoc(endCoordinates)
        return endCoordinates;
    };

    useEffect(() => {
        // Functionality to display and fetch ETA and distance for the sidebar for end user
        const fetchSideETA = async () => {
            try {
                let address = '';
                let eta = '';
                let distance = '';
                const origin = { lat: brangersMarker[0]?.latitude, lng: brangersMarker[0]?.longitude };

                // Calculate ETA and distance from the origin to the clicked marker
                const service = new google.maps.DistanceMatrixService();
                const destinations = [destinationLoc];

                const matrixOptions = {
                    origins: [origin],
                    destinations,
                    travelMode: google.maps.TravelMode.DRIVING,
                };

                const response = await new Promise((resolve, reject) => {
                    service.getDistanceMatrix(matrixOptions, (result, status) => {
                        if (status === google.maps.DistanceMatrixStatus.OK) {
                            resolve(result);
                        } else {
                            reject(status);
                        }
                    });
                });

                if (response.rows.length > 0 && response.rows[0].elements.length > 0) {
                    eta = response.rows[0].elements[0].duration.text;
                    distance = response.rows[0].elements[0].distance.text;
                }

                // Fetch address for the clicked marker
                const geocoder = new google.maps.Geocoder();
                const geocodeResult = await new Promise((resolve, reject) => {
                    geocoder.geocode({ location: destinationLoc }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            resolve(results);
                        } else {
                            reject(status);
                        }
                    });
                });

                address = geocodeResult[0].formatted_address;
                if (destinationLoc?.lat && destinationLoc?.lng) {
                    handleETA(`${eta} (${distance})`);
                } else { handleETA(null) }
            } catch (error) {
                console.error('Error fetching address, ETA, and distance:', error);
            }
        }

        fetchSideETA()
    }, [brangersMarker, destinationLoc])

    const polylineRef = useRef(null); // Ref to store the polyline

    //display makrer and routes
    useEffect(() => {
        if (points !== null && points?.length > 0 && points) {
            setPreviousPoints(points); // update the previous points value
            const pickup_loc = { lat: points[0]?.lat, lng: points[0]?.lng }
            const coords = [];
            const MyLocations = [];

            if (pickup_loc) {
                const startMark = (
                    <Marker
                        position={pickup_loc}
                        label={{ text: "P", color: "#FFFFFF", fontSize: "16px" }}
                        icon={{
                            url: blueIcon,
                            // Assume we're working with an icon size of 40x40
                            scaledSize: new google.maps.Size(40, 40),
                            // Set the anchor to the middle of your icon image
                            anchor: new google.maps.Point(20, 20),
                        }}
                        onClick={() => handleMarkerClick({ ...pickup_loc, isPickupMarker: true })}
                    />
                );
                if (startMark) {
                    setStartMarker(startMark);
                }
            }

            if (points?.length > 0) {
                points.forEach((point, index) => {
                    if (point.lat && point.lng) {
                        if (index !== 0) {
                            MyLocations.push({
                                lat: point.lat,
                                lng: point.lng,
                                package: point?.package
                            });
                        }
                    }
                });
            }

            if (points?.length > 0) {
                points.forEach((point) => {
                    if (point.lat && point.lng) {
                        coords.push({
                            lat: point.lat,
                            lng: point.lng,
                            package: point?.package
                        });
                    }
                });
            }

            const markers = MyLocations.map((location, index) => (
                <Marker
                    key={index}
                    position={{ lat: location.lat, lng: location?.lng }}
                    label={{
                        text: MyLocations.length === 1 ? "D" : `D${index + 1}`,
                        color: "#FFFFFF", fontSize: "16px",
                    }}
                    icon={{
                        url: location?.task_status === "UNASSIGNED" ? redIcon : location?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                        // Assume we're working with an icon size of 40x40
                        scaledSize: new google.maps.Size(40, 40),
                        // Set the anchor to the middle of your icon image
                        anchor: new google.maps.Point(20, 20),
                    }}
                    // icon={customMarkerIcon}
                    onClick={() => handleMarkerClick(location)}
                />
            ));

            if (markers) {
                setEndMarker(markers);
            }

            const start = { lat: brangersMarker[0]?.latitude, lng: brangersMarker[0]?.longitude };

            const end = getEndCoordinates(multiBranger, pickup_loc);

            // directions requests
            let request = {
                origin: start,
                waypoints: [],
                destination: end,
                travelMode: "DRIVING",
            };

            // show results in the directionsrenderer
            directionsRenderer.setDirections(null);

            //show results in the directionsrenderer
            directionsService.route(request, function (result, status) {
                if (result?.status == "OK") {

                    // Create an array to store polyline coordinates
                    const polylineCoordinates = [];

                    // Loop through the legs of the route and add their coordinates to the polylineCoordinates array
                    result.routes[0].legs.forEach((leg) => {
                        leg.steps.forEach((step) => {
                            step.path.forEach((point) => {
                                polylineCoordinates.push({ lat: point.lat(), lng: point.lng() });
                            });
                        });
                    });

                    // Remove the previous polyline if it exists
                    if (polylineRef.current) {
                        polylineRef.current.setMap(null); // This removes the previous polyline
                    }

                    // Create a Polyline object using the polylineCoordinates array
                    const polyline = new google.maps.Polyline({
                        path: polylineCoordinates,
                        geodesic: true,  // Adjust this option as needed
                        strokeColor: "#0046f7",  // Adjust the color as needed
                        strokeOpacity: 1.0,  // Adjust the opacity as needed
                        strokeWeight: 3,  // Adjust the line thickness as needed
                    });

                    // Store the new polyline in the ref
                    polylineRef.current = polyline;

                    // Set the map for the new polyline
                    if (mapRef.current) {
                        polyline.setMap(mapRef);
                    }
                }
            });
        }
    }, [points, brangersMarker?.length, multiBranger, brangersMarker, showInfoWindow, polylineRef, selectedMarker]);

    const loadGoogleInfoWindow = async () => {
        const { InfoWindow } = await google.maps.importLibrary("maps");
        let infoWindowContent = null

        // Check if InfoWindow is loaded properly
        if (InfoWindow && mapRef && selectedMarker) {
            // Your JSX content
            infoWindowContent = (
                <div>
                    {/* Conditionally Rendered Content */}
                    {!selectedMarker?.isPickupMarker && !selectedMarker?.isDriverMarker && (
                        <Typography>Task ID: {selectedMarker?.package?.task_id}</Typography>
                    )}
                    {!selectedMarker?.isPickupMarker && !selectedMarker?.isDriverMarker && isAuth && (
                        <>
                            <Typography>Customer Name: {selectedMarker?.package?.customer_name}</Typography>
                            <Typography>Customer Phone: {selectedMarker?.package?.customer_phone}</Typography>
                        </>
                    )}
                    <Typography>Address: {selectedMarkerAddress}</Typography>
                    {!selectedMarker?.isDriverMarker && selectedMarkerETA && (
                        <Typography>{`ETA: ${selectedMarkerETA}`}</Typography>
                    )}
                </div>
            );
        }

        // Convert JSX to string
        const contentString = ReactDOMServer.renderToString(infoWindowContent);

        // Check if InfoWindow is loaded properly
        if (InfoWindow && mapRef) {
            // Create an instance of InfoWindow
            const infoWindow = new InfoWindow({
                content: contentString, // Specify content here
                position: { lat: Number(selectedMarker?.lat), lng: Number(selectedMarker?.lng) }, // Set position (e.g., San Francisco)
            });

            // Attach the close event listener
            infoWindow.addListener('close', () => {
                setSelectedMarker(null);
                // You can put any logic you want to run when the InfoWindow is closed here
            });

            // Set the map to display the InfoWindow
            selectedMarker && infoWindow.open(mapRef); // mapRef should be your map object reference
        }
    }

    useEffect(() => {
        // Load Google Maps and its libraries when the component mounts
        selectedMarker && loadGoogleInfoWindow();
    }, [mapRef, selectedMarker]);

    return (
        <div>
            <Map
                style={{ height: "100vh", width: "100%" }}
                google={google}
                zoom={14}
                onReady={onMapReady}
                initialCenter={{ lat: -37.82296, lng: 144.979401 }}
                ref={mapRef}>
                {startMarker}
                {endMarker}
                {brangersMarker?.length > 0 ? brangersMarker?.map((driver) => {
                    return <Marker
                        key={driver?.userId}
                        position={{ lat: driver?.latitude, lng: driver?.longitude }}
                        data={{ lat: driver?.latitude, long: driver?.longitude, user_id: driver?.userId }}
                        icon={{
                            url: driverVan,
                            fillColor: '#EB00FF',
                            scale: 7,
                        }}
                        onClick={() => handleMarkerClick({ lat: driver?.latitude, lng: driver?.longitude, user_id: driver?.userId, isDriverMarker: true })}
                    />
                })
                    : null}
            </Map>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0'
})(TrackingMap);