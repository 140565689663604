import { apiSlice } from "../apiSlice";

export const taskApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTasks: builder.query({
            query: (orderID) => `/task/task/?order_id=${orderID}`,
            keepUnusedDataFor: 5,
        }),
        getAllTasks: builder.query({
            query: (taskStatus) => `/task/task/`,
            keepUnusedDataFor: 5,
        }),
        getBrangerTasksForDate: builder.query({
            query: ({ taskStatus, startDate, endDate, taskSearch }) => `task/task/?task_status=${taskStatus}&from=${startDate}&to=${endDate}&task_search=${taskSearch}`,
            keepUnusedDataFor: 5,
        }),
        getUnAssignedTasks: builder.query({
            query: ({ startDate, endDate, taskSearch, page }) => `task/task/?task_status=UNASSIGNED&from=${startDate}&to=${endDate}&task_search=${taskSearch}&page=${page}`,
            keepUnusedDataFor: 5
        }),
        getAssignedTasks: builder.query({
            query: ({ startDate, endDate, taskSearch, page, task_status_arr }) => `task/task/?from=${startDate}&to=${endDate}&task_search=${taskSearch}&page=${page}&task_status_arr=${task_status_arr}`,
            keepUnusedDataFor: 5
        }),
        getCompletedTasks: builder.query({
            query: ({ startDate, endDate, taskSearch, page, task_status_arr }) => `task/task/?task_status_arr=${task_status_arr}&from=${startDate}&to=${endDate}&task_search=${taskSearch}&page=${page}`,
            keepUnusedDataFor: 5
        }),
        assignTask: builder.mutation({
            query: (data) => ({
                url: '/task/task_assign/',
                method: 'PUT',
                body: data
            })
        }),
        getBrangerTasks: builder.query({
            query: ({ brangerId, task_status_arr }) => `task/task/?branger_id=${brangerId}&task_status_arr=${task_status_arr}`,
            keepUnusedDataFor: 5,
        }),
        getBrangerLastLoc: builder.query({
            query: ({ brangerId }) => `/operations/current_loc/?user_id=${brangerId}`,
            keepUnusedDataFor: 5,
        }),
        getOptimizePath: builder.query({
            query: (data) => ({
                url: '/operations/optimize_order/',
                method: 'PUT',
                body: data
            }),
            keepUnusedDataFor: 5,
        }),
        getPackageTracking: builder.query({
            query: (hash) => `/operations/track_package/?tracking_hash=${hash}`,
            keepUnusedDataFor: 5,
        }),
        getTaskAttachments: builder.query({
            query: (task_id) => `/order/order_attachments/?order_id=${task_id}`,
            keepUnusedDataFor: 5,
        }),
        addTaskAttachments: builder.mutation({
            query: (data) => ({
                url: '/order/order_attachments/',
                method: 'PUT',
                body: data
            })
        }),
        deleteAttachment: builder.mutation({
            query: (id) => ({
                url: `/order/order_attachments/?attachment_id=${id}`,
                method: "DELETE",
            }),
        }),
        resetTask: builder.mutation({
            query: (data) => ({
                url: '/task/reset_task/',
                method: 'PUT',
                body: data
            })
        }),
    })
})

export const { useLazyGetTasksQuery, useAssignTaskMutation,
    useGetAllTasksQuery, useGetAssignedTasksQuery,
    useGetUnAssignedTasksQuery, useGetCompletedTasksQuery, useGetBrangerTasksForDateQuery, useGetBrangerTasksQuery, useLazyGetBrangerTasksQuery, useLazyGetBrangerLastLocQuery, useLazyGetOptimizePathQuery, useLazyGetPackageTrackingQuery, useLazyGetTaskAttachmentsQuery, useDeleteAttachmentMutation, useResetTaskMutation, useAddTaskAttachmentsMutation } = taskApiSlice;