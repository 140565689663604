import { apiSlice } from "../apiSlice";
// import { numberValidator } from "../../utitlities/FormValidator";

export const merchantApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMerchants: builder.query({
            query: ({ page, query }) => `/user/users/?page=${page}&user_type=MERCHANT${query}`,
            keepUnusedDataFor: 0,
        }),
        getMerchant: builder.query({
            query: (id) => `user/users/?id=${id}`,
            keepUnusedDataFor: 5,
        }),
        getMerchantsByName: builder.query({
            query: ({name, status}) => `user/users/?user_type=MERCHANT&user_search=${name}${status !== undefined ? `&user_active=${status}` : ""}`,
            keepUnusedDataFor: 0,
        }),
        getVerifiedMerchants: builder.query({
            query: ({ verified, page }) => `user/users/?page=${page}&user_type=MERCHANT&user_verified=${verified}`,
            keepUnusedDataFor: 5,
        }),
        getActiveMerchants: builder.query({
            query: (active) => `user/users/?user_type=MERCHANT&user_active=${active}`,
            keepUnusedDataFor: 5,
        }),
        getMerchantAddress: builder.query({
            query: ({ userID, subscribedOnly }) => `/user/merchant_address/?user_id=${userID}&subscribed_only=${subscribedOnly}`,
            keepUnusedDataFor: 5,
        }),
        getMerchantAddressByAddressID: builder.query({
            query: (addressID) => `/user/merchant_address/?address_id=${addressID}`,
            keepUnusedDataFor: 5,
        }),
        postMerchantAddress: builder.mutation({
            query: (newMerchantAddress) => ({
                url: '/user/merchant_address/',
                method: "POST",
                body: { ...newMerchantAddress },
            })
        }),
        putMerchantAddress: builder.mutation({
            query: (data) => ({
                url: '/user/merchant_address/',
                method: 'PUT',
                body: data
            })
        }),
        deleteMerchantAddress: builder.mutation({
            query: (addressID) => ({
                url: `/user/merchant_address/?address_id=${addressID}`,
                method: 'DELETE',

            })
        }),

        onBoardMerchant: builder.mutation({
            query: (data) => ({
                url: '/user/onboard_merchant/',
                method: 'PUT',
                body: data,
            })
        }),
        getMerchantAddressWitUserId: builder.query({
            query: ({ userID }) => `/user/merchant_address/?user_id=${userID}`,
            keepUnusedDataFor: 5,
        }),
    })
})

export const {
    useGetMerchantsQuery,
    useGetMerchantAddressQuery,
    usePostMerchantAddressMutation,
    useGetMerchantQuery,
    useGetMerchantsByNameQuery,
    useGetVerifiedMerchantsQuery,
    useGetActiveMerchantsQuery,
    useOnBoardMerchantMutation,
    useGetMerchantAddressByAddressIDQuery,
    usePutMerchantAddressMutation,
    useDeleteMerchantAddressMutation,
    useLazyGetMerchantAddressQuery,
    useGetMerchantAddressWitUserIdQuery,
    useLazyGetMerchantsByNameQuery,
    useLazyGetMerchantQuery
} = merchantApiSlice