import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, accessToken: null, refreshToken: null, userType: null, userID: 0, userObject: {}, userName: null },
    reducers: {
        setCredentials: (state, action) => {
            state.user = action.payload.email;
            state.userName = action.payload.first_name + " " + action.payload.last_name;
            state.accessToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
        },
        logOut: (state, action) => {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.userType = null;
            state.userID = 0;
            state.userObject = {};
            state.userName = null;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
        },
        setUserID: (state, action) => {
            state.userID = action.payload;
        },
        setUser: (state, action) => {
            state.userObject = action.payload;
        }
    },
});


export const { setCredentials, logOut, setUserType, setUserID, setUser } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentUserName = (state) => state.auth.userName
export const selectCurrentAccessToken = (state) => state.auth.accessToken
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken
export const selectCurrentUserType = (state) => state.auth.userType
export const selectCurrentUserID = (state) => state.auth.userID
export const selectCurrentUserObject = (state) => state.auth.userObject