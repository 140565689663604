import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Link } from 'react-router-dom';

const MerchantChart = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // max-width: 600px

  // Total number of merchants (for calculating counts from percentages)
  const totalMerchants = 1000; // Change this to the actual total merchant count

  const chartOptions = {
    chart: {
      id: 'donut-chart',
      type: 'donut',
    },
    labels: ['Active Merchants', 'Inactive Merchants', 'New Register Merchants'],
    plotOptions: {
      pie: {
        donut: {
          size: '60%',  // Adjust donut size (optional)
          labels: {
            show: true,
            name: {
              show: false, // Hide the default name label
            },
            value: {
              offsetY: -20,
              color: "#81DD9E", // Adjust the value label color
            },
            total: {
              show: true,
              showAlways: true,
              offsetY: -60,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              formatter(w) {
                // Return a fixed value in the center, regardless of user interaction
                return ['100%', 'Total No. of', 'Merchants'];
              }
            }
          }
        },
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`, // Show percentage inside the donut slices
    },
    colors: ['#1BC2FF', '#EE7482', '#9DA1FE'], // Custom colors for each slice
    tooltip: {
      y: {
        formatter: (value, { seriesIndex }) => {
          // Calculate count for the slice based on the total merchant count
          const count = Math.round((value / 100) * totalMerchants);
          return `${count}`; // Show percentage and count
        },
      },
    },
    legend: {
      show: true, // Show legend
      position: isSmallScreen ? 'bottom' : 'right', // Position of the legend
      labels: {
        colors: '#ffffff', // Set the legend text color to white
        fontSize: '14px', // Set font size for the legend labels
        fontWeight: 500, // Change the font weight
      },
      onItemClick: {
        toggleDataSeries: false
      },
      markers: {
        shape: 'square', // Shape of the legend markers
      },
    },
  };

  // Data for the Donut chart
  const chartSeries = [35, 35, 30]; // Data values: Active, Inactive, New Register

  return (
    <div>
      <Box sx={{
        display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "18px", borderBottom: "1px solid #9797A933", marginBottom: "16px",
        "& a": {
          textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500"
        }
      }}>
        <Typography sx={{ fontSize: '20px', fontWeight: '700', color: "#fff" }}>Merchants</Typography>
        <Link to="/merchants" sx={{ textDecoration: "none", color: "#696CFF", fontSize: "16px", fontWeight: "500" }}>View All</Link>
      </Box>
      <div id="chart">
        <ApexCharts options={chartOptions} series={chartSeries} type="donut" height={350} />
      </div>
    </div>
  );
};

export default MerchantChart;

